import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appName: 'COUPLUS',
    logo: require('../assets/images/logo.png'),
    darklogo:require('../assets/images/logo.png'),
    dark: false,
    namespaced: true,
    user:{
      regdate:'',
      name:'',
      _id : '',
      companyid : '',
      email: '',
      phone: '',
      suppliercode: '',
      accesshistory: '',
      auth: {},
      grade: '',
      expireDate:'',
      paydate:'',
      nextpayment:'',
      addAccount : '',
      version:'',
    },
    userinit:{
      name:'',
      _id : '',
      companyid : '',
      email: '',
      phone: '',
      suppliercode: '',
      accesshistory: '',
      auth: {},
      regdate: '',
      grade : '',
      expireDate:'',
      paydate:'',
      nextpayment:'',
      addAccount : '',
      version:'',
    },
    polist:[],
    exportlist:[],
    productdb:[],
    purchase:[],
    matchpo:[],
    returns:[],
    sales:[],
    supply:[],
    stock:[],
    settings:[],
    invoice:[],
    reqpurchase:[],
    growthdb:[],
    growthSales:[],
    TS:[],
    TF:[],
    TF2:[],
    saveitem:[],
    saveItemStatus:false,
    keywordList:[],
    trafficList:[],
    adSourcingList:[],
  },
  mutations: {
    resetState(state){
      // 기본 state 객체의 깊은 복사본을 만들어서 현재 state를 덮어씁니다
      const defaultState = {
        appName: 'COUPLUS',
        logo: require('../assets/images/logo.png'),
        darklogo: require('../assets/images/logo.png'),
        dark: false,
        namespaced: true,
        user: {
          regdate: '',
          name: '',
          _id: '',
          companyid: '',
          email: '',
          phone: '',
          suppliercode: '',
          accesshistory: '',
          auth: {},
          grade: '',
          expireDate: '',
          paydate: '',
          nextpayment: '',
          addAccount: '',
          version: '',
        },
        userinit: {
          name: '',
          _id: '',
          companyid: '',
          email: '',
          phone: '',
          suppliercode: '',
          accesshistory: '',
          auth: {},
          regdate: '',
          grade: '',
          expireDate: '',
          paydate: '',
          nextpayment: '',
          addAccount: '',
          version: '',
        },
        polist: [],
        exportlist: [],
        productdb: [],
        purchase: [],
        matchpo: [],
        returns: [],
        sales: [],
        supply: [],
        stock: [],
        settings: [],
        invoice: [],
        reqpurchase: [],
        growthdb: [],
        growthSales: [],
        TS: [],
        TF: [],
        TF2: [],
        saveitem: [],
        saveItemStatus: false,
        keywordList: [],
        trafficList: [],
        adSourcingList: [],
      };

      Object.keys(defaultState).forEach(key => {
        state[key] = JSON.parse(JSON.stringify(defaultState[key]));
      });
    },
    userupdate(state, payload) {
      function deepClone(obj){
        if (obj === null || typeof obj !== "object") {
          return obj
        }

        const result = Array.isArray(obj) ? [] : {}

        for (let key of Object.keys(obj)) {
          result[key] = deepClone(obj[key])
        }

        return result
      }
      state.user.regdate = payload.regdate;
      state.user.name = payload.name;
      state.user.accesshistory = payload.accesshistory;
      state.user._id = payload._id;
      state.user.companyid = payload.companyid;
      state.user.email = payload.email;
      state.user.phone = payload.phone;
      state.user.suppliercode = payload.suppliercode;
      state.user.grade = payload.grade;
      state.user.auth = deepClone(payload.auth);
      state.user.addAccount = payload.addAccount;
      state.user.expireDate = payload.expireDate;
      state.user.paydate = payload.paydate;
      state.user.nextpayment = payload.nextpayment;
      state.user.expireDate = payload.expireDate;
      state.user.version = payload.version;
    },
    userpaymentstatusupdate(state,payload){
      state.user.nextpayment = payload.nextpayment;
      // state.user.paydate = payload.paydate;
    },
    userauthupdate(state, payload) {
      function deepClone(obj){
        if (obj === null || typeof obj !== "object") {
          return obj
        }

        const result = Array.isArray(obj) ? [] : {}

        for (let key of Object.keys(obj)) {
          result[key] = deepClone(obj[key])
        }

        return result
      }
      state.user.auth = deepClone(payload.auth);
    },
    dbupdate(state, payload) {
      console.log('store update')
      // if (payload.polist.length != 0){
      //   var temp = payload.polist;
      //   var arrangecnt = 0;
      //   var notarrangecnt = 0;
      //   var pstockcnt = 0;
      //   var rstockcnt = 0;
      //   var tempstock = payload.stock;

      //   temp.forEach(e => {
      //     e.발주세부내역.forEach(ele => {
      //       arrangecnt = 0;
      //       notarrangecnt = 0;
      //       pstockcnt = 0;
      //       rstockcnt = 0;
      //       if (payload.stock.length != 0){
      //         tempstock.forEach(element => {
      //           if(element.바코드 == ele.바코드){
      //             if(element.배치발주번호 == e.발주번호){
      //               arrangecnt++
      //             } else {
      //               if(element.배치발주번호 == ''){
      //                 if(element.Stockinfo == 'stock') {
      //                   notarrangecnt++
      //                 } else {
      //                   if(element.Stockinfo == 'Pstock') {
      //                     pstockcnt++
      //                   } else {
      //                     if(element.Stockinfo == 'Rstock') {
      //                       rstockcnt++
      //                     }
      //                   }
      //                 }
      //               }
      //             }
      //           }
      //         })
      //       }
      //       payload.productdb.some(el => {
      //         if(el.바코드 == ele.바코드) {
      //           if(el.구매링크 == '사입'){
      //             ele.사입재고수량 = ele.발주수량;
      //           } else {
      //             ele.사입재고수량 = 0;
      //           }
      //           return (el.바코드 == ele.바코드)
      //         }
      //       })


      //       ele.발주수량 = parseInt(ele.발주수량);
      //       ele.입고수량 = parseInt(ele.입고수량);
      //       ele.확정수량 = parseInt(ele.확정수량);
      //       ele.배치수량 = arrangecnt;
      //       ele.필요수량 = parseInt(ele.확정수량) - arrangecnt;
      //       ele.미배치재고수량 = notarrangecnt;
      //       ele.구매완료수량 = pstockcnt;
      //       ele.회송예정수량 = rstockcnt;

      //     })
      //   });

      //   state.polist = temp;
      // } else {
      //   state.polist = payload.polist;
      // }
      state.polist = payload.polist;
      state.productdb = payload.productdb;
      state.purchase = payload.purchase;
      state.returns = payload.returns;
      state.exportlist = payload.exportlist;
      state.stock = payload.stock;
      state.settings = payload.settings;
      state.invoice = payload.invoice;
      state.reqpurchase = payload.reqpurchase;
      console.log('success DbUpdate');
    },
    initDb(state) {
      state.polist = [];
      state.productdb = [];
      state.purchase = [];
      state.returns = [];
      state.exportlist = [];
      state.stock = [];
      state.settings = [];
      state.invoice = [];
      state.reqpurchase = [];
      console.log('@@@init Db success', state.polist, state.productdb, state.purchase, state.returns, state.exportlist, state.stock, state.settings, state.invoice, state.reqpurchase);
    },
    growthSalesUpdate(state, payload){
      state.growthSales = payload
    },
    growthdbupdate(state, payload) {
      // payload로 받은 데이터 가공
      const processedData = payload.map(item => {
        // 구매정보가 없는 경우 기본 구매정보 생성
        if(!item.구매정보 || item.구매정보.length == 0) {
          item.구매정보 = [{
            checked: false,
            showspinner: false,
            바코드: item.barcode || item.바코드,
            상품명: item.productName || item.상품명,
            상품명init: item.productName || item.상품명init,
            구매링크: undefined,
            구매링크init: undefined,
            옵션1_중국어: undefined,
            옵션2_중국어: undefined,
            옵션이미지: undefined,
            매칭상태: '매칭필요',
            상품매칭: {
              옵션1_중국어: [],
              옵션2_중국어: [],
            },
            구매재고: '',
            구매요청수량: '',
            판매구성수량: 1,
            묶음구성여부: false,
            묶음구성수량: '',
            묶음대상바코드: '',
            신고영문명: '',
            신고가격: '',
            HSCODE: '',
            진행현황: '구매전',
            재고동봉: true,
            등록이미지: item.optionImageUrl || item.등록이미지,
            요청사항: '요청등록',
            reqtype: '제트배송',
            sizeX: item.sizeInfo ? item.sizeInfo.width : 0,
            sizeY: item.sizeInfo ? item.sizeInfo.length : 0,
            sizeZ: item.sizeInfo ? item.sizeInfo.height : 0,
            weight: item.sizeInfo ? item.sizeInfo.weight : 0,
            요청내역: {
              default: true,
              poarrange: false,
              한글표시사항: true,
              barcode: true,
              sticker: false,
              sewing: false,
              stamp: false,
              tag: false,
              reqcontent: '',
              opp: false,
              LDPE: false,
              airpacking: false,
              exportType: 'box',
              destination: 'coupang',
              masking: false,
              auto: true,
            },
            한글표시사항: {
              제품명: item.productName || item.상품명,
              '수입원/판매원': '',
              제조원: '',
              제조국: '',
              내용량: '',
              '원료명및성분명(재질)': '',
              상품유형: '',
              사용시주의사항: '',
              사용기준: '',
              custom: '',
            },
            polists: [],
            selectpo: '', 
          }];
          item.구매정보여부 = false;
        }
 
        // 상품명 설정
        item.상품명 = item.skuName;
 
        // 매입가 계산
        if(item.매입정보 && item.매입정보.length > 0) {
          item.매입가 = parseInt(item.매입정보[item.매입정보.length-1].매입가);
          if(item.returnProducts && item.returnProducts.length > 0) {
            item.returnProducts.forEach(a => {
              a.매입가 = parseInt(item.매입정보[item.매입정보.length-1].매입가);
            });
          }
        } else {
          item.매입가 = "";
          if(item.returnProducts && item.returnProducts.length > 0) {
            item.returnProducts.forEach(a => {
              a.매입가 = "";
            });
          }
        }
 
        // 마진 계산
        if(item.매입가) {
          item.마진 = Math.round((item.salePrice-item.coupon-item.commission*1.1-item.매입가)*10/11);
          item.마진율 = Math.round(item.마진/(item.salePrice-item.coupon)*100)/100;
          item.ROI = Math.round(item.마진/(item.매입가*10/11)*100)/100;
          item.ROAS = Math.round((item.salePrice-item.coupon)/item.마진*110)/100;
        }
 
        if(item.returnProducts && item.returnProducts.length > 0) {
          item.returnProducts.forEach(a => {
            if(item.매입가) {
              a.마진 = Math.round((a.salePrice-a.coupon-a.commission*1.1-a.매입가)*10/11);
              a.마진율 = Math.round(a.마진/(a.salePrice-a.coupon)*100)/100;
              a.ROI = Math.round(a.마진/(a.매입가*10/11)*100)/100;
              a.ROAS = Math.round((a.salePrice-a.coupon)/a.마진*110)/100;
            }
          });
        }
 
        return item;
      });
 
      // 정렬
      processedData.sort((a,b) => b.gmvForLast30Days - a.gmvForLast30Days);
 
      // 처리된 데이터를 state에 저장
      state.growthdb = processedData;
    },
    growthdbupdateOne(state, payload) {
      for (let i = 0; i < state.growthdb.length; i++) {
        if (state.growthdb[i].barcode === payload.barcode) {
          // 방법 1: Object.assign 사용
          Object.assign(state.growthdb[i], payload);
    
          // 방법 2: Vue.set()을 사용 (Vue3 이전에서는 Vue.set을 사용해야 반응성 유지 가능)
          // Vue.set(state.growthdb, i, payload);
    
          console.log(state.growthdb[i]);
          console.log(payload);
          return;
        }
      }
    },
    getAdSourcingListUpdate(state, payload){
      state.adSourcingList = payload
    },
    reqpurchaseupdate(state, payload){
      state.reqpurchase = payload;
    },
    productdbupdate(state, payload){
      state.productdb = payload;
    },
    invoiceupdate(state, payload){
      state.invoice = payload;
    },
    settingsupdate(state, payload){
      state.settings = payload;
    },
    settingsupdateparameter(state, payload){
      state.settings[payload.key] = payload.value;
    },
    stockinsert(state, payload){
      state.stock.push(payload)
    },
    stockdelete(state, payload){
      //payload : arr, remove target objects in array, including "_id"

      payload.forEach(e => {
        state.stock = state.stock.filter(ele => ele._id != e._id)
      })

    },
    exportlistupdate(state,payload){
      state.exportlist = payload
    },
    purchaseupdate(state,payload){
      state.purchase = payload
    },
    updatepurchaseitem(state,payload){
      state.purchase.find(e => e._id == payload._id).주문수량 = payload.주문수량;
      state.purchase.find(e => e._id == payload._id).운송장번호 = payload.운송장번호;
    },
    updateboxqty(state,payload){
      state.productdb.find(e => e.바코드 == payload.바코드)['1박스인입수량'] = payload['1박스인입수량'];
    },
    updateleadtime(state,payload){
      state.productdb.find(e => e.바코드 == payload.바코드)['입고리드타임'] = payload['입고리드타임'];
    },
    deletepurchase(state,payload){
      payload.forEach(e => {
          state.purchase = deleteindexfromarray(state.purchase,state.purchase.findIndex(ele => equal(ele,e)));
      })

      function deleteindexfromarray(array,index){
        return array.slice(0,index).concat(array.slice(index+1))
      }

      function equal(k1,k2){
        JSON.stringify(k1) === JSON.stringify(k2)
      }

    },
    stockupdate(state, payload){
      state.stock = payload
    },
    salesupdate(state, payload){
      state.sales = payload
    },
    supplyupdate(state, payload){
      state.supply = payload
    },
    layoutModeCommit (state, payload) {
      state.dark = payload
      if (!payload) {
        state.logo = require('../assets/images/logo.png')
      } else {
        state.logo = require('../assets/images/logo.png')
      }
    },
    updateTS (state, payload){
      state.TS = payload;
    },
    updateTF (state, payload){
      state.TF = payload;
    },
    updateTF2 (state, payload){
      state.TF2 = payload;
    },        
    updateSaveItem(state,payload){
      state.saveitem = payload;
    },
    updateSaveItemStatus(state,payload){
      state.saveItemStatus = payload;
    },
    setKeywordList(state,payload){
        state.keywordList = payload;
    },
    updateKeywordList(state,payload){
      if(payload.arr.length == 0){
        return
      }
      if(payload.keyword == 'Default'){
        state.keywordList = state.keywordList.filter(e => e.keywordGroup)
        state.keywordList = state.keywordList.concat(payload.arr)
      } else {
        state.keywordList = state.keywordList.filter(e => e.keywordGroup !== payload.keyword)
        state.keywordList = state.keywordList.concat(payload.arr)
        console.log(state.keywordList)
      }
    },
    updateAllKeywordList(state,payload){
      state.keywordList = payload;
    },
    updateTrafficList (state, payload){
      state.trafficList = payload;
    },
  },
  actions: {
    layoutModeAction (context, payload) {
      context.commit('layoutModeCommit', payload.dark)
    }
  },
  getters: {
    appName: state => { return state.appName },
    logo: state => { return state.logo },
    darklogo: state => { return state.darklogo },
    image1: state => { return state.user.image },
    name: state => { return state.user.name },
    accesshistory: state => { return state.user.accesshistory },
    email: state => { return state.user.email },
    companyid: state => { return state.user.companyid },
    expireDate: state => { return state.user.expireDate},
    suppliercode: state => { return state.user.suppliercode },
    image2:state => { return state.user.image2},
    image3:state => { return state.user.image3},
    dark: state => { return state.dark },
  },
  modules: {
  },
  // plugins: [createPersistedState({ paths: ["userStore"] })]
})
