<template>
  <div :id="element" :class="classname"></div>
</template>

<script>
import ApexCharts from 'apexcharts'

export default {
  name: 'ApexChart',
  props: {
    element: {
      type: String,
      required: true
    },
    chartOption: {
      type: Object,
      required: true
    },
    classname: {
      type: String,
      default: ''
    },
    isLive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chart: null,
      lastDate: 0,
      data: [],
      TICKINTERVAL: 86400000,
      XAXISRANGE: 777600000,
      prevOptions: null  // 이전 옵션을 저장하기 위한 변수 추가
    }
  },
  watch: {
      chartOption: {
          handler(newVal, oldVal) {
              if (this.chart && newVal && this.hasChanges(newVal, oldVal)) {
                  this.chart.updateOptions(newVal, true, true);  // 애니메이션 없이 업데이트
              }
          },
          deep: true
      }
  },
  methods: {
    // 옵션 변경 여부를 확인하는 메소드
    hasChanges(newVal, oldVal) {
      if (!oldVal) return true;
      
      // series 데이터 비교
      const hasSeriesChanges = JSON.stringify(newVal.series) !== JSON.stringify(oldVal.series);
      
      // xaxis categories 비교
      const hasXAxisChanges = JSON.stringify(newVal.xaxis?.categories) !== 
                             JSON.stringify(oldVal.xaxis?.categories);
      
      return hasSeriesChanges || hasXAxisChanges;
    },
    
    initChart() {
      try {
        if (!this.element || !this.chartOption) return;

        const selector = '#' + this.element;
        const element = document.querySelector(selector);
        
        if (!element) {
          console.warn(`Element with id ${this.element} not found`);
          return;
        }

        if (this.chart) {
          this.chart.destroy();
        }

        this.chart = new ApexCharts(element, this.chartOption);
        this.chart.render();

        // 초기 옵션 저장
        this.prevOptions = JSON.parse(JSON.stringify(this.chartOption));

        if (this.isLive) {
          setInterval(() => {
            this.getNewSeries(this.lastDate, {
              min: 10,
              max: 90
            });
            this.chart.updateSeries([{
              data: this.data
            }]);
          }, 1000);
        }
      } catch (error) {
        console.error('Chart initialization error:', error);
      }
    },
    getNewSeries(baseval, yrange) {
      const newDate = baseval + this.TICKINTERVAL;
      this.lastDate = newDate;
      
      if (Array.isArray(this.data)) {
        for (let i = 0; i < this.data.length - 10; i++) {
          this.data[i].x = newDate - this.XAXISRANGE - this.TICKINTERVAL;
          this.data[i].y = 0;
        }
        this.data.push({
          x: newDate,
          y: Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min
        });
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.initChart();
      }, 100);
    });
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
</script>