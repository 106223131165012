<template>
    <div class="container-fluid">
         <div class="row">
            <div class="col-sm-12">
                  <div class="card">
                     <div class="card-header d-flex justify-content-between">
                        <div class="header-title">
                           <h4 class="card-title">배대지 사용자 현황</h4>
                        </div>
                     </div>
                     <div class="card-body">
                        <!-- <div> -->
                           <!-- <label>사용자등록(쿠플러스 이메일주소)</label> -->
                           <!-- <input type="text" class="form-control" aria-label="" v-model="regEmail" @change="activeemail"> -->
                        <!-- </div> -->
                        <div class="table-responsive">
                            <CDataTable :headers="headers" :items="userlists">
                                <!-- <template v-slot:approval="{item}">
                                    <span :class="[badge,bgcolor(item.approval)]">{{item.approval}}</span>
                                </template> -->
                                <!-- <template v-slot:auth.showpurchase="{item}">
                                    <span :class="[badge,bgcolor(item.auth.showpurchase)]">{{item.auth.showpurchase}}</span>
                                </template>
                                <template v-slot:auth.showgrowth="{item}">
                                    <span :class="[badge,bgcolor(item.auth.showgrowth)]">{{item.auth.showgrowth}}</span>
                                </template> -->
                                <template v-slot:memo="{item}">
                                    
                                        <input type="text" class="form-control" aria-label="" v-model="item.memo" @keyup.enter="changeusermemo($event,item)" style="text-align:center">
                                    
                                </template>
                                <template v-slot:chatLink="{item}">
                                    <div class="d-flex flex-column">
                                       <a :href="item.chatLink" style="z-index:9999" target="_blank"><b-icon icon="link" scale="2" variant="secondary"></b-icon></a>
                                       <input type="text" class="form-control" aria-label="" v-model="item.chatLink" @keyup.enter="changeUserChatLink($event,item)" style="text-align:center">
                                    </div>
                                </template>
                            </CDataTable>
                        </div>
                           <!-- <div class="row justify-content-between mt-3">
                              <div id="user-list-page-info" class="col-md-6">
                                 <span>Showing 1 to 5 of 5 entries</span>
                              </div>
                              <div class="col-md-6">
                                 <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-end mb-0">
                                       <li class="page-item disabled">
                                          <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                                       </li>
                                       <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                       <li class="page-item"><a class="page-link" href="#">2</a></li>
                                       <li class="page-item"><a class="page-link" href="#">3</a></li>
                                       <li class="page-item">
                                          <a class="page-link" href="#">Next</a>
                                       </li>
                                    </ul>
                                 </nav>
                              </div>
                           </div> -->
                     </div>
                  </div>
            </div>
         </div>
      </div>
</template>
<script>
import axios from 'axios';
import CDataTable from "@/components/common/CDataTable.vue";
export default {
   name:'logisuserlist',
    components:{
        CDataTable
    },
   data() {
      return {
         userlists:[],
         auth:[],
         regEmail:'',
         badge:'',
          headers: [
              {text: 'Company ID', value: 'companyid', align: 'center', width: 100, isSlot: false},
              {text: 'Name', value: 'name', align: 'center', width: 100, isSlot: false},
              {text: 'Contact', value: 'phone', align: 'center', width: 100, isSlot: false},
              {text: 'Email', value: 'email', align: 'center', width: 100, isSlot: false},
            //   {text: '로그인승인', value: 'approval', align: 'center', width: 100, isSlot: true},
            //   {text: '구매요청메뉴', value: 'auth.showpurchase', align: 'center', width: 100, isSlot: true},
            //   {text: '로켓그로스메뉴', value: 'auth.showgrowth', align: 'center', width: 100, isSlot: true},
            {text: '채널톡', value: 'chatLink', align: 'center', width: 150, isSlot: true},  
            {text: 'Memo', value: 'memo', align: 'center', width: 150, isSlot: true},
              
          ]
      }
   },
   methods: {
      activeemail(){
         if(!this.regEmail){
            alert('이메일주소를 입력해 주세요')
            return
         } else {
            axios.post('/api/activeUserEmail',{email: this.regEmail})
             .then(result => {
               if(result.data.res == 'success'){
                  this.userlists.unshift(result.data.result);
               } else {
                  alert(result)
               }
            })
             .catch(console.log)
         }
      },
      changeusermemo(event,userlist){
         if(event.keyCode !== 13){
            return
         }
         console.log(userlist)
         axios.post('/api/updateUserList',userlist)
            .then(result => {
            if(result.data.res == 'success'){
               alert(result.data.res)
            } else {
               alert(result.data.res)
            }
         })
            .catch(console.log)
      },
      changeUserChatLink(event,userlist){
         if(event.keyCode !== 13){
            return
         }
         console.log(userlist)
         axios.post('/api/updateUserList',userlist)
            .then(result => {
            if(result.data.res == 'success'){
               alert(result.data.res)
            } else {
               alert(result.data.res)
            }
         })
            .catch(console.log)
      },
      modalID(index) {
         return 'modal' + index
      },
      bgcolor(approval){
         if(approval == true) {
            return 'badge bg-success'
         } else {
            return 'badge bg-secondary'
         }
      },
      onSubmit(event) {
         event.preventDefault()
         if (this.auth.length > 0){
            var authobject = {
               showdashboard : true,
               showproduct : this.auth.includes("product"),
               showpolist : this.auth.includes("polist"),
               showstock : this.auth.includes("stock"),
               showpurchase : this.auth.includes("purchase"),
               showreturn : this.auth.includes("return"),
               showdeveloper : false,
               showadmin : this.auth.includes("admin")
            };
             this.$store.commit('userauthupdate', {auth : authobject})
             axios.post('/userauthupdate',{email: this.$store.state.user.email, auth : authobject})
             .then(result => console.log(result.data.response))
             .catch(console.log)
         }
      },
      onReset(event) {
         event.preventDefault();
         this.auth = [];
      },
   },
   mounted() {
      this.$nextTick(function () {
         if(this.$store.state.user.auth.showadmin == true) {
            axios.post('/api/userlist',{companyid : this.$store.state.user.companyid})
            .then(result => {
               console.log(result)
               if(result.data.res == 'success'){
                  result.data.result.forEach(e => {
                     if(!e.memo){
                        e.memo = ''
                     }
                     if(!e.chatLink){
                        e.chatLink = '';
                     }
                  })
                  this.userlists = result.data.result
               } else {
                  alert(result)
               }
            })
            .catch(console.log)
         }
      })
   }

}
</script>
