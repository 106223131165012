<template>
   <section class="login-content">
         <div class="container">
            <div class="row align-items-center justify-content-center height-self-center">
               <div class="col-lg-8">
                  <div class="card auth-card">
                     <div class="card-body p-0">
                        <div class="d-flex align-items-center auth-content">
                           <div class="col-lg-7 align-self-center">
                              <div class="p-3">
                                 <h2 class="mb-2">Sign Up</h2>
                                 <p>회원가입 후 관리자 승인이 필요합니다.<br>모든 정보는 정확하게 작성해주세요</p>
                                 <form @submit.prevent="onSubmit()">
                                    <div class="row">
                                       <div class="col-lg-12">
                                          <div class="floating-label form-group" v-b-tooltip.left="'업체명은 공백없이 영문으로 작성해 주세요 (특수문자 제외)'">
                                             <input class="floating-input form-control" type="text" placeholder=" " v-model="companyid">
                                             <label>업체명(영문)</label>
                                          </div>
                                       </div>
                                       <div class="col-lg-6">
                                          <div class="floating-label form-group">
                                             <input class="floating-input form-control" type="email" placeholder=" " v-model="email">
                                             <label>이메일</label>
                                          </div>
                                       </div>
                                       <div class="col-lg-6">
                                          <div class="floating-label form-group">
                                             <input class="floating-input form-control" type="text" placeholder=" " v-model="name">
                                             <label>이름</label>
                                          </div>
                                       </div>
                                       <div class="col-lg-6">
                                          <div class="floating-label form-group">
                                             <input class="floating-input form-control" type="password" placeholder=" " v-model="pw">
                                             <label>비밀번호</label>
                                          </div>
                                       </div>
                                       <div class="col-lg-6">
                                          <div class="floating-label form-group">
                                             <input class="floating-input form-control" type="password" placeholder=" " v-model="pwconfirm">
                                             <label>비밀번호 확인</label>
                                          </div>
                                       </div>
                                       <div class="col-lg-6">
                                          <div class="floating-label form-group">
                                             <input class="floating-input form-control" type="tel" placeholder=" " v-model="phone">
                                             <label>연락처 (숫자만 입력)</label>
                                          </div>
                                       </div>
                                       <div class="col-lg-6">
                                          <div class="floating-label form-group" v-b-tooltip.right="'쿠팡윙 로그인 시 우측 상단 판매자명 클릭했을 때 아래에 나오는 A로 시작하는 업체코드(쿠팡 판매자코드)를 정확하게 입력해 주세요. (띄어쓰기 X, 소문자 X)'">
                                             <input class="floating-input form-control" type="text" placeholder=" " v-model="suppliercode">
                                             <label>쿠팡 판매자코드</label>
                                          </div>
                                       </div>
                                       <!-- <div class="col-lg-6">
                                          <div class="floating-label form-group">
                                             <input class="floating-input form-control" type="text" placeholder=" " v-model="businessNo">
                                             <label>사업자번호(세금계산서용)</label>
                                          </div>
                                       </div>
                                       <div class="col-lg-6">
                                          <div class="floating-label form-group">
                                             <input class="floating-input form-control" type="text" placeholder=" " v-model="emailForTax">
                                             <label>이메일주소(세금계산서용)</label>
                                          </div>
                                       </div> -->
                                       <!-- <div class="col-lg-6">
                                          <div class="floating-label form-group" v-b-tooltip.left="'쿠플러스 배대지 이용 시 인보이스에 작성되는 영문주소입니다. 해당사항이 없을 경우 아무거나 입력해 주세요.'">
                                             <input class="floating-input form-control" type="text" placeholder=" " v-model="address">
                                             <label>영문주소(구매대행통관용)</label>
                                          </div>
                                       </div> -->
                                       <div class="col-lg-6">
                                          <div class="floating-label form-group">
                                             <input class="floating-input form-control" type="text" placeholder=" " v-model="recommend">
                                             <label>추천인 이름 또는 쿠폰 번호</label>
                                          </div>
                                       </div>

                                       <div class="alert alert-danger" v-if="pw.indexOf('$') > 0">
                                          <div>
                                             '$'특수문자는 포함할 수 없습니다
                                          </div>
                                       </div>
                                       <div class="alert alert-danger" v-if="!pwrules.test(pw.trim()) && pw.length > 0">
                                          <div>
                                             password : 8~20자 이내, 영문+숫자+특수문자
                                          </div>
                                       </div>


                                       <div class="col-lg-12">
                                          <div class="form-check">
                                             <input class="form-check-input" type="checkbox" value="" id="invalidCheck2" required>
                                             <b-button v-b-toggle.collapse-2 class="m-1">개인정보활용동의(필수)</b-button>

                                             <b-collapse id="collapse-2">
                                                <b-card>
                                                   <div class="terms_box" tabindex="0" id="divPrivacy">
                                                      <!-- 개인정보 수집 및 이용에 대한 안내 -->
                                                      <div class="policy_summary">
                                                         <p class="policy_summary_text">
                                                            개인정보보호법에 따라 COUPLUS('쿠플러스')에 회원가입 신청하시는 분께
                                                            수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적, 개인정보의 보유
                                                            및 이용기간, 동의 거부권 및 동의 거부 시 불이익에 관한 사항을 안내
                                                            드리오니 자세히 읽은 후 동의하여 주시기 바랍니다.
                                                         </p><br>
                                                         <p class="policy_article_title">
                                                            1. 수집하는 개인정보
                                                         </p>
                                                         <p class="policy_summary_text">
                                                         이용자가 회원제 서비스를 이용하기 위해 회원가입을 할 경우, 쿠플러스는
                                                         서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.

                                                         회원가입 시점에 쿠플러스가 이용자로부터 수집하는 개인정보는 아래와 같습니다.
                                                         </p>
                                                         <p class="policy_summary_text">
                                                         - 회원 가입 시에 ‘아이디, 비밀번호, 이름, 이메일, 휴대전화번호’를
                                                         필수항목으로 수집합니다. 만약 이용자가 입력하는 생년월일이 만14세 미만
                                                         아동일 경우에는 법정대리인 정보(법정대리인의 이름, 휴대전화번호)를 추가로
                                                         수집합니다.

                                                         서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.
                                                         </p>
                                                         <p class="policy_summary_text">
                                                         - 회원정보 또는 개별 서비스에서 프로필 정보(별명, 프로필 사진)를 설정할
                                                         수 있습니다. 회원정보에 별명을 입력하지 않은 경우에는 마스킹 처리된
                                                         아이디가 별명으로 자동 입력됩니다.
                                                         - 쿠플러스 내의 개별 서비스 이용, 이벤트 응모 및 경품 신청 과정에서
                                                         해당 서비스의 이용자에 한해 추가 개인정보 수집이 발생할 수 있습니다.
                                                         추가로 개인정보를 수집할 경우에는 해당 개인정보 수집 시점에서 이용자에게
                                                         ‘수집하는 개인정보 항목, 개인정보의 수집 및 이용목적, 개인정보의
                                                         보관기간’에 대해 안내 드리고 동의를 받습니다.
                                                         </p>
                                                         <p class="policy_summary_text">
                                                         서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보,
                                                         위치정보가 생성되어 수집될 수 있습니다.
                                                         구체적으로 1) 서비스 이용 과정에서 이용자에 관한 정보를 자동화된 방법으로
                                                         생성하여 이를 저장(수집)하거나, 2) 이용자 기기의 고유한 정보를 원래의 값을
                                                         확인하지 못 하도록 안전하게 변환하여 수집합니다.
                                                         서비스 이용 과정에서 쿠팡 서플라이어에 등록된 상품,발주서,정산정보가
                                                         수집될 수 있으며, 쿠플러스에서 제공하는 데이터베이스에 연동될 수 있습니다.
                                                         이와 같이 수집된 정보는 개인정보와의 연계 여부 등에 따라 개인정보에 해당할
                                                         수 있고, 개인정보에 해당하지 않을 수도 있습니다.
                                                         </p><br>
                                                         <p class="policy_article_title">
                                                         2. 수집한 개인정보의 이용
                                                         </p>
                                                         <p class="policy_summary_text">
                                                         쿠플러스 및 쿠플러스 관련 제반 서비스(모바일 웹/앱 포함)의 회원관리,
                                                         서비스 개발・제공 및 향상, 안전한 인터넷 이용환경 구축 등 아래의 목적으로만
                                                         개인정보를 이용합니다.

                                                         - 회원 가입 의사의 확인, 연령 확인 및 법정대리인 동의 진행, 이용자 및
                                                         법정대리인의 본인 확인, 이용자 식별, 회원탈퇴 의사의 확인 등 회원관리를 위하여
                                                         개인정보를 이용합니다.
                                                         - 서비스 제공(광고 포함)에 더하여, 인구통계학적 분석, 서비스 방문 및
                                                         이용기록의 분석, 개인정보 및 관심에 기반한 이용자간 관계의 형성, 지인 및 관심사
                                                         등에 기반한 맞춤형 서비스 제공 등 신규 서비스 요소의 발굴 및 기존 서비스 개선
                                                         등을 위하여 개인정보를 이용합니다.
                                                         - 법령 및 쿠플러스 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용
                                                         행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재,
                                                         계정도용 및 부정거래 방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한
                                                         기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.
                                                         - 유료 서비스 제공에 따르는 본인인증, 구매 및 요금 결제, 상품 및 서비스의
                                                         배송을 위하여 개인정보를 이용합니다.
                                                         - 이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션
                                                         목적으로 개인정보를 이용합니다.
                                                         - 서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석
                                                         및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를 이용합니다.
                                                         - 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스
                                                         이용환경 구축을 위해 개인정보를 이용합니다.
                                                         </p><br>
                                                         <p class="policy_article_title">
                                                         3. 개인정보의 보관기간
                                                         </p>
                                                         <p class="policy_summary_text">
                                                         회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체없이 파기하고 있습니다.
                                                         </p>
                                                         <p class="policy_summary_text">
                                                         단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서
                                                         일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를
                                                         안전하게 보관합니다.
                                                         </p>
                                                         <p class="policy_summary_text">
                                                         이용자에게 개인정보 보관기간에 대해 회원가입 시 또는 서비스 가입 시 동의를
                                                         얻은 경우는 아래와 같습니다.
                                                         - 부정 가입 및 이용 방지
                                                         부정 이용자의 가입인증 휴대전화번호 또는 DI (만14세 미만의 경우 법정대리인DI)
                                                         : 탈퇴일로부터 6개월 보관
                                                         탈퇴한 이용자의 휴대전화번호(복호화가 불가능한 일방향 암호화(해시처리))
                                                         : 탈퇴일로부터 6개월 보관
                                                         ID : 서비스 탈퇴 후 6개월 보관
                                                         전자상거래 등에서의 소비자 보호에 관한 법률, 전자금융거래법, 통신비밀보호법
                                                         등 법령에서 일정기간 정보의 보관을 규정하는 경우는 아래와 같습니다.
                                                         쿠플러스는 이 기간 동안 법령의 규정에 따라 개인정보를 보관하며, 본 정보를
                                                         다른 목적으로는 절대 이용하지 않습니다.
                                                         - 전자상거래 등에서 소비자 보호에 관한 법률
                                                         계약 또는 청약철회 등에 관한 기록: 5년 보관
                                                         대금결제 및 재화 등의 공급에 관한 기록: 5년 보관
                                                         소비자의 불만 또는 분쟁처리에 관한 기록: 3년 보관
                                                         - 전자문서 및 전자거래 기본법
                                                         공인전자주소를 통한 전자문서 유통에 관한 기록 : 10년 보관
                                                         - 통신비밀보호법
                                                         로그인 기록: 3개월
                                                         </p>
                                                         <p class="policy_summary_text">
                                                         참고로 쿠플러스는 ‘개인정보 유효기간제’에 따라 1년간 서비스를 이용하지 않은
                                                         회원의 개인정보를 별도로 분리 보관하여 관리하고 있습니다.
                                                         </p><br>
                                                         <p class="policy_article_title">
                                                         4. 개인정보 수집 및 이용 동의를 거부할 권리
                                                         </p>
                                                         <p class="policy_summary_text">
                                                         이용자는 개인정보의 수집 및 이용 동의를 거부할 권리가 있습니다.
                                                         회원가입 시 수집하는 최소한의 개인정보, 즉, 필수 항목에 대한 수집 및 이용
                                                         동의를 거부하실 경우, 회원가입이 어려울 수 있습니다.</p>
                                                      </div>
                                                   </div>
                                                </b-card>
                                             </b-collapse>
                                             <!-- <label class="form-check-label" for="defaultCheck1">개인정보활용동의(필수)</label> -->

                                          </div>

                                       </div>
                                    </div>
                                    <div>
                                    <!-- Using modifiers -->

                                    </div>
                                    <div><br></div>
                                    <button type="submit" class="btn btn-primary">회원가입</button>
                                    <p class="mt-3">
                                       이미 아이디가 있는 경우 <router-link :to="{name: 'auth.login'}" class="text-primary"><u>로그인</u></router-link>
                                    </p>
                                 </form>
                              </div>
                           </div>
                           <div class="col-lg-5 content-right">
                              <img src="@/assets/images/login/01.png" class="img-fluid image-right" alt="">
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
</template>
<script>
import axios from 'axios'

export default {
   name:'SignUp',
   data() {
      return {
         companyid: "",
         email: "",
         name: "",
         phone: "",
         suppliercode: "",
         pw: "",
         address:'',
         pwconfirm: "",
         status: 'not_accepted',
         recommend: '',
         // businessNo:'',
         // emailForTax:'',
         pwrules: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
      }
   },
   methods: {
      gettoday(){
         var now = new Date();
         var date = now
         var year = date.getFullYear();
         var month = ("0" + (1 + date.getMonth())).slice(-2);
         var day = ("0" + date.getDate()).slice(-2);
         return year + "-" + month + "-" + day;
      },
      onSubmit () {
          let companyIdPattern = /^[a-zA-Z0-9]+$/;

          if (!companyIdPattern.test(this.companyid)) {
              alert("업체명은 공백없이 영문으로 작성해 주세요 (특수문자 제외)");
              return
          }
         if (this.pw !== this.pwconfirm || !this.pwrules.test(this.pw.trim()) || this.pw.length == 0) {
            alert("비밀번호를 확인해주세요");
            return
         }
         if (this.companyid.length == 0 || this.email.length == 0 || this.name.length == 0 || this.phone.length == 0 || this.suppliercode.length == 0) {
            alert("모든 정보를 정확히 기입해주세요")
            return
         }
         if(this.suppliercode[0] !== 'A' && this.suppliercode[0] !== 'C'){
            alert("쿠팡 판매자코드를 정확히 입력해 주세요(띄어쓰기 주의). 쿠팡 판매자코드는 쿠팡윙 로그인 후 우측 상단 판매자계정을 클릭하면 확인이 가능합니다. ex) 'A00123456'")
            return
         }

         var now = new Date();

         axios.post('/api/auth/sign-up/add',{
            regdate: this.gettoday(),
            companyid:this.companyid.trim(),
            email:this.email.trim(),
            name:this.name,
            phone:this.phone,
            suppliercode:this.suppliercode.toUpperCase().trim(),
            address:this.address,
            pw:this.pw,
            accesshistory:now,
            recommend:this.recommend,
            businessNo:this.businessNo,
            emailForTax:this.emailForTax,
            auth:{
               showdashboard : false,
               showproduct : false,
               showpolist : false,
               showsales : false,
               showimport : false,
               showexport : false,
               showstock : false,
               showpurchase : false,
               showreturn : false,
               showdeveloper : false,
               showadmin : false,
               showlite : false,
               showgrowth : false,
               shownomad : false,
            },
            slot:0,
            rankslot:0,
            grade:'vip',
         })
         .then(result => {
            if (result.data.res == 'registed') {
               alert("로그인 시 결제 확인 후 최종 가입이 완료됩니다")
               this.$router.push({name: 'auth.login'})
            } else {
               if (result.data.res == 'existid') {
                  alert("이미 가입된 이메일주소입니다.")
                  return
               } else if(result.data.res == 'existCompanyId') {
                  alert("이미 가입된 업체명입니다.")
                  return
               } else {
                  alert("등록오류(고객센터에 문의하세요) : " + result.data.res)
                  return
               }
            }
         }).catch((e)=>{
            console.log(e);
         })

      }
   }
}
</script>
<style scoped>
   .policy_article_title {
      font-size:11px;
      font-weight:bold;

   }
   .policy_summary_text {
      font-size:11px;
      line-height:150%;
   }
</style>
