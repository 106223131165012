<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{ title }}</h4>
                        <p class="mb-0">{{ desc1 }}<br> {{ desc2 }}</p>
                    </div>
                    <div class="d-flex flex-column w-25">
                        <div class="h-100 w-100">
                            <b-input-group>
                                <b-form-datepicker id="example-datepicker1" size="sm" v-model="startDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" class="mb-2"></b-form-datepicker>
                                <p>~</p>
                                <b-form-datepicker id="example-datepicker2" size="sm" v-model="endDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" class="mb-2"></b-form-datepicker>
                            </b-input-group>
                        </div>

                        <b-button variant="primary pl-5 pr-5" class="mt-2 mr-1" @click="collectAdList" v-if="showlist" :disabled="isLoadingAdList">
                            <b-spinner small v-if="isLoadingAdList"></b-spinner>
                            <span v-if="!isLoadingAdList">광고목록 불러오기</span>
                            <span v-else>불러오는 중...</span>
                        </b-button>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <img v-if="showspinner" style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
                <div v-if="showspinner1" class="d-flex justify-content-center">
                    <div><img style="max-width:10%;" :src="require('../../../assets/images/small/loading.gif')">{{loadingtext}}</div>
                </div>
            </div>

            <div class="col-lg-12" v-if="showlist">
                <div class="p-3">
                    <div class="row d-flex justify-content-between">
                        <div class="d-flex justify-content-start">
                            <b-button variant="outline-primary" class="mb-1 mr-2" @click="showtotal">
                            전체 <b-badge variant="primary" class="ml-2">{{total}}</b-badge>
                            </b-button>
                            <b-button variant="outline-danger" class="mb-1 mr-2" @click="showa">
                            검증완료(검색)<b-badge variant="danger" class="ml-2">{{검색검증완료}}</b-badge>
                            </b-button>
                            <b-button variant="outline-warning" class="mb-1 mr-2" @click="showb">
                            검증완료(비검색)<b-badge variant="warning" class="ml-2">{{비검색검증완료}}</b-badge>
                            </b-button>
                            <b-button variant="outline-success" class="mb-1 mr-2" @click="showc">
                            검증완료(전체)<b-badge variant="success" class="ml-2">{{전체검증완료}}</b-badge>
                            </b-button>
                            <b-button variant="outline-primary" class="mb-1 mr-2" @click="showd">
                            판매지속가능<b-badge variant="primary" class="ml-2">{{판매가능}}</b-badge>
                            </b-button>
                            <b-button variant="outline-secondary" class="mb-1 mr-2" @click="showe">
                            판매지속불가<b-badge variant="secondary" class="ml-2">{{판매불가}}</b-badge>
                            </b-button>
                        </div>
                        <div class="d-flex justify-content-end">
                            <b-button variant="success" class="" v-b-toggle="'settings'">설정</b-button>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-start mt-4">
                        <b-card class="border-success text-success"><b>전체수익 : {{전체수익.toLocaleString()}}</b></b-card>
                        <b-card class="border-danger text-danger ml-2"><b>광고비 : {{광고비.toLocaleString()}}</b></b-card>
                        <b-card class="border-primary text-primary ml-2"><b>광고비 제외 순수익 : {{광고순익.toLocaleString()}}</b></b-card>
                    </div>
                </div>
            </div>

			<b-collapse id="settings" class="col-lg-12">
				<b-card>
					<div class="p-3">
						<div class="row d-flex justify-content-start">
							<div class="text-center">
								<span>기본통합마진</span>
								<input type="number" class="form-control" placeholder="기본통합마진" v-model="defaultMargin">
							</div>
                            <!-- <div class="text-center">
								<span>최소CVR(%)</span>
								<input type="number" class="form-control" placeholder="3" v-model="minCVR">
							</div> -->
                            <div class="text-center">
								<span>상품성 한도(%)</span>
								<input type="number" class="form-control" placeholder="0.05" v-model="minMarketAbility">
							</div>
						</div>
					</div>
				</b-card>
			</b-collapse>


            <div class="col-lg-12" v-if="showlist">
                <CDataTable 
                    :headers="headers" 
                    :items="ads" 
                    :fixedColumns="'vendor_item_id'"
                    v-model="tableSelectedItem" 
                    :show-select="true" 
                    itemKey="_id"
                >
                <!-- <CDataTable :headers="headers" :items="ads" v-model="tableSelectedItem" :show-select="true" itemKey="_id" style="margin-bottom: 10px" :initial-selected-columns="initialSelectedColumns"> -->
                    <template v-slot:cpc_search="{item}">
                        {{parseInt(item.cpc_search)}}
                    </template>
                    <template v-slot:ctr_search="{item}">
                        <h6>{{Math.round(item.ctr_search*10000)/100}}%</h6>
                    </template>
                    <template v-slot:cvr_search="{item}">
                        <h6>{{Math.round(item.cvr_search*10000)/100}}%</h6>
                    </template>
                    <template v-slot:ctr_notSearch="{item}">
                        <h6>{{Math.round(item.ctr_notSearch*10000)/100}}%</h6>
                    </template>
                    <template v-slot:cvr_notSearch="{item}">
                        <h6>{{Math.round(item.cvr_notSearch*10000)/100}}%</h6>
                    </template>
                    <template v-slot:ctr_search_result="{item}">
                        {{item.ctr_search_result ? "O" : "X"}}
                    </template>
                    <template v-slot:cvr_search_result="{item}">
                        {{item.cvr_search_result ? "O" : "X"}}
                    </template>
                    <template v-slot:search_result="{item}">
                        {{item.search_result ? "O" : "X"}}
                    </template>
                    <template v-slot:cpc_notSearch="{item}">
                        {{parseInt(item.cpc_notSearch)}}
                    </template>
                    <template v-slot:ctr_notSearch_result="{item}">
                        {{item.ctr_notSearch_result ? "O" : "X"}}
                    </template>
                    <template v-slot:cvr_notSearch_result="{item}">
                        {{item.cvr_notSearch_result ? "O" : "X"}}
                    </template>
                    <template v-slot:notSearch_result="{item}">
                        {{item.notSearch_result ? "O" : "X"}}
                    </template>
                    <template v-slot:total_result="{item}">
                        {{item.total_result ? "O" : "X"}}
                    </template>

                    <template v-slot:min_cvr_search="{item}">
                        <h6 class="text-success" v-if="item.min_cvr_search < item.cvr_search">{{Math.round(item.min_cvr_search*10000)/100}}%</h6>
                        <h6 class="text-secondary" v-if="item.min_cvr_search >= item.cvr_search">{{Math.round(item.min_cvr_search*10000)/100}}%</h6>
                    </template>
                    <template v-slot:min_cvr_notSearch="{item}">
                        <h6 class="text-success" v-if="item.min_cvr_notSearch < item.cvr_notSearch">{{Math.round(item.min_cvr_notSearch*10000)/100}}%</h6>
                        <h6 class="text-secondary" v-if="item.min_cvr_notSearch >= item.cvr_notSearch">{{Math.round(item.min_cvr_notSearch*10000)/100}}%</h6>
                    </template>

                    <template v-slot:adRoi_search="{item}">
                        <h6 class="text-success" v-if="item.adRoi_search >= 1">{{Math.round(item.adRoi_search*10000)/100}}%</h6>
                        <h6 class="text-secondary" v-if="item.adRoi_search < 1">{{Math.round(item.adRoi_search*10000)/100}}%</h6>
                    </template>
                    <template v-slot:adRoi_notSearch="{item}">
                        <h6 class="text-success" v-if="item.adRoi_notSearch >= 1">{{Math.round(item.adRoi_notSearch*10000)/100}}%</h6>
                        <h6 class="text-secondary" v-if="item.adRoi_notSearch < 1">{{Math.round(item.adRoi_notSearch*10000)/100}}%</h6>
                    </template>
                    <template v-slot:marketAbility="{item}">
                        <div v-if="item.search_result && item.marketAbility > minMarketAbility/100" class="text-success" style="font-weight: bolder;">{{Math.round(item.marketAbility*100000)/1000}}%</div>
                        <div v-if="item.search_result && item.marketAbility <= minMarketAbility/100" class="text-secondary" style="font-weight: bolder;">{{Math.round(item.marketAbility*100000)/1000}}%</div>
                    </template>
                    <template v-slot:merit="{item}">
                        <div v-if="item.search_result && item.merit && item.merit > 0" class="text-success" style="font-weight: bolder;">{{Math.round(item.merit*100000)/1000}}%</div>
                        <div v-if="item.search_result && item.merit && item.merit <= 0" class="text-secondary" style="font-weight: bolder;">{{Math.round(item.merit*100000)/1000}}%</div>
                    </template>

                    <!-- <template v-slot:category_7days_ctr="{item}">
                        <div v-if="item.item_7days_ctr/item.category_7days_ctr > 1" class="text-success" style="font-weight: bolder;">{{Math.round((item.item_7days_ctr-item.category_7days_ctr)/item.category_7days_ctr*10000)/100}}%</div>
                        <div v-else class="text-secondary" style="font-weight: bolder;">{{Math.round((item.item_7days_ctr-item.category_7days_ctr)/item.category_7days_ctr*1000)/10}}%</div>
                    </template>
                    <template v-slot:category_7days_cvr="{item}">
                        <div v-if="item.item_7days_cvr/item.category_7days_cvr > 1" class="text-success" style="font-weight: bolder;">{{Math.round((item.item_7days_cvr-item.category_7days_cvr)/item.category_7days_cvr*10000)/100}}%</div>
                        <div v-else class="text-secondary" style="font-weight: bolder;">{{Math.round((item.item_7days_cvr-item.category_7days_cvr)/item.category_7days_cvr*1000)/10}}%</div>
                    </template> -->



                    <template v-slot:cpc_search_usl="{item}">
                        {{parseInt(item.cpc_search_usl)}}
                    </template>
                    <template v-slot:cpc_notSearch_usl="{item}">
                        {{parseInt(item.cpc_notSearch_usl)}}
                    </template>

                    <template v-slot:cpc_search_opt="{item}">
                        <div class="text-primary" style="font-weight: bolder;">{{item.cpc_search_opt}}</div>
                    </template>
                    <template v-slot:cpc_notSearch_opt="{item}">
                        <div class="text-primary" style="font-weight: bolder;">{{item.cpc_notSearch_opt}}</div>
                    </template>

                    <template v-slot:totalSum_search="{item}">
                        <div v-if="item.totalSum_search >= 0" class="text-success" style="font-weight: bolder;">{{item.totalSum_search}}</div>
                        <div v-if="item.totalSum_search < 0" class="text-secondary" style="font-weight: bolder;">{{item.totalSum_search}}</div>
                    </template>

                    <template v-slot:totalSum_notSearch="{item}">
                        <div v-if="item.totalSum_notSearch >= 0" class="text-success" style="font-weight: bolder;">{{item.totalSum_notSearch}}</div>
                        <div v-if="item.totalSum_notSearch < 0" class="text-secondary" style="font-weight: bolder;">{{item.totalSum_notSearch}}</div>
                    </template>

                    <template v-slot:ad_cost_sum_search="{item}">
                        <div class="text-primary" style="font-weight: bolder;">{{item.ad_cost_sum_search}}</div>
                    </template>

                    <template v-slot:ad_cost_sum_notSearch="{item}">
                        <div class="text-primary" style="font-weight: bolder;">{{item.ad_cost_sum_notSearch}}</div>
                    </template>

                    <template v-slot:ad_profit_sum_search="{item}">
                        <div v-if="item.ad_profit_sum_search >= 0" class="text-success" style="font-weight: bolder;">{{item.ad_profit_sum_search}}</div>
                        <div v-if="item.ad_profit_sum_search < 0" class="text-secondary" style="font-weight: bolder;">{{item.ad_profit_sum_search}}</div>
                    </template>

                    <template v-slot:ad_profit_sum_notSearch="{item}">
                        <div v-if="item.ad_profit_sum_notSearch >= 0" class="text-success" style="font-weight: bolder;">{{item.ad_profit_sum_notSearch}}</div>
                        <div v-if="item.ad_profit_sum_notSearch < 0" class="text-secondary" style="font-weight: bolder;">{{item.ad_profit_sum_notSearch}}</div>
                    </template>



                    <template v-slot:totalSum="{item}">
                        <div v-if="item.totalSum >= 0" class="text-success" style="font-weight: bolder;">{{item.totalSum}}</div>
                        <div v-if="item.totalSum < 0" class="text-secondary" style="font-weight: bolder;">{{item.totalSum}}</div>
                    </template>
                    <template v-slot:ad_cost_sum="{item}">
                        <div class="text-primary" style="font-weight: bolder;">{{item.ad_cost_sum}}</div>
                    </template>
                    <template v-slot:ad_profit_sum="{item}">
                        <div v-if="item.ad_profit_sum >= 0" class="text-success" style="font-weight: bolder;">{{item.ad_profit_sum}}</div>
                        <div v-if="item.ad_profit_sum < 0" class="text-secondary" style="font-weight: bolder;">{{item.ad_profit_sum}}</div>
                    </template>


                    <template v-slot:margin_search="{item}">
                        {{item.margin_search}}
                        <!-- <div class="custom-control-inline">
                            <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="item.margin_search" style="text-align:center;" @change="getAdResult(item)">
                        </div> -->
                    </template>

                    <template v-slot:margin_notSearch="{item}">
                        {{item.margin_notSearch}}
                        <!-- <div class="custom-control-inline">
                            <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="item.margin_notSearch" style="text-align:center;" @change="getAdResult(item)">
                        </div> -->
                    </template>

                    <template v-slot:analysis_result_marketability="{item}">
                        <div class="d-flex justify-content-center" v-if="item.analysis_result_marketability == 'O'">
                            <svg class="w-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                <circle cx="30" cy="30" r="28" fill="#4CAF50"/>
                                <path d="M30 10 L40 25 L20 25 Z" fill="white"/>
                                <rect x="25" y="23" width="10" height="20" fill="white"/>
                            </svg>
                        </div>
                        <div class="d-flex justify-content-center" v-if="item.analysis_result_marketability == 'X'">
                            <svg class="w-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                <circle cx="30" cy="30" r="28" fill="#F44336"/>
                                <rect x="20" y="20" width="20" height="20" fill="white"/>
                            </svg>
                        </div>
                    </template>

                    <template v-slot:highRank_marketAbility="{item}">
                        <div class="d-flex justify-content-center" v-if="item.highRank_marketAbility == true">
                            <svg class="w-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                <circle cx="30" cy="30" r="28" fill="#4CAF50"/>
                                <path d="M30 10 L40 25 L20 25 Z" fill="white"/>
                                <rect x="25" y="23" width="10" height="20" fill="white"/>
                            </svg>
                        </div>
                    </template>

                    <template v-slot:analysis_result_adSearch="{item}">
                        <div class="d-flex justify-content-center" v-if="item.analysis_result_adSearch == '광고최적화'" >
                            <svg class="w-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                <circle cx="30" cy="30" r="28" fill="#4CAF50"/>
                                <path d="M30 10 L40 25 L20 25 Z" fill="white"/>
                                <rect x="25" y="23" width="10" height="20" fill="white"/>
                            </svg>
                        </div>
                        <div class="d-flex justify-content-center" v-if="item.analysis_result_adSearch == '광고유지'" >
                            <svg class="w-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                <circle cx="30" cy="30" r="28" fill="#FFC107"/>
                                <rect x="15" y="20" width="30" height="6" fill="white"/>
                                <rect x="15" y="34" width="30" height="6" fill="white"/>
                            </svg>
                        </div>
                        <div class="d-flex justify-content-center" v-if="item.analysis_result_adSearch == '광고최소화'" >
                            <svg class="w-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                <circle cx="30" cy="30" r="28" fill="#FFC107"/>
                                <path d="M30 50 L40 35 L20 35 Z" fill="white"/>
                                <rect x="25" y="17" width="10" height="20" fill="white"/>
                            </svg>
                        </div>
                        <div class="d-flex justify-content-center" v-if="item.analysis_result_adSearch == '광고중지'" >
                            <svg class="w-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                <circle cx="30" cy="30" r="28" fill="#F44336"/>
                                <rect x="20" y="20" width="20" height="20" fill="white"/>
                            </svg>
                        </div>
                    </template>

                    <template v-slot:analysis_result_adNotSearch="{item}">
                        <div class="d-flex justify-content-center" v-if="item.analysis_result_adNotSearch == '광고최적화'" >
                            <svg class="w-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                <circle cx="30" cy="30" r="28" fill="#4CAF50"/>
                                <path d="M30 10 L40 25 L20 25 Z" fill="white"/>
                                <rect x="25" y="23" width="10" height="20" fill="white"/>
                            </svg>
                        </div>
                        <div class="d-flex justify-content-center" v-if="item.analysis_result_adNotSearch == '광고유지'" >
                            <svg class="w-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                <circle cx="30" cy="30" r="28" fill="#FFC107"/>
                                <rect x="15" y="20" width="30" height="6" fill="white"/>
                                <rect x="15" y="34" width="30" height="6" fill="white"/>
                            </svg>
                        </div>
                        <div class="d-flex justify-content-center" v-if="item.analysis_result_adNotSearch == '광고최소화'" >
                            <svg class="w-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                <circle cx="30" cy="30" r="28" fill="#FFC107"/>
                                <path d="M30 50 L40 35 L20 35 Z" fill="white"/>
                                <rect x="25" y="17" width="10" height="20" fill="white"/>
                            </svg>
                        </div>
                        <div class="d-flex justify-content-center" v-if="item.analysis_result_adNotSearch == '광고중지'" >
                            <svg class="w-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                <circle cx="30" cy="30" r="28" fill="#F44336"/>
                                <rect x="20" y="20" width="20" height="20" fill="white"/>
                            </svg>
                        </div>
                    </template>

                    <template v-slot:continue="{item}">
                        <div class="d-flex justify-content-center" v-if="item.continue == true" >
                            <svg class="w-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                <circle cx="30" cy="30" r="28" fill="#4CAF50"/>
                                <path d="M30 10 L40 25 L20 25 Z" fill="white"/>
                                <rect x="25" y="23" width="10" height="20" fill="white"/>
                            </svg>
                        </div>
                        <div class="d-flex justify-content-center" v-if="item.continue == false" >
                            <svg class="w-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                                <circle cx="30" cy="30" r="28" fill="#F44336"/>
                                <rect x="20" y="20" width="20" height="20" fill="white"/>
                            </svg>
                        </div>
                    </template>

                </CDataTable>
            </div>
            
                        
            <div class="col-lg-12 mb-3" v-if="showlist">
                <button type="reset" @click="excelDownFunc" class="btn btn-success mr-2 mb-3">다운로드</button>
            </div>

            <b-modal :id="'addManage'" size="md" title="숨김처리취소" ok-only>
                <button type="reset" @click="importHideProducts" class="btn btn-primary mr-2 mb-3">숨김상품 불러오기</button>
                <div class="row">
                    <img v-if="showspinner2" style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
                </div>
                <div class="row" v-if="showHideProducts">
                    <div class="col-12 mt-3">
                        <div class="table-responsive">
                            <table class="table data-table table-striped dataTable">
                                <thead>
                                    <tr class="ligth ligth-data">
                                        <th style="min-width:200px;font-size:90%">제품정보</th>
                                        <th style="font-size:90%">숨김처리취소</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(list,index) in searchProducts" :key="index">
                                        <td style="min-width:300px;">
                                            <div class="d-flex align-items-center" v-b-modal="'modalpurchase' + index">
                                                <img :src="list.optionImageUrl" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                <div>
                                                    {{ list.productName }}
                                                    <p class="mb-0"><small>노출Id : {{ list.productId }}</small></p>
                                                    <p class="mb-0"><small>옵션Id : {{ list.vendorItemId }}</small></p>
                                                    <p class="mb-0"><small>승인상태 : {{ list.statusName }}</small></p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <b-button @click="addSearchProduct(list,index)" variant="outline-primary" class="text-center">숨김처리취소</b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-modal>


            <b-modal :id="'adList'" size="xl" title="광고목록선택" ok-only>
                <div class="row">
                    <div class="mb-2 col-6 d-flex justify-content-start">
                        <div class="h-100 w-50">
                            <b-input-group>
                                <b-form-datepicker id="example-datepicker1" size="sm" v-model="startDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" class="mb-2"></b-form-datepicker>
                                <p>~</p>
                                <b-form-datepicker id="example-datepicker2" size="sm" v-model="endDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" class="mb-2"></b-form-datepicker>
                            </b-input-group>
                        </div>
                        <b-button @click="applyAdList" variant="outline-primary" class="text-center ml-2 w-25 h-75">광고분석(소싱검증)</b-button>
                    </div>
                    <div class="col-12">
                        <CDataTable :headers="adListHeaders" :items="adList" v-model="adListSelectedItem" :show-select="true" itemKey="id" style="margin-bottom: 10px">
                            <template v-slot:status="{item}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="24" viewBox="0 0 40 24" :class="['toggle-icon', item.status ? 'on' : 'off']">
                                    <rect x="2" y="2" width="36" height="20" rx="10" ry="10" class="toggle-background"/>
                                    <circle :cx="item.status ? 28 : 12" cy="12" r="8" class="toggle-switch"/>
                                </svg>
                            </template>
                        </CDataTable>
                        
                    </div>
                </div>
                
                
                
            </b-modal>

        </div>
        <!-- Page end  -->
    </div>
</template>
<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
import CDataTable from "@/components/common/CDataTable";
export default {
    name: 'Listproduct',
    data() {
        return {
            total : 0,
            검색검증완료 : 0,
            비검색검증완료 : 0,
            전체검증완료 : 0,
            전체수익 : 0,
            광고비 : 0,
            광고순익 : 0,

            defaultMargin : 5000,
            // minCVR : 3,
            minMarketAbility : 0.05,

            startDate:this.getDates().oneMonthAgo,
            endDate:this.getDates().yesterday,
            adList:[],
            adListSelectedItem:[],
            isLoadingAdList: false,

            ads: [],
            adsInit: [],
            initialSelectedColumns:['campaign','adProduct','vendor_item_id','ctr_search','cvr_search','ctr_notSearch','cvr_notSearch','margin_search','margin_notSearch','adRoi_search','adRoi_notSearch','marketAbility','merit','cpc_search','cpc_notSearch','cpc_search_usl','cpc_notSearch_usl','analysis_result_marketability','analysis_result_adSearch','analysis_result_adNotSearch'],

            adListHeaders:[
                {text: '캠페인이름', value: 'campaign', align: 'center', width: 80, isSlot: false},
                {text: 'ON/OFF', value: 'status', align: 'center', width: 100, isSlot: true},
                {text: '예산', value: 'budget', align: 'center', width: 100, isSlot: false},
                {text: '시작날짜', value: 'startDate', align: 'center', width: 100, isSlot: false},
                {text: '광고운영방식', value: 'adType', align: 'center', width: 100, isSlot: false},
            ],

            headers: [
                {text: '광고\n캠페인\n이름', value: 'campaign', align: 'center', width: 100, isSlot: false},
                {text: '광고그룹', value: 'adGroup', align: 'center', width: 100, isSlot: false},
                {text: '광고상품', value: 'adProduct', align: 'center', width: 300, isSlot: false},
                {text: 'vendor_item_id', value: 'vendor_item_id', align: 'center', width: 80, isSlot: false},
                // {text: '전환상품', value: '판매링크', align: 'center', width: 100, isSlot: false},

                {text: '노출수\n검색', value: 'impressions_search', align: 'center', width: 80, isSlot: false},
                {text: '클릭수\n검색', value: 'clicks_search', align: 'center', width: 80, isSlot: false},
                {text: '주문수\n검색', value: 'orders_search', align: 'center', width: 80, isSlot: false},
                {text: '판매수\n검색', value: 'sales_search', align: 'center', width: 80, isSlot: false},
                

                {text: '노출수\n비검색', value: 'impressions_notSearch', align: 'center', width: 80, isSlot: false},
                {text: '클릭수\n비검색', value: 'clicks_notSearch', align: 'center', width: 80, isSlot: false},
                {text: '주문수\n비검색', value: 'orders_notSearch', align: 'center', width: 80, isSlot: false},
                {text: '판매수\n비검색', value: 'sales_notSearch', align: 'center', width: 80, isSlot: false},
                
                
                // {text: 'CTR검증(검색)', value: 'ctr_search_result', align: 'center', width: 100, isSlot: true},
                // {text: 'CVR검증(검색)', value: 'cvr_search_result', align: 'center', width: 100, isSlot: true},
                // {text: '검증완료(검색)', value: 'search_result', align: 'center', width: 100, isSlot: true},
                
                
                // {text: 'CTR검증(비검색)', value: 'ctr_notSearch_result', align: 'center', width: 100, isSlot: true},
                // {text: 'CVR검증(비검색)', value: 'cvr_notSearch_result', align: 'center', width: 100, isSlot: true},
                // {text: '검증완료(비검색)', value: 'notSearch_result', align: 'center', width: 100, isSlot: true},


                // {text: '검증완료(전체)', value: 'total_result', align: 'center', width: 100, isSlot: true},
                
                
                {text: '총수익\n검색', value: 'totalSum_search', align: 'center', width: 80, isSlot: true},
                {text: '총수익\n비검색', value: 'totalSum_notSearch', align: 'center', width: 80, isSlot: true},
                {text: '광고비\n검색', value: 'ad_cost_sum_search', align: 'center', width: 80, isSlot: true},
                {text: '광고비\n비검색', value: 'ad_cost_sum_notSearch', align: 'center', width: 80, isSlot: true},
                {text: '순수익\n검색', value: 'ad_profit_sum_search', align: 'center', width: 80, isSlot: true},
                {text: '순수익\n비검색', value: 'ad_profit_sum_notSearch', align: 'center', width: 80, isSlot: true},

                {text: '총수익\n전체', value: 'totalSum', align: 'center', width: 80, isSlot: true},
                {text: '광고비\n전체', value: 'ad_cost_sum', align: 'center', width: 80, isSlot: true},
                {text: '순수익\n전체', value: 'ad_profit_sum', align: 'center', width: 80, isSlot: true},

                {text: '주문건당\n마진\n검색', value: 'margin_search', align: 'center', width: 80, isSlot: true},
                {text: '주문건당\n마진\n비검색', value: 'margin_notSearch', align: 'center', width: 80, isSlot: true},
                
                {text: 'CPC\n검색\n한도', value: 'cpc_search_usl', align: 'center', width: 80, isSlot: true},
                {text: 'CPC\n비검색\n한도', value: 'cpc_notSearch_usl', align: 'center', width: 80, isSlot: true},
                {text: 'CPC\n검색\n현재', value: 'cpc_search', align: 'center', width: 80, isSlot: true},
                {text: 'CPC\n비검색\n현재', value: 'cpc_notSearch', align: 'center', width: 80, isSlot: true},
                {text: 'CPC\n검색\n최적', value: 'cpc_search_opt', align: 'center', width: 80, isSlot: true},
                {text: 'CPC\n비검색\n최적', value: 'cpc_notSearch_opt', align: 'center', width: 80, isSlot: true},

                {text: 'CTR\n검색', value: 'ctr_search', align: 'center', width: 80, isSlot: true},
                {text: 'CVR\n검색', value: 'cvr_search', align: 'center', width: 80, isSlot: true},
                {text: 'CTR\n비검색', value: 'ctr_notSearch', align: 'center', width: 80, isSlot: true},
                {text: 'CVR\n비검색', value: 'cvr_notSearch', align: 'center', width: 80, isSlot: true},

                {text: '최소CVR\n검색', value: 'min_cvr_search', align: 'center', width: 80, isSlot: true},
                {text: '최소CVR\n비검색', value: 'min_cvr_notSearch', align: 'center', width: 80, isSlot: true},
                {text: '광고ROI\n검색', value: 'adRoi_search', align: 'center', width: 80, isSlot: true},
                {text: '광고ROI\n비검색', value: 'adRoi_notSearch', align: 'center', width: 80, isSlot: true},

                {text: '상품성', value: 'marketAbility', align: 'center', width: 100, isSlot: true},
                {text: '상품성\n상대평가', value: 'merit', align: 'center', width: 100, isSlot: true},
                // {text: 'CTR\n카테고리\n평균대비', value: 'category_7days_ctr', align: 'center', width: 80, isSlot: true},
                // {text: 'CVR\n카테고리\n평균대비', value: 'category_7days_cvr', align: 'center', width: 80, isSlot: true},

                {text: '키워드\n노출전략', value: 'analysis_result_marketability', align: 'center', width: 80, isSlot: true},
                {text: '상위\n노출전략', value: 'highRank_marketAbility', align: 'center', width: 80, isSlot: true},
                {text: '검색\n광고전략', value: 'analysis_result_adSearch', align: 'center', width: 80, isSlot: true},
                {text: '비검색\n광고전략', value: 'analysis_result_adNotSearch', align: 'center', width: 80, isSlot: true},

                {text: 'Continue', value: 'continue', align: 'center', width: 100, isSlot: true},


                // {text: 'Actions', value: '', align: 'center', width: 100, isSlot: true},
            ],
			tableSelectedItem: [],

            showspinner2:false,
            showHideProducts:false,
            showPurchase:true,

            packageMethod : 'auto',
            searchProducts:[],
            addProducts:[],
            노출상품번호:'',
            등록상품번호:'',
            옵션번호:'',
            purchaseItem : {},
            isCollapseOpen : false,
            allchecked:false,

            PurchaseMappingSearch: false,
            searchPurchaseMapping: '',
            loadingtext: '',

            title: "광고관리(소싱검증)",
            desc1: "소싱검증을 위한 광고목록 불러오기로 불러오면 (기간선택 가능) 해당 광고데이터로 부터 소싱검증 결과가 분석됩니다.",
            desc2: "로켓그로스 > 상품관리에서 상품DB를 수집한 후 매입가를 입력해 놓으면 마진이 자동으로 입력됩니다.",

            showlist: false,
            showspinner: false,
            showspinner1: false,
            barcode: '',
            SKUID: '',
            상품명: '',
            구매링크: '',
            제품분류: '',
            editQty:false,
            printskuenable: true,
            printkcenable: false,
            원산지: 'Made in China',

            // link:{ name: 'product.addproduct'},
            // linktext:"Add Product",
            //    selected: '',
            //    menuint: ["옵션1_중국어","옵션2_중국어","제품분류","판매구성수량","구매가능상태"],
            //    menuDefault: ["옵션1_중국어","옵션2_중국어","제품분류","판매구성수량","구매가능상태"],
            //    menuCost: ["발주가능상태","매입단가","공급가액","부가세액"],
            //    // menuSales: ["바코드","구매링크","판매가격","판매링크","판매재고","판매상품명"],
             //this.$store.state.productdb,
            productListsinit: [],

            바코드출력: '',
            SKU출력: '',
            출력수량: 1,
            바코드간격: 0,

            enabledetail: true,

            출력SKUID: '',
            출력바코드: '',
            출력상품명: '',
            한글표시사항: {
                '수입원/판매원': '',
                제조원: '',
                제조국: '',
                내용량: '',
                '원료명및성분명(재질)': '',
                상품유형: '',
                사용시주의사항: '',
                사용기준: '',
            },
            bcvalue: '',
            bctag: 'svg',
            bcoptions: {
                lineColor: '#ff7069',
                fontSize: 50,
                font: 'Courier',
                width: 3,
                height: 60,
                marginBottom: 60,
                format: 'MSI',
                background: '#ccffff'
            },
            아이템루저:0,
            뱃지없음:0,
            구매정보있음:0,
            구매정보없음:0,
            매입정보없음:0,
            editmode:false,
            showImport:true,
            currentIndex:'',


        }
    },
    components: {
        CDataTable
    },
    beforeDestroy() {
		window.removeEventListener('message', this.messageEventHandler);
	},	
    mounted() {
		this.messageEventHandler = async(event) => {
            if (event.data.res && event.data.res === 'growthdbratio'){
                if(event.data.ratio == 0){
                    this.loadingtext = event.data.text    
                } else {
                    this.loadingtext = event.data.text + event.data.ratio.toFixed(0) + '%'
                }
            }
            if(event.data.res && event.data.res === 'collectAdList'){
                console.log(event.data)
                this.makeAdList(event.data.adList);
                this.isLoadingAdList = false;  // 여기에 추가
            }
            if(event.data.res && event.data.res === 'collectDetailAdList'){
                console.log(event.data)
                this.loadingtext = "광고데이터 분석 중..."
                this.makeAdSourcing(event.data.adList);
                this.isLoadingAdList = false;
                // this.isLoadingAdList = false;  // 여기에 추가
            }
		}
		window.addEventListener('message', this.messageEventHandler);
        this.$nextTick(function () {
            this.search()
        })
    },
    computed: {
        
        enablekr() {
            if (this.enabledetail) {
                return 'font-size:80%;color:black;line-height:110%;display:block;margin-bottom:0px;'
            } else {
                return 'font-size:80%;color:black;line-height:110%;display:none'
            }
        },
        disablekr() {
            if (this.enabledetail) {
                return 'font-size:80%;color:black;line-height:110%;display:none'
            } else {
                return 'font-size:80%;color:black;line-height:110%;display:block'
            }
        },
        printsku() {
            if (this.printskuenable) {
                return 'font-size:90%;color:black;'
            } else {
                return 'display:none;font-size:90%;color:black;'
            }
        },
        printkc() {
            if (this.printkcenable) {
                return 'font-size:90%;color:black;margin-left:20px;margin-top:10px'
            } else {
                return 'display:none;font-size:90%;color:black;'
            }
        }
    },
    methods: {
        saveAds(){
            this.saveAdSourcingList(this.ads)
        },
        getAdResult(item){
            // console.log(item)91233448911
            console.log(this.$store.state.growthdb?.find(f => f.vendorItemId == item.vendor_item_id))

            item.margin_search = this.$store.state.growthdb?.find(f => f.vendorItemId == item.vendor_item_id)?.마진 || this.defaultMargin;
            item.margin_notSearch = this.$store.state.growthdb?.find(f => f.vendorItemId == item.vendor_item_id)?.마진 || this.defaultMargin;
            console.log(item.margin_search)
            console.log(item.margin_notSearch)

            
            var totalSum_search = 0;
            // var totalCnt_search = 0;
            if(item.salesList_search.length > 0){
                item.salesList_search.forEach(f => {
                    var margin = this.$store.state.growthdb?.find(ele => ele.vendorItemId == f.vendor_item_id)?.마진 || this.defaultMargin;
                    var sum = margin * f.total_unit_14_days;
                    // totalCnt_search += f.total_unit_14_days;
                    totalSum_search += sum;
                })
            }
            item.margin_search = item.orders_search > 0 ? parseInt(totalSum_search/item.orders_search) : 0;
            item.ad_profit_sum_search = totalSum_search - item.ad_cost_sum_search
            item.totalSum_search = totalSum_search;
            
            
            var totalSum_notSearch = 0;
            // var totalCnt_notSearch = 0;
            if(item.salesList_notSearch.length > 0){
                item.salesList_notSearch.forEach(f => {
                    var margin = this.$store.state.growthdb?.find(ele => ele.vendorItemId == f.vendor_item_id)?.마진 || this.defaultMargin;
                    var sum = margin * f.total_unit_14_days;
                    // totalCnt_notSearch += f.total_unit_14_days;
                    totalSum_notSearch += sum;
                })
            }
            item.margin_notSearch = item.orders_notSearch > 0 ? parseInt(totalSum_notSearch/item.orders_notSearch) : 0;
            item.ad_profit_sum_notSearch = totalSum_notSearch - item.ad_cost_sum_notSearch
            item.totalSum_notSearch = totalSum_notSearch;

            item.min_cvr_search = item.margin_search > 0 ? 100/item.margin_search : 0;
            item.min_cvr_notSearch = item.margin_notSearch > 0 ? 100/item.margin_notSearch : 0;


            // var margin_search = item.margin_search;
            // var margin_notSearch = item.margin_notSearch;

            var analysis_result_marketability = "";
            var analysis_result_adSearch = "";
            var analysis_result_adNotSearch = "";

            //CPC한도 = 마진x전환율
            item.cpc_search_usl = item.cvr_search*item.margin_search;
            item.cpc_notSearch_usl = item.cvr_notSearch*item.margin_notSearch;

            var adRoi_search = item.ad_cost_sum_search > 0 ? (item.ad_cost_sum_search + item.ad_profit_sum_search) / item.ad_cost_sum_search : 0;
            var adRoi_notSearch = item.ad_cost_sum_notSearch > 0 ? (item.ad_cost_sum_notSearch + item.ad_profit_sum_notSearch) / item.ad_cost_sum_notSearch : 0;

            var cont = null;
            var cont_marketAbility = null;
            var cont_ad_search = null;
            var cont_ad_notSearch = null;
            item.highRank_marketAbility = null;

            var search_result = item.search_result;
            if(search_result){
                if(item.marketAbility > this.minMarketAbility/100){
                    analysis_result_marketability = "O";
                    cont_marketAbility = true;
                } else {
                    analysis_result_marketability = "X";
                    cont_marketAbility = false;
                }

                // if(item.marketAbility > this.minMarketAbility/100){
                if(item.is_category_marketAbility){
                    if(item.merit && item.merit > 0){
                        item.highRank_marketAbility = true;    
                    } else {
                        item.highRank_marketAbility = false;
                    }
                }
                // } else {
                //     analysis_result_marketability = "X";
                //     cont_marketAbility = false;
                // }

                if(item.cvr_search < item.min_cvr_search){
                    analysis_result_adSearch = "광고중지";
                    cont_ad_search = false;
                } else {
                    if(item.cpc_search_usl <= item.cpc_search){
                        analysis_result_adSearch = "광고중지";
                        cont_ad_search = false;
                    }
                    if(item.cpc_search_usl*0.6 <= item.cpc_search && item.cpc_search_usl > item.cpc_search){
                        analysis_result_adSearch = "광고최소화";
                        cont_ad_search = true;
                    }
                    if(item.cpc_search_usl*0.4 <= item.cpc_search && item.cpc_search_usl*0.6 > item.cpc_search){
                        analysis_result_adSearch = "광고유지";
                        cont_ad_search = true;
                    }
                    if(100 <= item.cpc_search && item.cpc_search_usl*0.4 > item.cpc_search){
                        analysis_result_adSearch = "광고최적화";
                        cont_ad_search = true;
                    }
                    if(100 > item.cpc_search){
                        analysis_result_adSearch = "광고중지";
                        cont_ad_search = false;
                    }
                }
            } else {
                if((item.orders_search + 1)/item.clicks_search < item.min_cvr_search){
                    analysis_result_adSearch = "광고중지";
                    cont_ad_search = false;
                    analysis_result_marketability = "X";
                    cont_marketAbility = false;
                }
            }
            var notSearch_result = item.notSearch_result;
            if(notSearch_result){
                if(item.cvr_notSearch < item.min_cvr_notSearch){
                    analysis_result_adNotSearch = "광고중지";
                    cont_ad_notSearch = false;
                } else {
                    if(item.cpc_notSearch_usl <= item.cpc_notSearch){
                        analysis_result_adNotSearch = "광고중지";
                        cont_ad_notSearch = false;
                    }
                    if(item.cpc_notSearch_usl*0.6 <= item.cpc_notSearch && item.cpc_notSearch_usl > item.cpc_notSearch){
                        analysis_result_adNotSearch = "광고최소화";
                        cont_ad_notSearch = true;
                    }
                    if(item.cpc_notSearch_usl*0.4 <= item.cpc_notSearch && item.cpc_notSearch_usl*0.6 > item.cpc_notSearch){
                        analysis_result_adNotSearch = "광고유지";
                        cont_ad_notSearch = true;
                    }
                    if(100 <= item.cpc_notSearch && item.cpc_notSearch_usl*0.4 > item.cpc_notSearch){
                        analysis_result_adNotSearch = "광고최적화";
                        cont_ad_notSearch = true;
                    }
                    if(100 > item.cpc_notSearch){
                        analysis_result_adNotSearch = "광고중지";
                        cont_ad_notSearch = false;
                    }
                }
            } else {
                if((item.orders_notSearch + 1)/item.clicks_notSearch < item.min_cvr_notSearch){
                    analysis_result_adNotSearch = "광고중지";
                    cont_ad_notSearch = false;
                }
            }

            

            item.totalSum = item.totalSum_search + item.totalSum_notSearch;
            item.ad_cost_sum = item.ad_cost_sum_search + item.ad_cost_sum_notSearch;
            item.ad_profit_sum = item.ad_profit_sum_search + item.ad_profit_sum_notSearch;

            if(((cont_ad_search && !cont_ad_notSearch) || (!cont_ad_search && cont_ad_notSearch)) && item.ad_profit_sum < 0 ){
                cont_ad_search = false;
                cont_ad_notSearch = false;
                analysis_result_adSearch = "광고중지";
                analysis_result_adNotSearch = "광고중지";
            }

            if(cont_marketAbility == false && cont_ad_search == false && cont_ad_notSearch == false){
                cont = false;
                item.total_result = true;
            }
            if(cont_marketAbility || cont_ad_search || cont_ad_notSearch){
                cont = true;
            }

            if(item.cpc_search_usl){
                item.cpc_search_opt = (Math.floor(item.cpc_search_usl/2 / 10) * 10 < 100) ? 100 : (Math.floor(item.cpc_search_usl/2 / 10) * 10);
            }
            if(item.cpc_notSearch_usl){
                item.cpc_notSearch_opt = (Math.floor(item.cpc_notSearch_usl/2 / 10) * 10 < 100) ? 100 : (Math.floor(item.cpc_notSearch_usl/2 / 10) * 10);
            }

            
            

            item.continue = cont;
            item.adRoi_search = adRoi_search;
            item.adRoi_notSearch = adRoi_notSearch;
            item.analysis_result_marketability = analysis_result_marketability;
            item.analysis_result_adSearch = analysis_result_adSearch;
            item.analysis_result_adNotSearch = analysis_result_adNotSearch;


        },
        makeAdSourcing(list) {
            this.ads = [];
            this.adsInit = [];
            for(var e of list){
                this.getAdResult(e)
                this.ads.push(e);
                this.adsInit.push(Object.assign({}, e));
            }

            this.$store.commit('getAdSourcingListUpdate', this.ads);
            this.saveAdSourcingList(this.ads)

            this.total = this.adsInit.length;
            this.검색검증완료 = this.adsInit.filter(e => e.search_result).length;
            this.비검색검증완료 = this.adsInit.filter(e => e.notSearch_result).length;
            this.전체검증완료 = this.adsInit.filter(e => e.total_result).length;
            this.판매가능 = this.adsInit.filter(e => e.continue == true).length;
            this.판매불가 = this.adsInit.filter(e => e.continue == false).length;
            this.전체수익 = this.adsInit.reduce((pv,cv)=>{return pv + (cv.ad_cost_sum_search || 0) + (cv.ad_profit_sum_search || 0) + (cv.ad_cost_sum_notSearch || 0) + (cv.ad_profit_sum_notSearch || 0)},0)
            this.광고비 = this.adsInit.reduce((pv,cv)=>{return pv + (cv.ad_cost_sum_search || 0) + (cv.ad_cost_sum_notSearch || 0)},0)
            this.광고순익 = this.adsInit.reduce((pv,cv)=>{return pv + (cv.ad_profit_sum_search || 0) + (cv.ad_profit_sum_notSearch || 0)},0)

            this.$nextTick(() => {
                this.showspinner1 = false;
                this.showlist = true;
            })
        },
        // makeAdSourcing(data) {
        //     console.log(data)
        //     // Helper function to replace NaN with 0
        //     const replaceNaN = (value) => isNaN(value) ? 0 : value;

        //     var list = data.result[0].data.report.rows;
        //     // console.log(list.map(e => e.placement_group + " / " + e.vendor_item_name))
        //     this.ads = [];
        //     this.adsInit = [];
        //     for(var e of list){
        //         if(this.ads.length == 0 || !this.ads.find(f => f.vendor_item_id == e.vendor_item_id)){
        //             var s = list.find(f => f.vendor_item_id == e.vendor_item_id && f.placement_group == '검색 영역') || {};
        //             var ns = list.find(f => f.vendor_item_id == e.vendor_item_id && f.placement_group == '비검색 영역') || {};
        //             var margin = this.$store.state.growthdb?.find(f => f.vendorItemId == e.vendor_item_id)?.마진 || 5000;

        //             var analysis_result = []
        //             var search_result = (s?.clicks_count && s?.total_unit_14_days) ? s.clicks_count >= 11 && s.total_unit_14_days >= 3 : false
        //             if(search_result){
        //                 if(replaceNaN((s?.ctr && s?.total_unit_14_days && s?.clicks_count) ? s.ctr * s.total_unit_14_days / s.clicks_count : 0) > 0.0005){
        //                     analysis_result.push("키워드 상위노출")
        //                 }
        //                 var adRoi_search = replaceNaN((s?.total_unit_14_days && s?.clicks_count && s?.ad_cost_sum) ? margin * s.total_unit_14_days / s.clicks_count / (s.ad_cost_sum / s.clicks_count) : 0);
        //                 if(adRoi_search > 1.2){
        //                     analysis_result.push("검색광고 CPC최적화")
        //                 }
        //                 if(adRoi_search <= 1.2 && adRoi_search > 1.0){
        //                     analysis_result.push("검색광고 현상유지")
        //                 }
        //                 if(adRoi_search <= 1.0){
        //                     analysis_result.push("검색광고 중지")
        //                 }
        //             }
        //             var notSearch_result = (ns?.clicks_count && ns?.total_unit_14_days) ? ns.clicks_count >= 11 && ns.total_unit_14_days >= 3 : false;
        //             if(notSearch_result){
        //                 var adRoi_notSearch = replaceNaN((ns?.total_unit_14_days && ns?.clicks_count && ns?.ad_cost_sum) ? margin * ns.total_unit_14_days / ns.clicks_count / (ns.ad_cost_sum / ns.clicks_count) : 0);
        //                 if(adRoi_notSearch > 1.2){
        //                     analysis_result.push("비검색광고 CPC최적화")
        //                 }
        //                 if(adRoi_notSearch <= 1.2 && adRoi_notSearch > 1.0){
        //                     analysis_result.push("비검색광고 현상유지")
        //                 }
        //                 if(adRoi_notSearch <= 1.0){
        //                     analysis_result.push("비검색광고 중지")
        //                 }
        //             }
                    
        //             var analysisResult = analysis_result.join(" / ")

        //             const adObject = {
        //                 vendor_item_id: e.vendor_item_id,
        //                 campaign: e.campaign_name,
        //                 adGroup: e.ad_group_name,
        //                 adProduct: e.vendor_item_name,

        //                 impressions_search: replaceNaN(s?.impressions_count ?? 0),
        //                 clicks_search: replaceNaN(s?.clicks_count ?? 0),
        //                 orders_search: replaceNaN(s?.total_unit_14_days ?? 0),
        //                 cpc_search: replaceNaN(s?.clicks_count == 0 ? 0 : (s?.ad_cost_sum / s?.clicks_count ?? 0)),
        //                 ctr_search: replaceNaN(s?.ctr ?? 0),
        //                 cvr_search: replaceNaN((s?.total_unit_14_days && s?.clicks_count) ? s.total_unit_14_days / s.clicks_count : 0),
        //                 ctr_search_result: s?.clicks_count ? s.clicks_count >= 11 : false,
        //                 cvr_search_result: s?.total_unit_14_days ? s.total_unit_14_days >= 3 : false,
        //                 search_result: (s?.clicks_count && s?.total_unit_14_days) ? s.clicks_count >= 11 && s.total_unit_14_days >= 3 : false,

        //                 impressions_notSearch: replaceNaN(ns?.impressions_count ?? 0),
        //                 clicks_notSearch: replaceNaN(ns?.clicks_count ?? 0),
        //                 sales_notSearch: replaceNaN(ns?.total_unit_14_days ?? 0),
        //                 cpc_notSearch: replaceNaN(ns?.clicks_count == 0 ? 0 : (ns?.ad_cost_sum / ns?.clicks_count ?? 0)),
        //                 ctr_notSearch: replaceNaN(ns?.ctr ?? 0),
        //                 cvr_notSearch: replaceNaN((ns?.total_unit_14_days && ns?.clicks_count) ? ns.total_unit_14_days / ns.clicks_count : 0),
        //                 ctr_notSearch_result: ns?.clicks_count ? ns.clicks_count >= 11 : false,
        //                 cvr_notSearch_result: ns?.total_unit_14_days ? ns.total_unit_14_days >= 3 : false,
        //                 notSearch_result: (ns?.clicks_count && ns?.total_unit_14_days) ? ns.clicks_count >= 11 && ns.total_unit_14_days >= 3 : false,

        //                 total_result: (s?.clicks_count >= 11 && s?.total_unit_14_days >= 3 && ns?.clicks_count >= 11 && ns?.total_unit_14_days >= 3) ?? false,
        //                 margin: margin,
        //                 adRoi_search: replaceNaN((s?.total_unit_14_days && s?.clicks_count && s?.ad_cost_sum) ? margin * s.total_unit_14_days / s.clicks_count / (s.ad_cost_sum / s.clicks_count) : 0),
        //                 adRoi_notSearch: replaceNaN((ns?.total_unit_14_days && ns?.clicks_count && ns?.ad_cost_sum) ? margin * ns.total_unit_14_days / ns.clicks_count / (ns.ad_cost_sum / ns.clicks_count) : 0),
        //                 marketAbility: replaceNaN((s?.ctr && s?.total_unit_14_days && s?.clicks_count) ? s.ctr * s.total_unit_14_days / s.clicks_count : 0),
        //                 cpc_usl: replaceNaN((s?.total_unit_14_days && s?.clicks_count) ? margin * s.total_unit_14_days / s.clicks_count : 0),
        //                 analysis_result: analysisResult,
        //             };

        //             this.ads.push(adObject);
        //             this.adsInit.push(Object.assign({}, adObject));
                    
        //         }
        //     }

        //     this.$store.commit('getAdSourcingListUpdate', this.ads);
        //     this.saveAdSourcingList(this.ads)

        //     this.total = this.adsInit.length;
        //     this.검색검증완료 = this.adsInit.filter(e => e.search_result).length;
        //     this.비검색검증완료 = this.adsInit.filter(e => e.notSearch_result).length;
        //     this.전체검증완료 = this.adsInit.filter(e => e.total_result).length;

        //     this.$nextTick(() => {
        //         this.showspinner1 = false;
        //         this.showlist = true;
        //     })
        // },
        async saveAdSourcingList(ads){
            try{
                var res = await axios.post('/api/ad/resetAdSourcingList',ads)
                console.log(res)
                return 
            }catch(e){
                console.log('err712' + e);
                return false
            }
        },
        makeAdList(list){
            if(!list){
                alert('분석할 광고목록이 없습니다.')
                return
            }
            this.adList = [];
            for(var e of list){
                if(e.targetType == 'cpc'){
                   var adType = '수동';
                } else {
                    if(e.creationContext){
                        adType = 'AI'
                    } else {
                        adType = '매출최적화'
                    }
                }
                
                this.adList.push({
                    adInfo : e,
                    campaign : e.name,
                    status : e.isActive,
                    budget : e.budget,
                    startDate : e.startDate.slice(0,10),
                    adType : adType,
                    id : e.id,
                })
            }
            this.$nextTick(() => {
                this.$bvModal.show('adList');
            })
        },
        applyAdList(){
            console.log(this.adListSelectedItem)
            var ids = this.adListSelectedItem.map(e => e.id)
            window.postMessage({ greeting: "collectDetailAdList", supplierCode: this.$store.state.user.suppliercode, targetIds:ids, startDate:this.startDate,endDate:this.endDate}, "*",)
            this.$bvModal.hide('adList');
            this.showlist = false;
            this.showspinner1 = true;
            this.loadingtext = "광고데이터 수집 중..."
        },
        collectAdList() {
            this.isLoadingAdList = true;
            window.postMessage({ greeting: "collectAdList", supplierCode: this.$store.state.user.suppliercode}, "*",)
            // this.showspinner1 = true;
            // this.showlist = false;
        },
        formatDate(date) {
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        getDates() {
            const today = new Date();
            
            // 어제 날짜 구하기
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            
            // 한 달 전 날짜 구하기
            const oneMonthAgo = new Date(today);
            oneMonthAgo.setMonth(today.getMonth() - 1);

            // yyyy-mm-dd 형태로 반환
            return {
                yesterday: this.formatDate(yesterday),
                oneMonthAgo: this.formatDate(oneMonthAgo)
            };
        },


        editQtyEnter($event){
            if($event.keyCode == 13){
                this.editQty = false;
            }
        },
        cancelPurchaseInfo($event,item){
            $event.preventDefault()
            item.구매정보여부 = this.productListsinit.find(e => e._id == item._id).구매정보여부;
            item.구매정보 = this.productListsinit.find(e => e._id == item._id).구매정보;
            this.구매정보없음 = this.productLists.filter(e => !e.구매정보여부).length;
            this.구매정보있음 = this.productLists.filter(e => e.구매정보여부).length;
            this.$nextTick(() => {
                this.$bvModal.hide('modalpurchase' + item._id);
            })
        },
        async savePurchaseInfo($event,item){
            $event.preventDefault()
            this.showlist = false;
            var 현재구매정보여부 = this.productListsinit.find(e => e._id == item._id).구매정보여부;
            var 현재구매정보 = this.productListsinit.find(e => e._id == item._id).구매정보;

            if(!item.구매정보 || item.구매정보.length == 0 || item.구매정보.find(e => e.매칭상태 !== '매칭완료' || e.요청사항 !== '등록완료')){
                var 구매정보여부 = false
            } else {
                구매정보여부 = true;
            }

            this.productListsinit.find(e => e._id == item._id).구매정보여부 = 구매정보여부;
            this.productListsinit.find(e => e._id == item._id).구매정보 = item.구매정보;
            item.구매정보여부 = 구매정보여부;

            console.log(item.구매정보)
            console.log(item)
            console.log(this.productListsinit.filter(e => e.노출ID == item.노출ID && e.구매정보.find(f => f.요청사항 !== "등록완료")))
            console.log(this.productListsinit.filter(e => e.구매정보.find(f => f.요청사항 !== "등록완료")))

            if(구매정보여부 && this.productListsinit.filter(e => e.노출ID == item.노출ID && e.구매정보.find(f => f.요청사항 !== "등록완료")).length > 0){
                if(confirm('현재 구매정보를 동일한 노출ID에 모두 적용하시겠습니까? 적용대상 : ' + this.productListsinit.filter(e => e.노출ID == item.노출ID).length + '개')){
                    var cnt = 0;
                    for(var e of this.productListsinit.filter(e => e.노출ID == item.노출ID)){
                        if(e.바코드 !== item.바코드){
                            console.log(e)
                            e.구매정보여부 = false;
                            e.구매정보 = [];
                            for(var i=0;i<item.구매정보.length;i++){
                                e.구매정보[i] = this.deepClone(item.구매정보[i])
                            }
                            for(i=0;i<e.구매정보.length;i++){
                                var f = e.구매정보[i];
                                console.log(e.구매정보)
                                f.바코드 = e.바코드;
                                f.상품명 = e.상품명;
                                f.상품명init = e.상품명;
                                f.등록이미지 = e.옵션이미지;
                                f.한글표시사항.제품명 = e.상품명;
                                f.옵션이미지 = undefined;
                                
                                f.옵션1_중국어 = f.상품매칭.옵션1_중국어.length > 0 ? undefined : "";
                                f.옵션2_중국어 = f.상품매칭.옵션2_중국어.length > 0 ? undefined : "";

                                if(f.옵션1_중국어 == undefined || f.옵션2_중국어 == undefined){
                                    f.매칭상태 = "매칭필요";
                                } else {
                                    f.매칭상태 = "매칭완료";
                                }
                            }
                        }
                        var res = await this.saveImportInfo(e,false);
                        if(res){
                            cnt++
                        }
                        this.구매정보없음 = this.productLists.filter(e => !e.구매정보여부).length;
                        this.구매정보있음 = this.productLists.filter(e => e.구매정보여부).length;
                    }
                    alert(cnt + '개 상품 적용완료')
                    this.$store.commit('growthdbupdate', this.productListsinit);
                }
            } else {
                res = await this.saveImportInfo(item,true);
                if(!res){
                    this.productListsinit.find(e => e._id == item._id).구매정보여부 = 현재구매정보여부;
                    this.productListsinit.find(e => e._id == item._id).구매정보 = 현재구매정보;
                    item.구매정보여부 = 현재구매정보여부;
                    item.구매정보 = 현재구매정보;
                } else {
                    this.$store.commit('growthdbupdate', this.productListsinit);
                }
                this.구매정보없음 = this.productLists.filter(e => !e.구매정보여부).length;
                this.구매정보있음 = this.productLists.filter(e => e.구매정보여부).length;
            }
            this.searchResult(this.$store.state.growthdb);
            
            this.$nextTick(() => {
                this.$bvModal.hide('modalpurchase' + item._id);
                this.showlist = true;
            })
            
        },
        classPurchaseInfo(item){
            if(item.구매정보여부){
                return 'success'
            } else {
                return 'secondary'
            }
        },
        async copyReq(lists){
            console.log(lists[0])
            var newObj = this.deepClone(lists[0]);
            if(newObj.상품매칭.옵션1_중국어.length > 0){
                newObj.옵션1_중국어 = undefined;
            } else {
                newObj.옵션1_중국어 = '';
            }
            if(newObj.상품매칭.옵션2_중국어.length > 0){
                newObj.옵션2_중국어 = undefined;
            } else {
                newObj.옵션2_중국어 = '';
            }
            newObj.상품명init = newObj.상품명
            newObj.옵션이미지 = undefined;
            newObj.매칭상태 = '매칭필요';
            if(!newObj.요청내역.destination){
                newObj.요청내역.destination = 'coupang'
            }
            lists.forEach(e => {
                e.묶음구성여부 = true;
                e.묶음구성수량 = lists.length+1;
            })
            lists.push(newObj);
        },
        addProductName(list){
            list.상품명 = list.상품명init;
        },
        changeBarcode(item){
            if(!item.요청내역.barcode){
                alert('바코드라벨 미부착 시 반드시 원산지가 표시되어 있어야 합니다.')
                item.요청내역.sticker = true;
            } else {
                item.요청내역.sticker = false;
            }
        },
        confirmBarcode(list){
            if(list.바코드.includes('no') || list.바코드.includes('NO') || list.바코드.includes('No') || list.바코드.includes('노')){
                if(list.묶음구성여부){
                    alert('바코드 정보가 없는 경우 묶음구성 진행이 어렵습니다. 바코드를 먼저 생성해 주세요')
                    return
                }
            }
        },
        async importHideProducts(){
            this.showHideProducts = false;
            this.showspinner2 = true;
            if(this.searchProducts.length == 0){
                try{
                    var res = await axios.post('/api/getGrowthDBV2',{show:false});
                    this.searchProducts = res.data.growthdb;
                    console.log(this.searchProducts)
                    // this.$store.commit('growthdbupdate', result.data.growthdb);
                    // this.searchResult(this.$store.state.growthdb);
                    this.showHideProducts = true;
                    this.showspinner2 = false;
                }catch(e){
                    alert('불러오기 실패')
                    console.log(e)
                }                
            } else {
                // alert('수집된 상품DB가 없습니다. 로켓그로스 상품DB 수집을 진행해 주세요.(쿠팡wing 로그인 필수)')
                // this.searchResult(this.$store.state.growthdb);
                this.showHideProducts = true;
                this.showspinner2 = false;
            }
        },
        async saveImport($event,item){
            $event.preventDefault()
            // this.showlist = false;
            var currentImportInfo = this.productListsinit.find(e => e._id == item._id).매입정보;
            var 현재매입가 = this.productListsinit.find(e => e._id == item._id).매입가;
            var 현재마진 = this.productListsinit.find(e => e._id == item._id).마진;
            var 현재마진율 = this.productListsinit.find(e => e._id == item._id).마진율;
            var 현재ROI = this.productListsinit.find(e => e._id == item._id).ROI;
            var 현재ROAS = this.productListsinit.find(e => e._id == item._id).ROAS;

            this.changeCost(item);
            this.changeMargin(item);
            this.productListsinit.find(e => e._id == item._id).매입정보 = item.매입정보;
            this.productListsinit.find(e => e._id == item._id).매입가 = item.매입가;
            this.productListsinit.find(e => e._id == item._id).마진 = item.마진;
            this.productListsinit.find(e => e._id == item._id).마진율 = item.마진율;
            this.productListsinit.find(e => e._id == item._id).ROI = item.ROI;
            this.productListsinit.find(e => e._id == item._id).ROAS = item.ROAS;

            var res = await this.saveImportInfo(item,true);
            if(!res){
                this.productListsinit.find(e => e._id == item._id).매입정보 = currentImportInfo;
                this.productListsinit.find(e => e._id == item._id).매입가 = 현재매입가;
                this.productListsinit.find(e => e._id == item._id).마진 = 현재마진;
                this.productListsinit.find(e => e._id == item._id).마진율 = 현재마진율;
                this.productListsinit.find(e => e._id == item._id).ROI = 현재ROI;
                this.productListsinit.find(e => e._id == item._id).ROAS = 현재ROAS;

                item.매입정보 = currentImportInfo;
                item.매입가 = 현재매입가;
                item.마진 = 현재마진;
                item.마진율 = 현재마진율;
                item.ROI = 현재ROI;
                item.ROAS = 현재ROAS;
            } else {
                this.$store.commit('growthdbupdate', this.productListsinit);
            }
            this.매입정보없음 = this.productLists.filter(e => !e.매입가).length;
            this.$nextTick(()=>{
                this.$bvModal.hide('modalImport' + item._id);
            })
            
            // setTimeout(()=>{
            //     this.showlist = true;
            // },10)
        },
        async saveImportInfo(data,bool){
            try{
                var res = await axios.post('/api/growthdb/saveDBInfo',data);
                if(res.data.res !== 'success'){
                    alert('저장실패(901) : ' + res.data.alert)
                    return false
                } else {
                    if(bool){
                        alert('저장완료')
                    }
                    return true
                }
            }catch(e){
                alert('저장실패(905) : ' + e)
                return false
            }
        },
        cancelImport(item){
            item.매입정보 = this.productListsinit.find(e => e._id == item._id).매입정보;
            this.changeCost(item);
            this.changeMargin(item);
            this.매입정보없음 = this.productLists.filter(e => !e.매입가).length;
            this.$nextTick(()=>{
                this.$bvModal.hide('modalImport' + item._id);
            })
        },
        changeCost(item){
            if(item.매입정보 && item.매입정보.length > 0){
                item.매입가 = parseInt(item.매입정보[item.매입정보.length-1].매입가);
            } else {
                item.매입가 = "";
            }
        },
        addImportInfo(productList){
            this.showImport = false;
            if(productList.매입정보){
                productList.매입정보.push({
                    date : this.getTodaywith(),
                    매입가 : 0,
                })
            } else {
                productList.매입정보 = [{
                    date : this.getTodaywith(),
                    매입가 : 0,
                }]
            }
            setTimeout(()=>{
                this.showImport = true;
                this.editmode = true;
            },1)
        },
        changeImportInfo(event){
            if(event.keyCode == 13){
				this.editmode = false;
			}
        },
        editImport(){
            if(this.editmode == false){
                this.editmode = true;
            } else {
                this.editmode = false;
            }
        },
        deleteImport(productList,index){
            productList.매입정보.splice(index, 1);
        },
        async calMargin(productList){
            this.showlist = false;
            if(productList.매입가 && productList.매입가 > 0){
                var 부가세 = productList.판매가/11 - productList.수수료/11 - productList.매입가/11
                productList.마진 = parseInt(productList.판매가 - productList.매입가 - productList.수수료 - 부가세);
                productList.마진율 = Math.round((productList.판매가 - productList.매입가 - productList.수수료 - 부가세)/productList.판매가*100*10)/10
                productList.ROI = Math.round((productList.판매가 - productList.매입가 - productList.수수료 - 부가세)/productList.매입가*100*10)/10
            } else {
                productList.마진 = '';
                productList.마진율 = '';
                productList.ROI = '';
            }
            setTimeout(()=>{
                this.showlist = true;
            },1)
        },
        async delManageItems(){
            if(this.tableSelectedItem.length == 0){
                alert('관리삭제할 항목을 선택해 주세요')
                return
            }
            if(confirm(this.tableSelectedItem.length + '개의 항목을 관리삭제(숨김처리)하시겠습니까?')){
                try{
                    var input = this.tableSelectedItem.map(e => e._id);
                    var res = await axios.post('/api/growthdb/hideProduct',input);
                    if(res.data.res == 'success'){
                        this.productLists = this.productLists.filter(e => !input.includes(e._id));
                        this.productListsinit = this.productListsinit.filter(e => !input.includes(e._id));
                        console.log(this.searchProducts)
                        this.searchProducts = this.searchProducts.concat(this.tableSelectedItem);
                        this.$store.commit('growthdbupdate', this.productListsinit);
                    } else {
                        alert(res.data.alert)
                    }
                }catch(e){
                    alert('err823' + e)
                }
            }
        },
        changePackage(list){
            if(this.packageMethod == 'opp'){
                list.요청내역.opp = true;
                list.요청내역.LDPE = false;
                list.요청내역.auto = false;
                return
            }
            if(this.packageMethod == 'LDPE'){
                list.요청내역.opp = true;
                list.요청내역.LDPE = false;
                list.요청내역.auto = false;
                return
            }
            if(this.packageMethod == 'noPackage'){
                list.요청내역.opp = false;
                list.요청내역.LDPE = false;
                list.요청내역.auto = false;
                return
            }
            if(this.packageMethod == 'auto'){
                list.요청내역.opp = false;
                list.요청내역.LDPE = false;
                list.요청내역.auto = true;
                return
            }
        },
        addSearchProduct(list,index){
            console.log(list)
            this.searchProducts.splice(index, 1);
            var f = list;
            if(!f.구매정보 || f.구매정보여부.length == 0){
                f.구매정보 = [{
                    checked : false,
                    showspinner : false,
                    // SKUID : '',
                    바코드 : f.barcode,
                    상품명 : f.productName,
                    상품명init : f.productName,
                    구매링크 : undefined,
                    구매링크init : undefined,
                    옵션1_중국어 : undefined,
                    옵션2_중국어 : undefined,
                    옵션이미지: undefined,
                    매칭상태 : '매칭필요',
                    상품매칭 : {
                        옵션1_중국어 : [],
                        옵션2_중국어 : [],
                    },
                    구매재고 : '',
                    구매요청수량 : '',
                    판매구성수량 : 1,
                    묶음구성여부 : false,
                    묶음구성수량 : '',
                    묶음대상바코드 : '',
                    신고영문명 : '',
                    신고가격 : '',
                    HSCODE : '',
                    진행현황 : '구매전',
                    재고동봉 : true,
                    등록이미지 : f.optionImageUrl,
                    요청사항 : '요청등록',
                    reqtype : '제트배송',
                    sizeX : f.sizeInfo ? f.sizeInfo.width : 0,
                    sizeY : f.sizeInfo ? f.sizeInfo.length : 0,
                    sizeZ : f.sizeInfo ? f.sizeInfo.heigth : 0,
                    weight : f.sizeInfo ? f.sizeInfo.weight : 0,
                    요청내역 : {
                        default : true,
                        poarrange : false,
                        한글표시사항 : true,
                        barcode : true,
                        sticker : false,
                        stamp : false,
                        tag : false,
                        reqcontent : '',
                        opp : false,
                        LDPE : false,
                        airpacking : false,
                        exportType : 'box',

                        masking : false,
                        destination : 'coupang',
                        auto : true,
                    },
                    한글표시사항 : {
                        제품명 : f.productName,
                        '수입원/판매원' : '',
                        제조원 : '',
                        제조국 : '',
                        내용량 : '',
                        '원료명및성분명(재질)' : '',
                        상품유형 : '',
                        사용시주의사항 : '',
                        사용기준 : '',
                        custom : '',
                    },
                    polists : [],
                    selectpo : '', 
                }];
            }
            var obj = {
                _id : f._id,
                상품명 : f.상품명 ? f.상품명 : f.productName,
                바코드 : f.바코드 ? f.바코드 : f.barcode,
                노출ID : f.노출ID ? f.노출ID : f.productId,
                옵션ID : f.옵션ID ? f.옵션ID : f.vendorItemId,
                승인상태 : f.승인상태 ? f.승인상태 : f.statusName,
                옵션이미지 : f.옵션이미지 ? f.옵션이미지 : f.optionImageUrl,

                구매정보 : f.구매정보 ? f.구매정보 : f.purchaseInfo,
                구매정보여부 : f.구매정보여부 ? f.구매정보여부 : f.purchaseInfoReg,
                판매링크 : f.판매링크 ? f.판매링크 : 'https://www.coupang.com/vp/products/' + f.productId + '?vendorItemId=' + f.vendorItemId,
                아이템위너 : f.아이템위너 ? f.아이템위너 : f.winner,
                뱃지 : f.뱃지 ? f.뱃지 : f.badge,

                사이즈 : f.사이즈 ? f.사이즈 : f.size,
                사이즈정보 : f.사이즈정보 ? f.사이즈정보 : f.sizeInfo,
                판매가 : f.판매가 ? f.판매가 : f.salePrice,

                풀필먼트수수료 : f.풀필먼트수수료 ? f.풀필먼트수수료 : f.fullfillmentFee,
                입출고수수료 : f.입출고수수료 ? f.입출고수수료 : f.warehouseFee,
                판매수수료 : f.판매수수료 ? f.판매수수료 : f.commision,
                판매수수료율 : f.판매수수료율 ? f.판매수수료율 : f.commisionRate,
                수수료 : f.수수료 ? f.수수료 : ((parseInt(f.fullfillmentFee) + parseInt(f.warehouseFee) + f.commision)*1.1).toFixed(0),

                매입정보 : f.매입정보 ? f.매입정보 : f.importInfo,
                매입가 : f.매입가 ? f.매입가 : f.매입가,
                마진 : f.마진 ? f.마진 : f.마진,
                마진율 : f.마진율 ? f.마진율 : f.마진율,
                ROI : f.ROI ? f.ROI : f.ROI,
                ROAS : f.ROAS ? f.ROAS : f.ROAS
            }

            this.addManage(obj);
            //hideProduct show를 true로 변경하는 axios 추가


            
            this.total = this.productLists.length;
            this.뱃지없음 = this.productLists.filter(e => e.뱃지 == 'N').length;
            this.아이템루저 = this.productLists.filter(e => e.아이템위너 == 'N').length;
            this.구매정보없음 = this.productLists.filter(e => !e.구매정보여부).length;
            this.구매정보있음 = this.productLists.filter(e => e.구매정보여부).length;
            this.showlist = true;
            this.showspinner = false;
            this.showspinner1 = false;
            // this.productList = list;
        },
        delSearchProduct(list){
            delete list.add;
        },
        async addManage(item){
            var vendorItemId = item.옵션ID;
            try{
                var res = await axios.post('/api/growthdb/showProduct',[vendorItemId])
                if(res.data.res == 'success'){
                    this.productLists.push(item)
                    this.productListsinit.push(this.deepClone(item))
                    this.$store.commit('growthdbupdate', this.productListsinit);
                } else {
                    alert(res.data.alert)
                }
            }catch(e){
                console.log('err844' + e);
            }
        },
        delAddProduct(index){
            this.addProducts.splice(index, 1);
        },
        confirmlist(list){
            if(!list.바코드){
                if(!confirm('바코드가 없는 경우 로켓그로스 출고가 불가능합니다.')){
                    return false
                }
            }
            if(!list.신고영문명){
                alert("요청내역 중 신고영문명을 작성해주세요. ex)여성신발 -> women's shoes ")
                return false
            }
            // if(!list.신고가격){
            //     alert("요청내역 중 신고가격을 USD로 환산하여 작성해주세요. ex) 2달러 -> 2 ")
            //     return false
            // }            
            if(!list.HSCODE){
                alert("요청내역 중 HS-CODE를 작성해주세요. ex) 6303.92-0000 ")
                return false
            }
            if(list.HSCODE.length !== 12){
                alert("HS-CODE를 Full name으로 작성해주세요. ex) 6303.92-0000 ")
                return false
            }
            if(list.구매요청수량 == 0 || !list.구매요청수량){
                alert("구매요청수량을 작성해 주세요.")
                return false
            }
            if(list.판매구성수량 == 0 || !list.판매구성수량){
                alert("판매구성수량을 작성해 주세요.")
                return false
            }  
            list.한글표시사항.제품명 = list.상품명;
            if(Object.keys(list.한글표시사항).find(e => list.한글표시사항[e].length == 0 && e !== '제품명' && e !== 'custom')){
                console.log(list.한글표시사항)
                alert('한글표시사항을 모두 작성해 주세요')
                return false
            }
            return true      
        },
        async savereq($event,list,index){
            $event.preventDefault();
            var foo = this.confirmlist(list)
            if(!foo){
                return
            }
            list.요청사항 = '등록완료';
            console.log(list)
            
            this.$nextTick(() => {
                this.$bvModal.hide('modalreq' + index);
                console.log('modalreq' + index)
            })
            
            
        },
        // regrequest(list,index){
        //     if(!list.상품명){
        //         alert('상품명을 먼저 입력해 주세요')
        //         return
        //     }
        //     this.$bvModal.show('modalreq' + index);
        // },
        async addlists(item){
            var obj = {
                checked : false,
                showspinner : false,
                // SKUID : '',
                바코드 : item.바코드,
                상품명 : item.상품명,
                상품명init : item.상품명,
                구매링크 : undefined,
                구매링크init : undefined,
                옵션1_중국어 : undefined,
                옵션2_중국어 : undefined,
                옵션이미지: undefined,
                매칭상태 : '매칭필요',
                상품매칭 : {
                    옵션1_중국어 : [],
                    옵션2_중국어 : [],
                },
                구매재고 : '',
                구매요청수량 : '',
                판매구성수량 : 1,
                묶음구성여부 : false,
                묶음구성수량 : '',
                묶음대상바코드 : '',
                신고영문명 : '',
                신고가격 : '',
                HSCODE : '',
                진행현황 : '구매전',
                재고동봉 : true,
                등록이미지 : item.옵션이미지,
                요청사항 : '요청등록',
                reqtype : '제트배송',
                sizeX : item.sizeInfo ? item.sizeInfo.width : "",
                sizeY : item.sizeInfo ? item.sizeInfo.length : "",
                sizeZ : item.sizeInfo ? item.sizeInfo.heigth : "",
                weight : item.sizeInfo ? item.sizeInfo.weight : "",
                요청내역 : {
                    default : true,
                    poarrange : false,

                    한글표시사항 : true,
                    barcode : true,
                    sticker : false,
                    sewing : false,
                    stamp : false,
                    tag : false,
                    reqcontent : '',
                    opp : false,
                    LDPE : false,
                    airpacking : false,
                    exportType : 'box',
                    destination : 'coupang',
                    masking : false,
                    auto : true,
                },
                한글표시사항 : {
                    제품명 : item.상품명,
                    '수입원/판매원' : '',
                    제조원 : '',
                    제조국 : '',
                    내용량 : '',
                    '원료명및성분명(재질)' : '',
                    상품유형 : '',
                    사용시주의사항 : '',
                    사용기준 : '',
                },
                polists : [],
                selectpo : '',               
            }
            item.구매정보.push(obj)
        },
        toggleCollapse(item) {
            if(this.isCollapseOpen){
                this.purchaseItem.purchaseInfoLists = [];
            } else {
                this.purchaseItem = this.deepClone(item);
            }
            this.isCollapseOpen = !this.isCollapseOpen;
        },
        deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
        
        barcodegap(index, total) {
            if (index < total) {
                return 'border:0px solid silver;width:300px;text-align: center;margin-bottom:' + this.바코드간격 + 'px'
            } else {
                return 'border:0px solid silver;width:300px;text-align: center;'
            }
        },
        printbarcoderef() {
            if (this.바코드출력 != '') {
                this.printbarcode(this.바코드출력)
                // this.바코드출력 = ''
            } else {
                if (this.SKU출력 != '') {
                    var barcode = this.$store.state.productdb.find(e => e["SKU ID"] == this.SKU출력).바코드
                    this.printbarcode(barcode);
                    // this.SKU출력 = ''
                }
            }
        },
        printbarcodewith(event) {
            if (event.keyCode === 13) {
                this.printbarcode(event.target.value)
                // this.바코드출력 = ''
            }
        },
        printSKUwith(event) {
            if (event.keyCode === 13) {
                var barcode = this.$store.state.productdb.find(e => e["SKU ID"] == event.target.value).바코드
                this.printbarcode(barcode);
                // this.SKU출력 = ''
            }
        },
        printbarcode(barcode) {
            if (this.$store.state.productdb.length == 0 || !this.$store.state.productdb.find(e => e.바코드 == barcode)) {
                // axios.post('/api/logisaddimport/importbarcodeinfo',{barcode:barcode})
                // .then((res) => {
                // 	this.출력SKUID = res.data.res[0]["SKU ID"]
                // 	this.출력바코드 = barcode;
                // 	this.출력상품명 = res.data.res[0]['상품명'];
                // 	this.한글표시사항 = {
                // 		'수입원/판매원':res.data.res[0]['수입원/판매원'],
                // 		제조원:res.data.res[0].제조원,
                // 		제조국:res.data.res[0].제조국,
                // 		내용량:res.data.res[0].내용량,
                // 		'원료명및성분명(재질)':res.data.res[0]['원료명및성분명(재질)'],
                // 		상품유형:res.data.res[0].상품유형,
                // 		사용시주의사항:res.data.res[0].사용시주의사항,
                // 		사용기준:res.data.res[0].사용기준,
                // 	}
                // 	setTimeout(() => {
                // 		this.print('printMe8');
                // 	},10)
                // })
                // .catch((e) => {alert("importbarcode 실패(1465)");console.log(e);})

                alert('상품DB에 존재하지 않은 바코드입니다. 상품DB를 업데이트한 후 다시 시도해 주세요')
                return
            } else {
                var ob = this.$store.state.productdb.find(e => e.바코드 == barcode)
                this.출력SKUID = ob['SKU ID'];
                this.출력바코드 = barcode;
                this.출력상품명 = ob['상품명'];
                this.한글표시사항 = {
                    '수입원/판매원': ob['수입원/판매원'],
                    제조원: ob.제조원,
                    제조국: ob.제조국,
                    내용량: ob.내용량,
                    '원료명및성분명(재질)': ob['원료명및성분명(재질)'],
                    상품유형: ob.상품유형,
                    사용시주의사항: ob.사용시주의사항,
                    사용기준: ob.사용기준,
                }
                setTimeout(() => {
                    this.print('printMe8');
                }, 10)
            }

        },
        async print(id) {
            // Pass the element id here
            var options = {
                name: '_blank',
                autoClose: true,
            }
            await this.$htmlToPaper(id, options);
        },
        excelDownFunc() {
            var dataWS = XLSX.utils.json_to_sheet(this.ads);

            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS, '광고관리');
            // 엑셀 파일을 내보낸다.
            XLSX.writeFile(wb, '광고관리.xlsx');
        },
        edit(ProductList) {
            this.$store.state.productdb.some(e => {
                if (e["SKU ID"] == ProductList["SKU ID"]) {
                    e.등록이미지 = ProductList.등록이미지;
                    e.구매링크 = ProductList.구매링크;
                    e.옵션1_중국어 = ProductList.옵션1_중국어;
                    e.옵션2_중국어 = ProductList.옵션2_중국어;
                    e.판매구성수량 = ProductList.판매구성수량;
                    e.구매가능상태 = ProductList.구매가능상태;
                    axios.post('/api/addproduct/editproductdb', ProductList)
                        .then((res) => { console.log(res.data.요청결과) })
                        .catch(console.log)
                    return (e["SKU ID"] == ProductList["SKU ID"])
                }
            })
        },
        MenuUpdate() {
            console.log(this.selected)
            if (this.selected == "radio1") {
                this.menuint = this.menuDefault;
            } else {
                if (this.selected == "radio2") {
                    this.menuint = this.menuCost;
                }
                //  else {
                //     this.menuint = this.menuSales;
                // }
            }
        },
        modalID(index) {
            return 'modal' + index
        },
        modaledit(index) {
            return 'modaledit' + index
        },
        searchpd(event) {
            if (event.keyCode === 13) {
                this.search();
            }
        },
        async search() {
            this.showlist = false;
            this.showspinner = true;

            if(!this.$store.state.user.auth.showAd){
                return
            }

            if(this.$store.state.growthdb.length == 0){
                try{
                    var result = await axios.post('/api/getGrowthDBV2',{show:true});
                    this.$store.commit('growthdbupdate', result.data.growthdb);
                    if(this.$store.state.growthdb.length == 0){
                        alert('수집된 로켓그로스 상품DB가 없습니다. 로켓그로스 > 상품관리에서 로켓그로스 상품DB 수집 및 매입가를 입력해 주세요.')
                    }
                    // this.searchResult(this.$store.state.growthdb);
                    // this.showlist = true;
                    // this.showspinner = false;
                }catch(e){
                    alert('불러오기 실패')
                    console.log(e)
                }                
            }
            if(this.$store.state.adSourcingList.length == 0){
                try{
                    var res = await axios.post('/api/ad/getAdSourcingList');
                    this.$store.commit('getAdSourcingListUpdate', res.data.adSourcingList);
                    this.searchResult(this.$store.state.adSourcingList);
                    this.showlist = true;
                    this.showspinner = false;
                }catch(e){
                    alert('불러오기 실패')
                    console.log(e)
                }
            } else {
                this.searchResult(this.$store.state.adSourcingList);
                this.showlist = true;
                this.showspinner = false;
            }
        },
        async searchResult(list){
            this.ads = [];
            this.adsInit = [];
            for(var e of list){
                this.getAdResult(e)
                this.ads.push(e);
                this.adsInit.push(Object.assign({}, e));
            }

            this.total = this.adsInit.length;
            this.검색검증완료 = this.adsInit.filter(e => e.search_result).length;
            this.비검색검증완료 = this.adsInit.filter(e => e.notSearch_result).length;
            this.전체검증완료 = this.adsInit.filter(e => e.total_result).length;
            this.판매가능 = this.adsInit.filter(e => e.continue == true).length;
            this.판매불가 = this.adsInit.filter(e => e.continue == false).length;
            this.전체수익 = this.adsInit.reduce((pv,cv)=>{return pv + (cv.ad_cost_sum_search || 0) + (cv.ad_profit_sum_search || 0) + (cv.ad_cost_sum_notSearch || 0) + (cv.ad_profit_sum_notSearch || 0)},0)
            this.광고비 = this.adsInit.reduce((pv,cv)=>{return pv + (cv.ad_cost_sum_search || 0) + (cv.ad_cost_sum_notSearch || 0)},0)
            this.광고순익 = this.adsInit.reduce((pv,cv)=>{return pv + (cv.ad_profit_sum_search || 0) + (cv.ad_profit_sum_notSearch || 0)},0)

            this.$nextTick(() => {
                this.showspinner1 = false;
                this.showlist = true;
            })
        },
        showtotal(){
            this.showlist=false;
            setTimeout(() => {
                this.ads = this.adsInit;
                this.showlist = true;
            }, 1);
        },
        showa(){
            this.showlist=false;
            setTimeout(() => {
                this.ads = this.adsInit.filter(e => e.search_result)
                this.showlist = true;
            }, 1);
        },        
        showb(){
            this.showlist=false;
            setTimeout(() => {
                this.ads = this.adsInit.filter(e => e.notSearch_result)
                this.showlist = true;
            }, 1);
        }, 
        showc(){
            this.showlist=false;
            setTimeout(() => {
                this.ads = this.adsInit.filter(e => e.total_result)
                this.showlist = true;
            }, 1);
        }, 
        showd(){
            this.showlist=false;
            setTimeout(() => {
                this.ads = this.adsInit.filter(e => e.continue == true)
                this.showlist = true;
            }, 1);
        }, 
        showe(){
            this.showlist=false;
            setTimeout(() => {
                this.ads = this.adsInit.filter(e => e.continue == false)
                this.showlist = true;
            }, 1);
        }, 
        rematching(lists,list,index){
            if(!list.구매링크){
                alert('구매링크를 입력해 주세요')
                return
            }
            list.옵션1_중국어 = undefined;
            list.옵션2_중국어 = undefined;
            list.상품매칭.옵션1_중국어 = [];
            list.상품매칭.옵션2_중국어 = [];
            this.matchoption1(lists,index,list.구매링크)
       },
       async matchoption1(lists,index,구매링크){
            lists[index].showspinner = true;
            lists[index].구매링크 = 구매링크;
           this.currentindex = index;
           // setTimeout(() => {this.sendmessage(구매링크);},1)
           var newObj = await this.confirmreq(lists[index]);
           // console.log(newObj)
           // this.showlist = false
           lists[index] = newObj;
           console.log(lists[this.currentindex])
           // this.showlist = true


           // axios.post('/api/reqpurchase/geturlinfo',{
           //     url : 구매링크
           // })
           // .then((res) => {
           //     if(res.data.요청결과 == 'success'){
           //         console.log(res.data.result)
           //         var obj = {}
           //         res.data.result.option1array.forEach(e => {
           //             obj = {
           //                 image : e.imageUrl,
           //                 optionname : e.name,
           //             }
           //             list.상품매칭.옵션1_중국어.push(obj)
           //         })
           //         res.data.result.option2array.forEach(e => {
           //             obj = {
           //                 image : e.imageUrl,
           //                 optionname : e.name,
           //             }
           //             list.상품매칭.옵션1_중국어.push(obj)
           //         })

           //     } else {
           //         alert('error777 :' + res.data.요청결과)
           //     }
           // })
           // .catch(console.log)
       },
       async confirmreq(obj){
        if(!obj.구매링크){
                obj.showspinner = false;
                return obj
            }
            obj.구매링크init = obj.구매링크;
            obj.매칭상태 = '매칭필요'
            var item = await this.getProductInfo(obj.구매링크);
            console.log(item)

            if(item.format_check == 'fail'){
                obj.상품매칭.옵션1_중국어 = [];
                obj.상품매칭.옵션2_중국어 = [];
                obj.옵션1_중국어 = '';
                obj.옵션2_중국어 = '';
                obj.구매재고 = '구매불가';
                obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : item.pic_url;
                obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : item.pic_url;
                obj.매칭상태 = '매칭필요';
                obj.showspinner = false;
                return obj
            }

            var option1array = [];
            var option2array = [];
            if(item.props_list && Object.keys(item.props_list).length > 0) {
                item.skus.sku.forEach(e => {
                    if (e.properties.includes(";")) {
                        var properties1 = e.properties.split(';')[0];
                        var properties2 = e.properties.split(';')[1];
                        if (!option1array.find(ele => ele.properties == properties1)) {
                            var name = e.properties_name.split(";" + properties2)[0].replace(properties1 + ":", "")
                            name = name.slice(name.search(":") + 1)
                            option1array.push({
                                properties: properties1,
                                name: name,
                                imageUrl: item.props_img[properties1] ? item.props_img[properties1] : '',
                            })
                        }
                        if (!option2array.find(ele => ele.properties == properties2)) {
                            name = e.properties_name.split(";" + properties2)[1].slice(1);
                            name = name.slice(name.search(":") + 1)
                            option2array.push({
                                properties: properties2,
                                name: name,
                                imageUrl: item.props_img[properties2] ? item.props_img[properties2] : '',
                            })
                        }
                    } else {
                        name = e.properties_name.replace(e.properties + ":", "");
                        name = name.slice(name.search(":") + 1)
                        option1array.push({
                            properties: e.properties,
                            name: name,
                            imageUrl: item.props_img[e.properties] ? item.props_img[e.properties] : '',
                        })
                    }
                })
            }

            if(option1array.length == 0){
                obj.상품매칭.옵션1_중국어 = [];
                obj.상품매칭.옵션2_중국어 = [];
                obj.옵션1_중국어 = '';
                obj.옵션2_중국어 = '';
                obj.구매재고 = parseInt(item.num);
                obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : item.pic_url;
                obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : item.pic_url;
                obj.매칭상태 = '매칭완료'
            } else {
                if(option2array.length == 0){
                    var imgUrl = '';
                    if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                        obj.옵션1_중국어 = undefined;
                        obj.옵션이미지 = undefined;
                        obj.구매재고 = 0;
                        obj.item = item
                    } else {
                        var option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                        console.log(option1properties)
                        obj.구매재고 = parseInt(item.skus.sku.find(e => e.properties == option1properties).quantity);
                        if(item.prop_imgs.prop_img.length > 0){
                            if(item.prop_imgs.prop_img.find(e => e.properties == option1properties)){
                                imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option1properties).url;
                            } else {
                                if(obj.옵션이미지){
                                    imgUrl = obj.옵션이미지;
                                } else {
                                    imgUrl = '';
                                }
                            }

                        }
                        obj.옵션이미지 = imgUrl;
                        obj.매칭상태 = '매칭완료'
                    }
                    obj.상품매칭.옵션1_중국어 = option1array;
                    obj.상품매칭.옵션2_중국어 = [];
                    obj.옵션2_중국어 = '';
                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
                } else {
                    imgUrl = '';
                    if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                        obj.옵션1_중국어 = undefined;
                        obj.옵션이미지 = undefined;
                        obj.구매재고 = 0;
                        obj.item = item
                    } else {
                        option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                        if(item.prop_imgs.prop_img && item.prop_imgs.prop_img.find(e => e.properties == option1properties)){
                            imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option1properties).url;
                        }
                        obj.옵션이미지 = imgUrl;
                        if(!option2array.find(e => e.name == obj.옵션2_중국어)){
                            obj.옵션2_중국어 = undefined;
                            obj.구매재고 = 0;
                            obj.item = item
                        } else {
                            var option2properties = option2array.find(e => e.name == obj.옵션2_중국어).properties;
                            if (!item.skus.sku.find(e => e.properties == (option1properties + ';' + option2properties))) {
                                obj.옵션이미지 = undefined
                                obj.옵션1_중국어 = undefined;
                                obj.옵션2_중국어 = undefined;
                                obj.매칭상태 = '매칭필요'
                                obj.구매가격 = ''
                            } else {
                                obj.구매재고 = parseInt(item.skus.sku.find(e => e.properties == (option1properties + ';' + option2properties)).quantity);
                            }
                            if(item.prop_imgs.prop_img && item.prop_imgs.prop_img.find(e => e.properties == option2properties)){
                                imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option2properties).url;
                            }
                            obj.옵션이미지 = imgUrl;
                            obj.매칭상태 = '매칭완료'
                        }
                    }
                    obj.상품매칭.옵션1_중국어 = option1array;
                    obj.상품매칭.옵션2_중국어 = option2array;
                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
                }
            }
            if(!obj.등록이미지 && !option1array.find(e => e.imageUrl) && !option1array.find(e => e.imageUrl)){
                obj.등록이미지 = item.pic_url;
            }
            obj.showspinner = false;
            return obj
       },
       async getProductInfo(구매링크){
        var productNo = 구매링크.slice(구매링크.search('offer/')+6,구매링크.search('.html'));
            console.log(productNo)
            try{
                var res = await axios.post('/api/reqpurchase/getProductInfo',[productNo])
                return res.data.result[0].item
            }catch(e){
                console.log('err821' + e);
                return false
            }
       },
       pricechange(obj){
           this.change = true;
           if(obj.매칭상태 == '매칭필요'){
               obj.구매가격 = '';
               return
           }
           if(obj.item && obj.구매요청수량){
               var option1array = [];
               var option2array = [];
               var item = obj.item;
               if(item.props_list && Object.keys(item.props_list).length > 0){
                   Object.keys(item.props_list).forEach(e => {
                       if(e[0] == '0'){
                           option1array.push({
                               properties : e,
                               name : item.props_list[e].split(":")[1],
                               imageUrl : item.props_img[e] ? item.props_img[e] : '',
                           })
                       }
                       if(e[0] == '1'){
                           option2array.push({
                               properties : e,
                               name : item.props_list[e].split(":")[1],
                               imageUrl : item.props_img[e] ? item.props_img[e] : '',
                           })
                       }
                   })
               }

               if(option1array.length == 0){
                   if(item.priceRange){
                       for(var i=0;i<item.priceRange.length;i++){
                           obj.구매가격 = parseFloat(item.priceRange[i][1]);
                           if(parseInt(item.priceRange[i][0]) > obj.구매요청수량){
                               break
                           }
                       }
                   } else {
                       obj.구매가격 = parseInt(item.price);
                   }
               } else {
                   if(option2array.length == 0){
                       if(item.priceRange){
                           for(i=0;i<item.priceRange.length;i++){
                               obj.구매가격 = parseFloat(item.priceRange[i][1]);
                               if(parseInt(item.priceRange[i][0]) > obj.구매요청수량){
                                   break
                               }
                           }
                       } else {
                           obj.구매가격 = parseInt(item.price);
                       }
                   } else {
                       if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                           obj.구매가격 = '';
                       } else {
                           if(!option2array.find(e => e.name == obj.옵션2_중국어)){
                               obj.구매가격 = '';
                           } else {
                               var option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                               var option2properties = option2array.find(e => e.name == obj.옵션2_중국어).properties;
                               obj.구매가격 = parseInt(item.skus.sku.find(e => e.properties == (option1properties + ';' + option2properties)).price);
                           }
                       }
                   }
               }
           }
       },
       reset(list){
            list.옵션1_중국어 = undefined;
            list.옵션2_중국어 = undefined;
            list.상품매칭.옵션1_중국어 = [];
            list.상품매칭.옵션2_중국어 = [];
            list.구매링크 = undefined;
            list.구매링크init = '';
       },
       deleteindexfromarray(array,index){
            return array.slice(0,index).concat(array.slice(index+1))
        },
        deleteitem(lists,index,f){
            // this.showPurchase = false;
            lists.forEach(e => {
                e.묶음구성여부 = lists.length == 2 ? false : true;
                e.묶음구성수량 = lists.length == 2 ? "" : (lists.length-1)
            })
            lists.splice(index,1)
            console.log(lists)
            console.log(f)
            if(lists.length == 0){
                console.log(lists.length)
                lists.push({
                    checked : false,
                    showspinner : false,
                    // SKUID : '',
                    바코드 : f.바코드,
                    상품명 : f.상품명,
                    상품명init : f.상품명,
                    구매링크 : undefined,
                    구매링크init : undefined,
                    옵션1_중국어 : undefined,
                    옵션2_중국어 : undefined,
                    옵션이미지: undefined,
                    매칭상태 : '매칭필요',
                    상품매칭 : {
                        옵션1_중국어 : [],
                        옵션2_중국어 : [],
                    },
                    구매재고 : '',
                    구매요청수량 : '',
                    판매구성수량 : 1,
                    묶음구성여부 : false,
                    묶음구성수량 : '',
                    묶음대상바코드 : '',
                    신고영문명 : '',
                    신고가격 : '',
                    HSCODE : '',
                    진행현황 : '구매전',
                    재고동봉 : true,
                    등록이미지 : f.옵션이미지,
                    요청사항 : '요청등록',
                    reqtype : '제트배송',
                    sizeX : f.sizeX,
                    sizeY : f.sizeY,
                    sizeZ : f.sizeZ,
                    weight : f.weight,
                    요청내역 : {
                        default : true,
                        poarrange : false,

                        한글표시사항 : true,
                        barcode : true,
                        sticker : false,
                        sewing : false,
                        stamp : false,
                        tag : false,
                        reqcontent : '',
                        opp : false,
                        LDPE : false,
                        airpacking : false,
                        exportType : 'box',
                        destination : 'coupang',
                        masking : false,
                        auto : true,
                    },
                    한글표시사항 : {
                        제품명 : f.상품명,
                        '수입원/판매원' : '',
                        제조원 : '',
                        제조국 : '',
                        내용량 : '',
                        '원료명및성분명(재질)' : '',
                        상품유형 : '',
                        사용시주의사항 : '',
                        사용기준 : '',
                        custom : '',
                    },
                    polists : [],
                    selectpo : '', 
                })
                
            }
            console.log(lists)
            // lists = this.deleteindexfromarray(lists,index);
            // setTimeout(()=>{
            //     this.showPurchase = true;
            // },10)
            
        },
       matchclass(a){
           if(a == '매칭완료'){
               return 'badge badge-success';
           } else {
               return 'badge badge-warning';
           }
       },
       reqclass(a){
           if(a == '등록완료'){
               return 'badge badge-success';
           } else {
               return 'badge badge-warning';
           }
       },
       regclass(a){
           if(a.length > 0){
               return 'badge badge-success';
           } else {
               return 'badge badge-warning';
           }
       },
       option1matching(a,list){
            console.log(a)
            console.log(list)
           // this.showlist = false;
           if(a.imageUrl){
                // if(!list.등록이미지){
                    list.등록이미지 = a.imageUrl;
                // }
                list.옵션이미지 = a.imageUrl;
            }
            list.옵션1_중국어 = a.name;


            // if(this.checkstock){
            if(list.옵션2_중국어 == ''){
                var properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1).quantity);
                if(list.구매재고 == 0){
                    alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                } else {
                    alert('구매 가능한 재고수량 : ' + list.구매재고)
                }
                list.매칭상태 = '매칭완료';
            } else {
                if(list.옵션2_중국어 !== undefined){
                    properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                    var properties2 = list.상품매칭.옵션2_중국어.find(e => e.name == list.옵션2_중국어).properties;
                    list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).quantity);
                    list.구매sku = list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).sku_id;
                    if(list.구매재고 == 0){
                        alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                    } else {
                        alert('구매 가능한 재고수량 : ' + list.구매재고)
                    }
                    list.매칭상태 = '매칭완료';
                }
            }
            if(list.등록이미지 == '' && list.옵션이미지 == ''){
                list.등록이미지 = list.item.pic_url;
                list.옵션이미지 = list.item.pic_url;
            }
            console.log(list)
            // this.showlist = true;
            // }
       },
       option2matching(a,list){
           // this.showlist = false;
           if(a.imageUrl){
                list.등록이미지 = a.imageUrl;
            }
            list.옵션2_중국어 = a.name;

            // if(this.checkstock){

            if(list.상품매칭.옵션1_중국어 && list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어)){
                var properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                var properties2 = list.상품매칭.옵션2_중국어.find(e => e.name == list.옵션2_중국어).properties;
                list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).quantity);
                list.구매sku = list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).sku_id;
                if(list.구매재고 == 0){
                    alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                } else {
                    alert('구매 가능한 재고수량 : ' + list.구매재고)
                }
                list.매칭상태 = '매칭완료'
            }

            // }
            if(list.등록이미지 == '' && list.옵션이미지 == ''){
                list.등록이미지 = list.item.pic_url;
                list.옵션이미지 = list.item.pic_url;
            }
            console.log(list)
            // this.showlist = true;
       },
    }
}
</script>
<style scoped>

/* .modal-dialog {
    max-width: 90%;
    position: absolute;

    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
} */

.my-class .dropdown-menu {
    transform: translate3d(0px, -30px, 0px) !important;
    max-height: 100px;
    width:400px;
    z-index:10000;
    overflow-y: auto;
}



.toggle-icon {
    cursor: default;
}

.toggle-background {
    transition: fill 0.2s ease-in-out;
}

.toggle-switch {
    transition: cx 0.2s ease-in-out;
    fill: white;
}

.toggle-icon.on .toggle-background {
    fill: #4CD964;
}

.toggle-icon.off .toggle-background {
    fill: #D1D1D6;
}

</style>

