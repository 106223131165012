<template>
    <v-app>
        <div>
            <!-- 상단 컨트롤 영역 -->
            <div class="d-flex justify-content-between mb-3">
                <div class="d-flex justify-content-start w-75">
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        class="search-input"
                    />

                    <v-select
                        :id="`${uniqueId}_perPage`"
                        v-if="isPerPage"
                        v-model="itemsPerPage"
                        @change="handlePerPageChange"
                        :items="perPageOptions"
                        label="Items per page"
                        dense
                        outlined
                        class="table-control-select"
                        @click="handleMethod(itemsPerPage)"
                    />

                    <v-select
                        :id="`${uniqueId}_columns`"
                        v-if="isColumn"
                        v-model="selectedColumns"
                        :items="headers.map(header => header.text)"
                        item-text="text"
                        item-value="value"
                        label="선택 컬럼 보기"
                        class="table-control-select"
                        dense
                        outlined
                        multiple
                        chips
                        placeholder="선택 컬럼 보기"
                    >
                        <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                            <span>{{ item }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption">
                            (+{{ selectedColumns.length - 1 }} others)
                        </span>
                        </template>
                    </v-select>

                    <v-select
                        :id="`${uniqueId}_fixed`"
                        v-model="selectedFixedColumn"
                        :items="headers"
                        item-text="text"
                        item-value="value"
                        label="고정할 열 선택"
                        class="table-control-select"
                        dense
                        outlined
                        clearable
                    />
                </div>

                <div v-if="isRadio" class="radio-container">
                    <div class="form-group">
                        <label class="d-block mr-2">높이고정</label>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input :id="`radio1_${uniqueId}`" :name="`radioGroup_${uniqueId}`" type="radio" class="custom-control-input" v-model="radioCheck" @change="handleRadioChange(true)" :value="true">
                            <label class="custom-control-label" :for="`radio1_${uniqueId}`"> Y </label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input :id="`radio2_${uniqueId}`" :name="`radioGroup_${uniqueId}`" type="radio" class="custom-control-input" v-model="radioCheck" @change="handleRadioChange(false)" :value="false">
                            <label class="custom-control-label" :for="`radio2_${uniqueId}`"> N </label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 테이블 영역 -->
            <div class="table-scroll-container" :class="{ 'no-fixed-height': !radioCheck }">
                <div class="table-wrapper">
                    <!-- 고정 컬럼 테이블 -->
                    <div v-if="selectedFixedColumn && showFixedTable" 
                         class="fixed-table-container"
                         :class="{ 'show-fixed': isScrolled }">
                        <v-data-table
                            :headers="fixedHeaders"
                            :items="currentPageItems"
                            :height="tableHeight"
                            :hide-default-footer="true"
                            :show-select="showSelect"
                            v-model="selected"
                            :single-select="singleSelect"
                            :page.sync="currentPage"        
                            :items-per-page="itemsPerPage"  
                            fixed-header
                            class="fixed-table sticky-header elevation-0"
                        >
                            <!-- 체크박스 슬롯 -->
                            <template v-if="!hideCheckboxAll" v-slot:header.data-table-select>
                                <v-simple-checkbox
                                    :value="selected.length === sortedItems.length"
                                    :indeterminate="selected.length > 0 && selected.length < sortedItems.length"
                                    @input="selectAllItems"
                                />
                            </template>

                            <template v-slot:item.data-table-select="{ item }">
                                <v-simple-checkbox
                                    :value="selected.includes(item)"
                                    @input="toggleSelectItem(item)"
                                />
                            </template>

                            <!-- 고정 열 데이터 슬롯 -->
                            <template v-for="(header, index) in fixedHeaders" v-slot:[`item.${header.value}`]="{ item }">
                                <div :key="index">
                                    <slot v-if="header.isSlot" :name="`${header.value}`" :item="item" />
                                    <span v-else>{{ item[header.value] }}</span>
                                </div>
                            </template>
                        </v-data-table>
                    </div>

                    <!-- 메인 테이블 -->
                    <v-data-table
                        ref="mainTable"
                        :id="'data_table_'.concat(uniqueId)"
                        v-model="selected"
                        :headers="selectedItems"
                        :height="tableHeight"
                        :show-select="showSelect"
                        :items="sortedItems"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :page.sync="currentPage"
                        :items-per-page="itemsPerPage"
                        @input="onInput"
                        :item-key="itemKey"
                        @page-count="pageCount = $event"
                        fixed-header
                        hide-default-footer
                        class="main-table sticky-header elevation-0"
                        @scroll="handleScroll"
                    >
                        <template v-if="hideCheckboxAll" v-slot:header.data-table-select></template>
                        
                        <template v-slot:header.text="{ header }">
                            <span style="white-space: pre-line">{{ header.text }}</span>
                        </template>

                        <template v-for="(header, index) in headers" v-slot:[`item.${header.value}`]="{ item }">
                            <div :key="index">
                                <slot v-if="header.isSlot" :name="`${header.value}`" :item="item" />
                                <span v-else>{{ item[header.value] }}</span>
                            </div>
                        </template>
                    </v-data-table>
                </div>
            </div>

            <!-- 페이지네이션 -->
            <v-pagination
                v-if="sortedItems.length > itemsPerPage"
                v-model="currentPage"
                :length="Math.ceil(sortedItems.length / itemsPerPage)"
                :total-visible="15"
                @input="changePage"
                class="mt-3"
            />
        </div>
    </v-app>
</template>

<script>
import { v4 } from 'uuid'

export default {
    name: 'CDataTable',
    data() {
        return {
            uniqueId: v4(),
            selected: [],
            search: '',
            currentPage: 1,
            itemsPerPage: 10,
            perPageOptions: [10, 25, 50, 100],
            radioCheck: true,
            selectedColumns: [],
            columns: [],
            sortBy: [],
            sortDesc: [],
            isScrolled: false,
            selectedFixedColumn: this.fixedColumns,
            showFixedTable: true,
            pageCount: 0,
            tableHeight: this.height || '600px',
            textToValueMap: {},
            // Add unique identifiers for form controls
            radioId1: `radio1_${v4()}`,
            radioId2: `radio2_${v4()}`,
            selectId: `select_${v4()}`
        }
    },
    props: {
        headers: {
            type: Array,
            required: true
        },
        height: {
            type: String,
            default: '600px'
        },
        showSelect: Boolean,
        countInit: Number,
        singleSelect: Boolean,
        hideCheckboxAll: Boolean,
        totalCount: Number,
        items: {
            type: Array,
            required: true
        },
        itemKey: {
            type: String,
            required: true
        },
        page: Number,
        isPerPage: {
            type: Boolean,
            default: true
        },
        isColumn: {
            type: Boolean,
            default: true
        },
        isRadio: {
            type: Boolean,
            default: true
        },
        initialSelectedColumns: {
            type: Array,
            default: () => []
        },
        fixedColumns: {
            type: String,
            default: null
        }
    },
    methods: {
        // Add namespace to radio handlers
        handleRadioChange(value) {
            this.radioCheck = value;
            this.updateTableHeight();
        },
        // Update select handlers
        handlePerPageChange(value) {
            this.itemsPerPage = value;
            this.$emit('click', value);
        },
        
        handleColumnSelect(value) {
            this.selectedColumns = value;
            this.$nextTick(() => {
                this.syncHeaderHeights();
            });
        },
        columnItems() {
        return this.headers.map(header => ({
            text: header.text,
            value: header.value
        }))
        },
        onInput(eventvalue) {
            this.$emit('input', eventvalue)
        },
        handleMethod(eventvalue) {
            this.$emit('click', eventvalue)
        },
        itemsLength(eventValue) {
            this.$emit('itemsLength', eventValue)
        },
        changePage(value) {
            this.currentPage = value
            this.isScrolled = false
            this.showFixedTable = false
            
            this.$nextTick(() => {
                setTimeout(() => {
                    this.showFixedTable = true
                    this.syncHeaderHeights()
                }, 50)
                
                this.emitChangePage()
            })
        },
        emitChangePage() {
            this.$emit('changePage', this.currentPage)
        },
        handleScroll(e) {
            const target = e.target || e.srcElement
            this.isScrolled = target.scrollLeft > 0
            
            const fixedTableContainer = this.$el.querySelector('.fixed-table-container')
            if (fixedTableContainer) {
                const fixedWrapper = fixedTableContainer.querySelector('.v-data-table__wrapper')
                if (fixedWrapper) {
                    fixedWrapper.scrollTop = target.scrollTop
                }
            }
        },
        selectAllItems(value) {
            if (value) {
                this.selected = [...this.sortedItems]
            } else {
                this.selected = []
            }
            this.$emit('input', this.selected)
        },
        toggleSelectItem(item) {
            const index = this.selected.findIndex(i => i[this.itemKey] === item[this.itemKey])
            if (index === -1) {
                this.selected.push(item)
            } else {
                this.selected.splice(index, 1)
            }
            this.$emit('input', this.selected)
        },
        syncHeaderHeights() {
            this.$nextTick(() => {
                const mainHeaders = this.$refs.mainTable.$el.querySelectorAll('th')
                const fixedTableContainer = this.$el.querySelector('.fixed-table-container')
                
                if (fixedTableContainer) {
                    const fixedHeaders = fixedTableContainer.querySelectorAll('th')
                    mainHeaders.forEach((header, index) => {
                        if (fixedHeaders[index]) {
                            const height = getComputedStyle(header).height
                            fixedHeaders[index].style.height = height
                        }
                    })
                }
            })
        },
        updateTableHeight() {
            if (!this.radioCheck) {
                this.tableHeight = 'auto'
            } else {
                this.tableHeight = this.height || '700px'
            }
        }
    },
    computed: {
        filteredItems() {
            return this.items.filter((item) => {
                if (!this.search) return true
                
                const searchTerms = this.search.toLowerCase().split(' ').filter(term => term.length > 0)
                return searchTerms.every(term => {
                    return Object.values(item).some(value => 
                        value != null && String(value).toLowerCase().includes(term)
                    )
                })
            })
        },
        sortedItems() {
            let items = [...this.filteredItems]
            if (this.sortBy.length) {
                const sortBy = this.sortBy[0]
                const sortDesc = this.sortDesc[0]
                
                items.sort((a, b) => {
                    let compareA = a[sortBy]
                    let compareB = b[sortBy]
                    
                    if (typeof compareA === 'string') compareA = compareA.toLowerCase()
                    if (typeof compareB === 'string') compareB = compareB.toLowerCase()
                    
                    if (compareA < compareB) return sortDesc ? 1 : -1
                    if (compareA > compareB) return sortDesc ? -1 : 1
                    return 0
                })
            }
            console.log(items)
            return items
        },
        currentPageItems() {
            const start = (this.currentPage - 1) * this.itemsPerPage
            const end = start + this.itemsPerPage
            return this.sortedItems.slice(start, end)
        },
        fixedHeaders() {
            if (!this.selectedFixedColumn) return []
            const selectedIndex = this.headers.findIndex(h => h.value === this.selectedFixedColumn)
            return this.headers.slice(0, selectedIndex + 1)
        },
        selectedItems() {
        if (!this.selectedColumns.length) {
            return this.headers
        }
        
        return this.headers.filter(header => {
            return this.selectedColumns.includes(header.text)
        })
        }
    },
    watch: {
        search() {
            this.currentPage = 1
        },
        selectedFixedColumn(newVal) {
            if (!newVal) {
                this.isScrolled = false
            }
            this.$nextTick(() => {
                this.syncHeaderHeights()
            })
        },
        radioCheck() {
            this.updateTableHeight()
        },
        currentPageItems: {
            handler() {
                this.$nextTick(() => {
                    this.syncHeaderHeights()
                })
            }
        },
        itemsPerPage: {
            handler(newValue) {
                this.showFixedTable = false
                this.isScrolled = false
                this.currentPage = 1
                
                this.$nextTick(() => {
                    const mainTableWrapper = this.$refs.mainTable.$el.querySelector('.v-data-table__wrapper')
                    if (mainTableWrapper) {
                        mainTableWrapper.scrollLeft = 0
                    }
                    
                    setTimeout(() => {
                        this.showFixedTable = true
                        this.syncHeaderHeights()
                    }, 50)
                    
                    this.handleMethod(newValue)
                })
            },
            immediate: false
        }
    },
    created() {
        // Initialize columns with both text and value
        this.columns = this.headers.map(header => ({
        text: header.text,
        value: header.value
        }))
        
        // Create a mapping of text to value
        this.textToValueMap = this.headers.reduce((map, header) => {
        map[header.text] = header.value
        return map
        }, {})

        // Initialize selectedColumns
        if (this.initialSelectedColumns.length > 0) {
        this.selectedColumns = this.initialSelectedColumns.filter(
            column => this.columns.some(c => 
            c.value === column || 
            c.text === column
            )
        )
        } else {
        this.selectedColumns = this.columns.map(column => column.text)
        }
        
        if (!this.page) {
            this.currentPage = 1
        } else {
            this.currentPage = this.page
        }
        
        this.updateTableHeight()
    },
    mounted() {
        const tableWrapper = this.$refs.mainTable.$el.querySelector('.v-data-table__wrapper')
        tableWrapper.addEventListener('scroll', this.handleScroll)
        
        // Add unique IDs to form controls
        const controls = this.$el.querySelectorAll('select, input[type="radio"]')
        controls.forEach(control => {
            control.id = `${this.uniqueId}_${control.id}`
        })
        
        this.$nextTick(() => {
            this.syncHeaderHeights()
        })
    },
    beforeDestroy() {
        const tableWrapper = this.$refs.mainTable.$el.querySelector('.v-data-table__wrapper')
        tableWrapper.removeEventListener('scroll', this.handleScroll)
    }
}
</script>

<style lang="scss" scoped>
.table-scroll-container {
  position: relative;
  height: v-bind(tableHeight);
  margin-bottom: 16px;
  &.no-fixed-height { height: auto; }
}

.table-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

::v-deep .v-data-table {
  height: 100%;
  
  .v-data-table__wrapper {
      height: 100%;
      max-height: none;
      overflow: auto;
      border-radius: 10px;
      border: 1px solid #DCDFE8;
  }

  table {
      width: 100%;
      border-spacing: 0;
  }
  
  thead tr:first-child th {
      position: sticky !important;
      top: 0;
      z-index: 2;
      background-color: #f8f7f7 !important;
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
      white-space: normal !important;

      &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          border-bottom: thin solid rgba(0, 0, 0, 0.12);
      }
  }
}

.fixed-table-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 4;
  background: white;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  border-right: 2px solid rgba(0, 0, 0, 0.12);

  &.show-fixed {
      opacity: 1;
      pointer-events: auto;
  }

  ::v-deep .v-data-table__wrapper {
      overflow: hidden !important;
      thead tr:first-child th { z-index: 5 !important; }
  }
}

::v-deep .v-data-table-header th {
  background-color: #f4f5fa !important;
  white-space: pre-line !important;
  height: unset !important;
  padding: 16px 8px !important;
  
  span {
      display: flex;
      justify-content: center !important;
      align-items: center !important;
      white-space: pre-line !important;
      min-height: unset !important;
      line-height: 1.2;
  }
}

::v-deep .v-data-table__wrapper {
  &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
  }
  &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
      &:hover { background: #555; }
  }
}

// 기본 컨트롤
.table-control-select {
  width: 130px !important;
  margin-right: 12px;
  position: relative;
  z-index: 3;
}

.search-input {
  width: 250px;
  margin-right: 12px;
  position: relative;
  z-index: 3;
}

.radio-container {
  margin-left: auto;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 3;

  .form-group {
      display: flex;
      align-items: center;
      padding: 0 12px;
  }
}

::v-deep {
  .v-input__slot {
      min-height: 40px !important;
      max-height: 40px !important;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details,
  .v-select.v-select--enclosed .v-select__details {
      display: none;
  }

  .v-input--dense.v-text-field--outlined .v-input__slot,
  .v-input--dense.v-select--outlined .v-input__slot {
      min-height: 40px !important;
      max-height: 40px !important;
  }

  .v-input {
      margin-top: 0 !important;
      padding-top: 0 !important;
  }

  .v-select__selections {
      min-height: 38px;
      display: flex;
      align-items: center;
  }
}

// 모달 관련
.modal-backdrop { z-index: 1040; }
.modal-dialog { z-index: 1050; }

::v-deep .modal-content, 
.modal {
   .table-control-select,
   .search-input {
      z-index: 2055 !important;
      .v-input__slot { z-index: 2055 !important; }
   }
   .radio-container { 
      z-index: 2065 !important; // 이 값을 증가
      input[type="radio"] { 
         z-index: 2065 !important; // 라디오 버튼 자체의 z-index도 추가
      }
   }
   .v-menu__content { z-index: 2060 !important; }
}

// 드롭다운 메뉴
.v-menu__content { 
   z-index: 1000;
   &.menuable__content__active { z-index: 2060 !important; }
}

::v-deep .v-chip {
  margin: 0 !important;
  height: fit-content;
}

::v-deep .v-pagination__navigation,
::v-deep .v-pagination__item {
  box-shadow: none;
}

.table-container {
  max-height: 700px;
  overflow: auto;
  &-fix-none {
      max-height: none;
  }
}

::v-deep .v-application--wrap {
  min-height: 0 !important;
}
</style>