<template>
    <div class="container-fluid">
         <div class="row">
            <div class="col-sm-12">
                  <div class="card">
                     <div class="card-header d-flex justify-content-between">
                        <div class="header-title">
                           <h4 class="card-title">외주 권한 관리</h4>
                        </div>
                     </div>
                     <div class="card-body">
                        <div>
                           <label>※ 외주인원이 쿠플러스에 회원가입하면 아래 리스트에 추가 됩니다. (단, 회원가입 시 업체명({{companyid}})과 판매자코드({{suppliercode}})가 일치해야 합니다.)</label>
                        </div>
                        <div class="table-responsive text-center" style="font-size:90%" v-if="showlist">
                           <tableList>
                              <template v-slot:header>
                                 <tr class="ligth">
                                    <th style="font-size:90%">비고</th>
                                    <!-- <th style="font-size:90%">업체명</th> -->
                                    <th style="font-size:90%">Name</th>
                                    <th style="font-size:90%">Phone</th>
                                    <th style="font-size:90%">Email</th>
                                    <th style="font-size:90%">로그인권한</th>
                                    <th style="font-size:90%">접속IP제한</th>
                                    <th style="font-size:90%">노마드클럽수집</th>
                                    <th style="font-size:90%">노마드클럽메뉴</th>
                                    <!-- <th style="font-size:90%">로켓그로스상품DB</th> -->
                                    <th style="font-size:90%">구매요청메뉴</th>
                                    <th style="font-size:90%">판매SCM메뉴</th>
                                    <th style="font-size:90%">랭킹추적</th>
                                    <th style="font-size:90%">키워드분석</th>
                                    <th style="font-size:90%">상품명메이커</th>
                                    <th style="font-size:90%">키워드그래프</th>
                                    <th style="font-size:90%">키워드카테고리</th>
                                    <th style="font-size:90%" v-if="$store.state.user.auth.shownomadv3">TF2</th>
                                    <th style="font-size:90%">광고관리</th>
                                    <th style="font-size:90%" data-toggle="tooltip" v-b-tooltip.top title="쿠플러스 우측상단 Company ID, 판매자코드 노출여부">판매자정보노출</th>
                                    <!-- <th style="font-size:90%">상세페이지</th> -->
                                    <th style="font-size:90%">삭제</th>
                                 </tr>
                              </template>
                              <template v-slot:body>
                                 <tr v-for="userlist,index in userlists" :key="index">
                                    <td style="min-width:100px;"><input type="text" class="form-control" aria-label="" v-model="userlist.memo" @change="changeusermemo(userlist)" style="text-align:center;"></td>
                                    <!-- <td>{{ userlist.companyid }}</td> -->
                                    <td>{{userlist.name}}</td>
                                    <td>{{userlist.phone}}</td>
                                    <td>{{userlist.email}}</td>
                                    <td><span :class="[badge,bgcolor(userlist.approval)]" @click="updateUserAuth('approval',userlist)">{{userlist.approval ? 'on' : 'off'}}</span></td>
                                    <td>
                                       <span :class="[badge,bgcolor(userlist.ipCheck)]" @click="updateUserAuth('ipCheck',userlist)">{{userlist.ipCheck ? 'on' : 'off'}}</span>
                                       <div v-if="userlist.ipCheck">
                                          <div v-for="allowIp,index of userlist.allowIps" :key="index">
                                             <span>{{allowIp.ip}}</span>
                                          </div>
                                          <span class="badge bg-primary mt-1" @click="showIpList(userlist)">허용IP설정</span>
                                       </div>

                                       <b-modal id="modal-4" size="md" title="허용IP설정" ok-title="저장" cancel-title="취소" @ok="saveIp($event,userlist)" @cancel="cancelIp($event,userlist)">
                                          <div class="row">
                                             <div class="col-md-12">
                                                <div class="form-group">
                                                   <label  for="Code">허용할 IP를 "xxx.xx.xx.xx"와 같이 IP를 정확하게 입력해 주세요</label>
                                                </div>
                                             </div>
                                             <div class="col-md-12" v-if="userlist.ipCheck">
                                                <div class="table-responsive">
                                                   <table id="datatable" class="table data-table table-striped dataTable text-center" style="font-size:90%">
                                                      <thead>
                                                         <tr class="ligth ligth-data">
                                                            <th style="font-size:90%">IP주소</th>
                                                            <th style="font-size:90%">삭제</th>
                                                         </tr>
                                                      </thead>
                                                      <tbody>
                                                         <tr v-for="(allowIp,index) in userlist.allowIps" :key="index">
                                                            <td>
                                                               <input type="text" class="form-control mt-1" aria-label="" v-model="allowIp.ip" style="text-align:center;">
                                                            </td>
                                                            <td>
                                                               <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete" href="javascript:void(0)" @click="deleteIp(userlist,index)">
                                                                  <i class="ri-delete-bin-line mr-0"></i>
                                                               </a>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
                                                </div>
                                                <div class="d-flex justify-content-between">
                                                   <button type="button" class="btn btn-success mr-2 mb-4" @click="addAllowIp(userlist)">허용 IP주소 추가</button>
                                                </div>
                                             </div>
                                          </div>
                                       </b-modal>

                                    </td>
                                    <td><span :class="[badge,bgcolor(userlist.collectNomad)]" @click="updateUserAuth('collectNomad',userlist)">{{userlist.collectNomad ? 'on' : 'off'}}</span></td>
                                    <td><span :class="[badge,bgcolor(userlist.showNomadMenu)]" @click="updateUserAuth('showNomadMenu',userlist)">{{userlist.showNomadMenu ? 'on' : 'off'}}</span></td>
                                    
                                    <td><span :class="[badge,bgcolor(userlist.showgrowth)]" @click="updateUserAuth('showgrowth',userlist)">{{userlist.showgrowth ? 'on' : 'off'}}</span></td>
                                    <td><span :class="[badge,bgcolor(userlist.showSCM)]" @click="updateUserAuth('showSCM',userlist)">{{userlist.showSCM ? 'on' : 'off'}}</span></td>
                                    <td><span :class="[badge,bgcolor(userlist.showCollectRank)]" @click="updateUserAuth('showCollectRank',userlist)">{{userlist.showCollectRank ? 'on' : 'off'}}</span></td>
                                    <td><span :class="[badge,bgcolor(userlist.showKeywordAnalysis)]" @click="updateUserAuth('showKeywordAnalysis',userlist)">{{userlist.showKeywordAnalysis ? 'on' : 'off'}}</span></td>
                                    <td><span :class="[badge,bgcolor(userlist.makeProductName)]" @click="updateUserAuth('makeProductName',userlist)">{{userlist.makeProductName ? 'on' : 'off'}}</span></td>


                                    <td><span :class="[badge,bgcolor(userlist.showNomadKeyword)]" @click="updateUserAuth('showNomadKeyword',userlist)">{{userlist.showNomadKeyword ? 'on' : 'off'}}</span></td>
                                    <td><span :class="[badge,bgcolor(userlist.showNomadCategory)]" @click="updateUserAuth('showNomadCategory',userlist)">{{userlist.showNomadCategory ? 'on' : 'off'}}</span></td>
                                    <td v-if="$store.state.user.auth.shownomadv3"><span :class="[badge,bgcolor(userlist.shownomadv3)]" @click="updateUserAuth('shownomadv3',userlist)">{{userlist.shownomadv3 ? 'on' : 'off'}}</span></td>
                                    <td><span :class="[badge,bgcolor(userlist.showNomadAdManage)]" @click="updateUserAuth('showNomadAdManage',userlist)">{{userlist.showNomadAdManage ? 'on' : 'off'}}</span></td>


                                    <td><span :class="[badge,bgcolor(userlist.showSellerInfo)]" @click="updateUserAuth('showSellerInfo',userlist)">{{userlist.showSellerInfo === false  ? 'off' : 'on'}}</span></td>
                                    <td>
                                          <div class="d-flex align-items-center list-action">
                                             <!-- <a class="badge bg-success mr-2" data-toggle="tooltip" v-b-tooltip.top title="삭제" href="javascript:void(0)" @click="rematching(list,index)">
                                                <i class="ri-pencil-line mr-0"></i>
                                             </a>
                                             <a class="badge badge-info mr-2" data-toggle="tooltip" v-b-tooltip.top title="Reset" href="javascript:void(0)" @click="reset(list)">
                                                <i class="ri-eye-line mr-0"></i>
                                             </a> -->
                                             <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="삭제" href="javascript:void(0)" @click="deleteitem(index)">
                                                <i class="ri-delete-bin-line mr-0"></i>
                                             </a>
                                          </div>
                                    </td>
                                 </tr>
                              </template>
                           </tableList>
                        </div>
                     </div>
                  </div>
            </div>
         </div>
      </div>
</template>
<script>
import axios from 'axios';
export default {
   name:'nomaduserlist',
   data() {
      return {
         userlists:[],
         auth:[],
         regEmail:'',
         badge:'',
         showlist:false,
         companyid:this.$store.state.user.companyid,
         suppliercode:this.$store.state.user.suppliercode,
         initIp:[],
      }
   },
   methods: {
      async saveIp($event,userlist){
         $event.preventDefault();
         if(userlist.allowIps.length > 0){
            var arr = userlist.allowIps.filter(e => e)
         } else {
            arr = [];
         }
         var obj = {
            email:userlist.email,
            allowIps:arr
         }
         
         try{
            var result = await axios.post('/api/nomadUser/allowIpUpdate',obj);
            console.log(result)
            if(result.data.res == 'success'){
               alert('저장완료')
            } else {
               alert(result.data.res)
            }
         }catch(error){
            console.log(error)
         }
         this.initIp = [];
         this.$bvModal.hide('modal-4');
      },
      cancelIp($event,userlist){
         $event.preventDefault();
         userlist.allowIps = [];
         if(this.initIp.length > 0){
            this.initIp.forEach(e => {
               userlist.allowIps.push({
                  ip:e.ip
               })
            })
         }
         this.initIp = [];
         this.$bvModal.hide('modal-4');
      },
      showIpList(userlist){
         this.initIp = [];
         if(userlist.allowIps && userlist.allowIps.length > 0){
            userlist.allowIps.forEach(e => {
               this.initIp.push({
                  ip : e.ip
               })
            })
         }
         this.$bvModal.show('modal-4');
      },
      deleteIp(userlist,i){
			userlist.allowIps = userlist.allowIps.slice(0,i).concat(userlist.allowIps.slice(i+1));
		},	
      async addAllowIp(userlist){
         if(userlist.allowIps && userlist.allowIps.length > 0){
            userlist.allowIps.push({
               ip:""
            })
         } else {
            userlist.allowIps = [{
               ip:""
            }]
         }
      },
      async updateUserAuth(authName,bool){
         if(authName == 'ipCheck' && bool.ipCheck == false){
            if(!confirm('허용IP로 접속하는 경우에만 접속이 가능하게됩니다. 접속IP를 제한하시겠습니까?')){
               return
            }
         }
         console.log(authName,bool)

         try{
            var result = await axios.post('/api/nomadUserAuthUpdate',{authName:authName,bool:bool});
            console.log(result)
            if(result.data.res == 'success' && result.data.result.modifiedCount == 1){
               bool[authName] = !bool[authName];
            } else {
               alert(result.data.res)
            }
         }catch(error){
            console.log(error)
         }
      },
      activeemail(){
         if(!this.regEmail){
            alert('이메일주소를 입력해 주세요')
            return
         } else {
            axios.post('/api/nomadUserAuthUpdate',{email: this.regEmail})
             .then(result => {
               if(result.data.res == 'success'){
                  this.userlists.unshift(result.data.result);
               } else {
                  alert(result)
               }
            })
             .catch(console.log)
         }
      },
      async changeusermemo(userlist){
         try{
            var result = await axios.post('/api/nomadUserChangeMemo',{userlist:userlist});
            console.log(result)
            if(result.data.res !== 'success'){
               alert(result.data.res)
            }
         }catch(error){
            console.log(error)
         }
      },
      modalID(index) {
         return 'modal' + index
      },
      bgcolor(approval){
         if(approval) {
            return 'badge bg-success'
         } else {
            return 'badge bg-secondary'
         }
      },
      onSubmit(event) {
         event.preventDefault()
         if (this.auth.length > 0){
            var authobject = {
               showdashboard : true,
               showproduct : this.auth.includes("product"),
               showpolist : this.auth.includes("polist"),
               showstock : this.auth.includes("stock"),
               showpurchase : this.auth.includes("purchase"),
               showreturn : this.auth.includes("return"),
               showdeveloper : false,
               showadmin : this.auth.includes("admin")
            };
             this.$store.commit('userauthupdate', {auth : authobject})
             axios.post('/userauthupdate',{email: this.$store.state.user.email, auth : authobject})
             .then(result => console.log(result.data.response))
             .catch(console.log)
         }
      },
      onReset(event) {
         event.preventDefault();
         this.auth = [];
      },  
      deleteindexfromarray(array,index){
           return array.slice(0,index).concat(array.slice(index+1))
      },
      async deleteitem(index){
         if(confirm('외주아이디를 삭제하시겠습니까?')){
            try{
               var result = await axios.post('/api/deletenomaduserlist',{email:this.userlists[index].email})
               if(result.data.res == 'success'){
                  this.userlists = this.deleteindexfromarray(this.userlists,index);
               } else {
                  alert(result.data.alert)
               }
            } catch (err){
               alert(err)
            }
         }
      },
   },
   mounted() {
      this.$nextTick(function () {
         this.showlist = false;
         // if(this.$store.state.user.auth.showadmin == true) {
            axios.post('/api/nomaduserlist')
            .then(result => {
               console.log(result)
               if(result.data.res == 'success'){
                  result.data.result.forEach(e => {
                     if(!e.memo){
                        e.memo = ''
                     }
                  })
                  this.userlists = result.data.result.filter(e => e.email !== this.$store.state.user.email)
                  this.showlist = true;
               } else {
                  alert(result)
               }
            })
            .catch(console.log)
         // }
      })
   }

}
</script>