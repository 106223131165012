<template>
     <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br>{{desc2}}</p>
                        <!-- <p class="mb-0">{{desc1}}<br>{{desc2}}&nbsp;&nbsp;&nbsp;&nbsp;<u v-b-modal.modal-3 variant="link" style="color:blue;">{{desc3}}</u></p>
                        <b-modal id="modal-3" size="xl" title="1688 주문서 업로드 방법" ok-title="Save Changes" cancel-title="Close">
                            <p>생성한 주문서를 <a href="https://www.1688.com" target="_blank"><u>1688</u></a>에 접속해서 아래의 순서로 업로드해주세요</p>
                            <img  style="max-width:100%;" :src="require('../../../assets/images/small/1688주문서업로드.png')">
                        </b-modal> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <b-card>
                    <form  @submit.prevent="submit" >
                        <div class="row">
                            <div class="col-md-12">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio7" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="today" @change="changedate()">
                                    <label class="custom-control-label" for="customRadio7">오늘</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio8" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="week" @change="changedate()">
                                    <label class="custom-control-label" for="customRadio8">최근7일</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="customRadio9" name="customRadio-2" class="custom-control-input" v-model="selectdate" value="month" @change="changedate()">
                                    <label class="custom-control-label" for="customRadio9">최근1개월</label>
                                </div>
                                <!-- <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="checksstock">
                                    <label class="custom-control-label" for="customSwitch2">사입제품 포함 발주서만 보기</label>
                                </div> -->
                            </div>
                            <div class="col-md-12 d-flex align-items-center">
                                <b-input-group>
                                    <select class="custom-select col-md-2" v-model="selectoption">
                                        <option>요청일</option>
                                    </select>
                                    <b-form-datepicker id="example-datepicker1" size="sm" v-model="startdate"></b-form-datepicker>
                                    <p>~</p>
                                    <b-form-datepicker id="example-datepicker2" size="sm" v-model="enddate"></b-form-datepicker>
                                </b-input-group>
                                <b-form-input type="text" class="form-control w-25 ml-3" list="my-list-id" placeholder="업체ID" @keyup.enter="importtextchange($event)" v-model="업체명"></b-form-input>
                                <datalist id="my-list-id">
                                    <option v-for="companyid in companyids" :key="companyid">{{ companyid }}</option>
                                </datalist>
                                <div class="custom-control custom-switch custom-control-inline w-25 ml-2">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="tobepurchase">
                                    <label class="custom-control-label" for="customSwitch1">구매전인 상품만 조회</label>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary mr-2 mt-2" @click="search">검색</button>
                        <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
                    </form>
                </b-card>
            </div>
            <div class="col-lg-12" v-if="showreqlist">
                <div class="p-3">
                    <div class="row">
                        <b-button variant="outline-primary" class="mb-1 mr-2" @click="showtotal">
                            전체 <b-badge variant="primary" class="ml-2">{{total}}</b-badge>
                        </b-button>
                        <b-button variant="outline-secondary" class="mb-1 mr-2" @click="showa">
                            구매전<b-badge variant="secondary" class="ml-2">{{구매전}}</b-badge>
                        </b-button>
                        <b-button variant="outline-primary" class="mb-1 mr-2" @click="showj">
                            구매확인<b-badge variant="primary" class="ml-2">{{구매확인}}</b-badge>
                        </b-button>
                        <b-button variant="outline-danger" class="mb-1 mr-2" @click="showf">
                            구매취소<b-badge variant="danger" class="ml-2">{{구매취소}}</b-badge>
                        </b-button>
                        <b-button variant="outline-secondary" class="mb-1 mr-2" @click="showb">
                            구매완료<b-badge variant="secondary" class="ml-2">{{발송전}}</b-badge>
                        </b-button>
                        <b-button variant="outline-danger" class="mb-1 mr-2" @click="showg">
                            발송지연<b-badge variant="danger" class="ml-2">{{발송지연}}</b-badge>
                        </b-button>
                        <b-button variant="outline-secondary" class="mb-1 mr-2" @click="showc">
                            발송완료<b-badge variant="secondary" class="ml-2">{{발송완료}}</b-badge>
                        </b-button>
                        <b-button variant="outline-danger" class="mb-1 mr-2" @click="showh">
                            배송지연<b-badge variant="danger" class="ml-2">{{배송지연}}</b-badge>
                        </b-button>
                        <b-button variant="outline-primary" class="mb-1 mr-2" @click="showd">
                            입고완료<b-badge variant="primary" class="ml-2">{{입고완료}}</b-badge>
                        </b-button>
                    </div>
                    <div class="row mt-4">
                        <b-button variant="outline-secondary" class="mb-1 mr-2" @click="showi">
                            일반배송<b-badge variant="secondary" class="ml-2">{{일반배송}}</b-badge>
                        </b-button>
                        <b-button variant="outline-success" class="mb-1 mr-2" @click="showe">
                            제트배송<b-badge variant="success" class="ml-2">{{노마드클럽}}</b-badge>
                        </b-button>
                    </div>
                </div>
                <CDataTable :headers="headers" :items="reqlists" v-model="tableSelectedItem" :show-select="true" itemKey="_id">
                    <template v-slot:요청일="{item}">
                        {{getTimeDate(item.요청일)}}
                    </template>
                    <template v-slot:reqtype="{item}">
                        {{item.reqtype == '제트배송' ? '로켓그로스' : item.reqtype}}
                    </template>
                    <template v-slot:묶음구성여부="{item}">
                        {{item.묶음구성여부 ? item.묶음구성수량 + '개' : ""}}<br><small>{{item.묶음구성여부 ? '(' + item.바코드 + ")" : ""}}</small>
                    </template>
                    <template v-slot:상품명="{item}">
                        <div class="d-flex align-items-center p-1" v-b-modal="'modalDetail'+item._id">
                            <img :src="item.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                            <div>
                                {{item.상품명}}
                                <p class="mb-0" style="color: #676E8A"><small>{{item.SKUID}}</small></p>
                                <p class="mb-0" style="color: #676E8A"><small>{{item.바코드}}</small></p>
                            </div>
                        </div>

                        
                        <b-modal :id="'modalDetail'+item._id" size="xl" scrollable title="제품상세" ok-title="OK" cancel-title="Close" class="detail-modal">
                            <div class="row">
                                <div class="col-md-6">
                                    <img class="w-100 d-block" :src="item.등록이미지" style="border:1px solid silver; border-radius: 30px">
                                </div>
                                <div class="col-md-6">
                                    <p style="font-size:100%">주문수량 : {{parseInt(item.구매요청수량)*item.판매구성수량}}</p>
                                    <p style="font-size:100%">구매요청수량 : {{parseInt(item.구매요청수량)}}</p>
                                    <p style="font-size:100%">판매구성 : {{item.판매구성수량}}</p>
                                    <!-- <div class="mb-4">
                                        <div class="row text-center ml-1" id="printMe" style="border:1px solid silver;width:300px">
                                            <div class="col-12 text-center mb-0">
                                                <p class="mb-0" style="font-size:90%;">Made in China&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>SKU : {{purchase.SKUID}}</span></p>
                                            </div>
                                            <div class="col-12 text-center mb-0">
                                                <barcode :value="purchase.바코드" :tag="bctag" :options="{ lineColor: '#0275d8', text: 'Scan'}"></barcode>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <button class="btn btn-primary" type="button" @click="print">바코드출력</button>
                                        </div>
                                    </div> -->
                                    <p>상품명 : {{item.상품명}}</p>
                                    <p v-if="item.SKUID">SKU ID : {{item.SKUID}}</p>
                                    <p>바코드 : {{item.바코드}}</p>
                                    <p>구매링크 : <a :href="item.구매링크">{{item.구매링크.slice(0, item.구매링크.search('.html')+5)}}</a></p>
                                    <p>옵션1_중국어 : {{item.옵션1_중국어}}</p>
                                    <p>옵션2_중국어 : {{item.옵션2_중국어}}</p>
                                    <p>[배송 요청사항]</p>
                                    <card style="color:blue;">
                                        <div class="ml-1 mt-1 mb-1 mr-1 row">
                                            <div class="col-5">
                                                <span><b>[출고작업]</b></span><br>
                                                <span v-if="item.selectpo">배치 : {{item.selectpo ? '발주배치' : '해당없음'}}</span><br v-if="item.selectpo">
                                                <span v-if="item.selectpo">배치발주번호 : {{item.selectpo}}</span><br v-if="item.selectpo">
                                                <span>출고유형 : {{item.요청내역.exportType.toUpperCase()}}</span><br>
                                                <br>
                                                <span><b>[검수 및 포장 작업]</b></span><br>
                                                <span>기본검수 : {{item.요청내역.default ? 'Y' : 'N'}}</span><br>
                                                <span>OPP봉투 : {{item.요청내역.opp ? 'Y' : 'N'}}</span><br>
                                                <span>택배봉투(LDPE) : {{item.요청내역.LDPE ? 'Y' : 'N'}}</span><br>
                                                <span>에어캡 : {{item.요청내역.airpacking ? 'Y' : 'N'}}</span><br>
                                                <br>
                                            </div>
                                            <div class="col-7">
                                                <span><b>[라벨 및 원산지 작업]</b></span><br>
                                                <span>바코드 : {{item.요청내역.barcode ? 'Y' : 'N'}}</span><br>
                                                <span>한글표시사항 : {{item.요청내역.한글표시사항 ? 'Y' : 'N'}}</span><br>
                                                <span>원산지스티커 : {{item.요청내역.sticker ? 'Y' : 'N'}}</span><br>
                                                <span>도장 : {{item.요청내역.stamp ? 'Y' : 'N'}}</span><br>
                                                <span>택총 : {{item.요청내역.tag ? 'Y' : 'N'}}</span><br>
                                                <br>
                                                <span><b>[통관 정보]</b></span><br>
                                                <span>신고영문명 : {{item.신고영문명 ? item.신고영문명 : ''}}</span><br>
                                                <span>신고단가(USD) : {{item.주문정보 ? Math.ceil(item.주문정보.총금액/item.구매요청수량/7.4*100)/100 : ''}}</span><br>
                                                <span>HS-CODE : {{item.HSCODE ? item.HSCODE : ''}}</span><br>
                                                <br>
                                                <span><b>[기타 구매대행 요청사항]</b></span><br>
                                                <span>{{item.요청내역.reqcontent}}</span>
                                            </div>
                                        </div>
                                    </card>
                                    <!-- <p :style="item.입고오류유형 == '' ? 'font-size:120%;font-weight:bold;color:black' : 'font-size:120%;font-weight:bold;color:red'">입고오류유형 : {{item.입고오류유형 ? item.입고오류유형 : ''}}</p>
                                    <p style="font-size:120%;font-weight:bold;color:red" v-if="item.입고오류유형 == '수량불일치'">입고수량 : {{item.입고수량}}</p>
                                    <p style="font-size:120%;font-weight:bold;color:red" v-if="item.입고오류유형 != '' && item.입고오류유형 != '수량불일치'">입고오류내용 : {{item.입고오류내용}}</p>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <button :class="importerrorbutton == '입고처리' ? 'btn btn-secondary' : 'btn btn-danger'" type="button" @click="importerror(item)">{{importerrorbutton}}</button>
                                        </div>
                                        <div class="col-md-8" v-if="showimporterror">
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio15" name="customRadio-4" class="custom-control-input" @change="changeimporterror(item)" v-model="item.처리방법" value="입고완료처리">
                                                <label class="custom-control-label" for="customRadio15">입고완료처리</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio16" name="customRadio-4" class="custom-control-input" @change="changeimporterror(item)" v-model="item.처리방법" value="반품">
                                                <label class="custom-control-label" for="customRadio16">반품</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio17" name="customRadio-4" class="custom-control-input" @change="changeimporterror(item)" v-model="item.처리방법" value="폐기">
                                                <label class="custom-control-label" for="customRadio17">폐기</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio18" name="customRadio-4" class="custom-control-input" @change="changeimporterror(item)" v-model="item.처리방법" value="입고대기">
                                                <label class="custom-control-label" for="customRadio18">입고대기</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-2" v-if="showimporterror && showimporterror1">
                                            <div class="form-group">
                                                <label for="exampleFormControlTextarea1">반품정보</label>
                                                <input type="text" class="form-control" placeholder="반품수취인" v-model="item.반품수취인">
                                                <input type="text" class="form-control" placeholder="반품연락처" v-model="item.반품연락처">
                                                <textarea class="form-control" placeholder="반품주소" id="exampleFormControlTextarea1" rows="3" v-model="item.반품주소"></textarea>
                                                <input type="text" class="form-control" placeholder="반품운송장번호" v-model="item.반품운송장번호">
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-2" v-if="showimporterror && showimporterror2">
                                            <div class="form-group">
                                                <label for="exampleFormControlTextarea1">입고대기 사유</label>
                                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="item.입고대기사유"></textarea>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </b-modal>


                    </template>
                    <template v-slot:구매링크="{item}">
                        <a :href="item.구매링크" target="_blank"><b-icon icon="link" scale="2" variant="secondary"></b-icon></a>
                    </template>
                    <template v-slot:action="{item}">
                        <div class="d-flex align-items-center list-action">
                            <a v-if="changeoption" class="badge bg-success mr-2" data-toggle="tooltip" v-b-tooltip.top title="옵션재매칭" href="javascript:void(0)" @click="rematching(item)" style="width: 30px; height: 30px; display: flex; justify-content: center; align-items: center">
                                <i class="ri-pencil-line mr-0"></i>
                            </a>
                            <a class="badge badge-info mr-2" data-toggle="tooltip" v-b-tooltip.top title="편집" href="javascript:void(0)" v-b-modal="modalID(item._id)" style="width: 30px; height: 30px; display: flex; justify-content: center; align-items: center">
                                <i class="ri-eye-line mr-0"></i>
                            </a>
                            <b-modal :id="'modal' + item._id" @shown="showModal(item)" size="sm" title="구매요청사항" ok-title="Save Changes" cancel-title="Close" @ok="saverequest(formData)">
                                <div class="row">
                                    <h6>1. 구매요청수량</h6>
                                    <div class="custom-control-inline ml-3">
                                        <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="formData.구매요청수량" style="text-align:center;">
                                    </div>
                                </div>
                                <div class="row" v-if="formData.reqtype == '제트배송'">
                                    <div class="col-md-12 mt-4 mb-1 d-flex align-items-center justify-contents-start">
                                        <h6>1. 구매요청수량</h6>
                                        <div class="custom-control-inline ml-3">
                                            <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="item.구매요청수량" style="text-align:center;">
                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                        <h6>2. 판매구성수량</h6>
                                        <div class="custom-control-inline ml-3">
                                            <input type="number" :min="0" class="form-control" placeholder="" aria-label="" v-model="item.판매구성수량" style="text-align:center;" v-b-tooltip.left="'해당 SKU가 2p 구성일 경우에는 2라고 작성해 주세요'">
                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                        <h6>3. A-B번들 묶음구성여부</h6>
                                        <div class="custom-control ml-3 custom-switch custom-control-inline d-flex align-items-center">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch111" v-model="item.묶음구성여부" @change="confirmBarcode(item)">
                                            <label class="custom-control-label" for="customSwitch111">&nbsp;</label>
                                            <input v-if="item.묶음구성여부" type="number" :min="0" class="form-control ml-3" placeholder="종류갯수" aria-label="" v-model="item.묶음구성수량" style="text-align:center;" v-b-tooltip.bottom="'3종의 제품을 묶음 구성하는 경우 3으로 작성'">
                                            <!-- <input v-if="item.묶음구성여부" type="text" class="form-control ml-3" placeholder="묶음대상바코드번호" aria-label="" v-model="item.바코드" style="text-align:center;" v-b-tooltip.bottom="'묶음구성할 대상의 바코드를 입력해 주세요.'"> -->
                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                        <h6>4. 도착지</h6>
                                        <div class="custom-control custom-radio custom-control-inline ml-3">
                                            <input type="radio" id="customRadio3" name="customRadio-3" class="custom-control-input" v-model="item.요청내역.destination" value="coupang">
                                            <label class="custom-control-label" for="customRadio3">쿠팡센터</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="customRadio4" name="customRadio-3" class="custom-control-input" v-model="item.요청내역.destination" value="customer">
                                            <label class="custom-control-label" for="customRadio4">화주</label>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                        <h6>5. 출고형태</h6>
                                        <div class="custom-control custom-radio custom-control-inline ml-3">
                                            <input type="radio" id="customRadio2" name="customRadio-44" class="custom-control-input" v-model="item.요청내역.exportType" value="box">
                                            <label v-if="!item.요청내역.destination || item.요청내역.destination == 'coupang'" class="custom-control-label" for="customRadio2">밀크런 택배</label>
                                            <label v-if="item.요청내역.destination == 'customer'" class="custom-control-label" for="customRadio2">택배</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="customRadio1" name="customRadio-44" class="custom-control-input" v-model="item.요청내역.exportType" value="pallet">
                                            <label v-if="!item.요청내역.destination || item.요청내역.destination == 'coupang'" class="custom-control-label" for="customRadio1">밀크런 파렛트 (￦35,000/pallet)</label>
                                            <label v-if="item.요청내역.destination == 'customer'" class="custom-control-label" for="customRadio1">파렛트 (￦20,000/pallet)</label>
                                        </div>
                                    </div>



                                    <div class="col-md-12 mt-1 mb-1 d-flex align-items-center justify-contents-start">
                                        <h6>6. 통관정보</h6>
                                        <div class="custom-control-inline ml-3">
                                            <input type="text" class="form-control" placeholder="신고영문명" aria-label="" v-model="item.신고영문명" style="text-align:center;" v-b-tooltip.bottom="'제품의 신고영문명을 간단히 작성해주세요. ex) 신고영문명 : shoes'">
                                        </div>
                                        <div class="custom-control-inline">
                                            <input type="text" class="form-control" placeholder="HS-CODE" aria-label="" v-model="item.HSCODE" style="text-align:center;" v-b-tooltip.bottom="'통관할 제품의 HS-CODE를 작성해주세요 ex) HS-CODE : 6401.10-1000'">
                                        </div>
                                        <a href="https://www.bandtrass.or.kr/hsnavi.do?page=F&site=" target="_blank"><u>HS-CODE 검색</u></a>
                                    </div>


                                    <div class="col-md-12 mt-3">
                                        <h6>7. 검수 및 포장</h6>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch6" v-model="item.요청내역.default">
                                            <label class="custom-control-label" for="customSwitch6">기본검수/분류/포장/포장자재 (￦200)</label>
                                        </div>
                                        <div class="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="item.요청내역.barcode">
                                            <label class="custom-control-label" for="customSwitch2">바코드라벨 (Made in China,한글표시사항 기본포함) (￦100)</label>
                                        </div>
                                        <!-- <div class="custom-control custom-switch custom-control-inline" v-if="item.요청내역.barcode">
                                            <input type="text" class="form-control" placeholder="바코드입력" aria-label="" v-model="item.바코드" style="text-align:center;" >
                                        </div> -->
                                    </div>

                                    <div class="col-md-12 mt-0" v-if="item.요청내역.default">
                                        <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'포장 방법을 쿠패스에 위임해서 진행함. (적합하지 않는 경우 추후 사입 시에는 얼마든지 변경가능하며, 판단 미스에 대한 별도의 보상은 없음.)'">
                                            <input type="radio" id="customRadio88" name="customRadio-4" class="custom-control-input" value="auto" v-model="packageMethod" @change="changePackage(item)">
                                            <label class="custom-control-label" for="customRadio88">포장 방법 위임</label>
                                        </div>
                                        <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'상품의 원상태 포장상태 그대로 진행'">
                                            <input type="radio" id="customRadio5" name="customRadio-4" class="custom-control-input" value="noPackage" v-model="packageMethod" @change="changePackage(item)">
                                            <label class="custom-control-label" for="customRadio5">재포장 생략</label>
                                        </div>
                                        <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'상품상태가 보이는 투명 OPP 봉투로 재포장 진행'">
                                            <input type="radio" id="customRadio6" name="customRadio-4" class="custom-control-input" value="opp" v-model="packageMethod" @change="changePackage(item)">
                                            <label class="custom-control-label" for="customRadio6">투명 OPP 봉투 포장 (무료)</label>
                                        </div>
                                        <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'상품상태가 보이지 않는 불투명 LDPE 봉투로 재포장 진행'">
                                            <input type="radio" id="customRadio77" name="customRadio-4" class="custom-control-input" value="LDPE" v-model="packageMethod" @change="changePackage(item)">
                                            <label class="custom-control-label" for="customRadio77">불투명 LDPE 봉투 포장 (무료)</label>
                                        </div>

                                    </div>
                                    <div class="col-md-12 mt-0">
                                        <div class="custom-control custom-switch custom-control-inline" v-b-tooltip.bottom="'박스 겉면에 있는 중국어를 가리는 작업 진행'">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch91" v-model="item.요청내역.masking">
                                            <label class="custom-control-label" for="customSwitch91">중국어 마스킹 (무료)</label>
                                        </div>
                                        <div class="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch9" v-model="item.요청내역.airpacking">
                                            <label class="custom-control-label" for="customSwitch9">에어캡 포장 (실비)</label>
                                        </div>

                                    </div>

                                    <div class="col-md-12 mt-3 mb-1 d-flex align-items-center justify-contents-start">
                                        <h6>8. 한글표시사항</h6>
                                    </div>
                                    <div class="col-md-12 mt-1 mb-1">
                                        <div class="custom-control-inline w-50 d-flex align-items-center mb-2">
                                            <label class="w-50 ml-0">바코드번호</label>
                                            <input type="text" class="form-control" placeholder="수입원/판매원" aria-label="" v-model="item.바코드" style="text-align:center;"  v-b-tooltip.right="'ex) S00012341234'">
                                        </div>
                                        <div class="name-input">
                                            <div class="custom-control-inline w-50 d-flex align-items-center">
                                                <label class="w-50 ml-0">제품명</label>
                                                <input type="text" class="form-control" placeholder="제품명" aria-label="" v-model="item.상품명" style="text-align:center;"  v-b-tooltip.right="'ex) 쿠플러스 예쁜접시, 10인치'">
                                            </div>
                                            <div class="warning2" style="font-weight: 500">* 쿠팡에 등록한 상품명 + 옵션명을 같이 입력해주세요</div>
                                        </div>
                                        <div class="custom-control-inline w-50 d-flex align-items-center">
                                            <label class="w-50 ml-0">수입원/판매원</label>
                                            <input type="text" class="form-control" placeholder="수입원/판매원" aria-label="" v-model="item.한글표시사항['수입원/판매원']" style="text-align:center;"  v-b-tooltip.right="'ex) 회사명'">
                                        </div>
                                        <div class="custom-control-inline w-50 d-flex align-items-center">
                                            <label class="w-50 ml-0">제조원</label>
                                            <input type="text" class="form-control" placeholder="제조원" aria-label="" v-model="item.한글표시사항.제조원" style="text-align:center;"  v-b-tooltip.right="'ex) 회사명OEM'">
                                        </div>
                                        <div class="custom-control-inline w-50 d-flex align-items-center">
                                            <label class="w-50 ml-0">제조국</label>
                                            <input type="text" class="form-control" placeholder="제조국" aria-label="" v-model="item.한글표시사항.제조국" style="text-align:center;"  v-b-tooltip.right="'ex) 중국'">
                                        </div>
                                        <div class="custom-control-inline w-50 d-flex align-items-center">
                                            <label class="w-50 ml-0">내용량</label>
                                            <input type="text" class="form-control" placeholder="내용량" aria-label="" v-model="item.한글표시사항.내용량" style="text-align:center;"  v-b-tooltip.right="'ex) 300ml'">
                                        </div>
                                        <div class="custom-control-inline w-50 d-flex align-items-center">
                                            <label class="w-50 ml-0">원료명및성분명</label>
                                            <input type="text" class="form-control" placeholder="원료명및성분명(재질)" aria-label="" v-model="item.한글표시사항['원료명및성분명(재질)']" style="text-align:center;"  v-b-tooltip.right="'ex) 도자기제'">
                                        </div>
                                        <div class="custom-control-inline w-50 d-flex align-items-center">
                                            <label class="w-50 ml-0">상품유형</label>
                                            <input type="text" class="form-control" placeholder="상품유형" aria-label="" v-model="item.한글표시사항.상품유형" style="text-align:center;"  v-b-tooltip.right="'ex) 주방용품'">
                                        </div>
                                        <div class="custom-control-inline w-50 d-flex align-items-center">
                                            <label class="w-50 ml-0">사용시주의사항</label>
                                            <input type="text" class="form-control" placeholder="사용시주의사항" aria-label="" v-model="item.한글표시사항.사용시주의사항" style="text-align:center;"  v-b-tooltip.right="'ex) 용도 이외에 사용금지'">
                                        </div>
                                        <div class="custom-control-inline w-50 d-flex align-items-center">
                                            <label class="w-50 ml-0">사용기준</label>
                                            <input type="text" class="form-control" placeholder="사용기준" aria-label="" v-model="item.한글표시사항.사용기준" style="text-align:center;"  v-b-tooltip.right="'ex) 14세 이상'">
                                        </div>
                                    </div>

                                    <div class="col-md-12" style="display: flex">
                                        <h6 class="mt-4 mb-1">[ 기타요청사항 ]</h6>
                                        <div class="mt-3" style="margin-left: 8px; position: relative; top: 4px; color: #e83e8c">(예: 1688제품 겉박스 중국어 있는부분 작업 요청 내용, 실사, 사이즈, 무게, 센터 입고요청, 신발도장, 의류봉제 등, OPP/LDPE/뽁뽁이 일반검수/정밀검수, 시간이 걸리는 별도의 작업은 6000/시간의 작업비 발생)</div>
                                    </div>
                                    <div class="col-md-12">
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="item.요청내역.reqcontent"></textarea>
                                    </div>

                                    <div class="col-md-12 mt-2">
                                        <div class="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch222" v-model="formData.구매취소" @change="cancelpo(formData)">
                                            <label class="custom-control-label" for="customSwitch222">구매취소</label>
                                        </div>
                                        <div class="custom-control">
                                            <textarea v-if="formData.구매취소" class="form-control" id="exampleFormControlTextarea122" v-model="formData.구매취소내용"></textarea>
                                        </div>
                                    </div>
                                    <b-button variant="outline-primary" class="mb-1 mr-2" @click="onClickLocket(formData)" style="margin-left: 10px">
                                        로켓배송 변경
                                    </b-button>

                                </div>
                                <div class="row" v-else>
                                    <div class="col-md-12">
                                        <h6 class="mt-2 mb-1">[ 출고유형 ]</h6>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="formData.요청내역.poarrange">
                                            <label class="custom-control-label" for="customSwitch1">발주센터/입고예정일에 따라 박스 분류</label>
                                        </div>
                                        <span v-if="!formData.요청내역.poarrange">※제품/발주 구분없이 적재되어 출고됩니다.</span>
                                        <div class="custom-control custom-switch custom-control-inline">
                                            <div class="input-group" v-if="formData.요청내역.poarrange">
                                                <input type="text" class="form-control" placeholder="발주서선택" aria-label="" v-model="formData.selectpo" style="text-align:center;">
                                                <div class="input-group-append">
                                                    <b-dropdown class="my-class" variant="primary" type="button" text="">
                                                        <b-dropdown-item @click="polistchange(b,formData)" v-for="b,j in formData.polists" :key="j">발주번호 : {{b.발주번호}} ({{b.발주센터}}/{{b.입고예정일}}) - {{b.sku수량}}개</b-dropdown-item>
                                                    </b-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="customRadio1" name="customRadio-3" class="custom-control-input" v-model="formData.요청내역.exportType" value="pallet">
                                            <label class="custom-control-label" for="customRadio1">파렛트출고</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="customRadio2" name="customRadio-3" class="custom-control-input" v-model="formData.요청내역.exportType" value="box">
                                            <label class="custom-control-label" for="customRadio2">박스출고</label>
                                        </div>
                                        <span>※최대 박스 사이즈는 60cm(가로) x 40cm(세로) x 50cm(높이)이며, 상황에 따라 변경될 수 있습니다.</span>
                                    </div>

                                    <div class="col-md-12 mt-4 mb-1">
                                        <h6>[ 구매요청수량 ]</h6>
                                        <div class="custom-control-inline">
                                            <input type="number" :min="0" class="form-control" placeholder="구매요청수량" aria-label="" v-model="formData.구매요청수량" style="text-align:center;">
                                        </div>
                                    </div>
                                    <div class="col-md-12" v-if="formData.구매요청수량 > formData.발주수량 && formData.발주수량 > 0 && formData.요청내역.poarrange">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="customRadio3" name="customRadio-13" class="custom-control-input" v-model="formData.재고동봉" value=true>
                                            <label class="custom-control-label" for="customRadio3">나머지 수량 출고</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="customRadio4" name="customRadio-13" class="custom-control-input" v-model="formData.재고동봉" value=false>
                                            <label class="custom-control-label" for="customRadio4">나머지 수량 재고보관</label>
                                        </div>
                                        <span>※나머지 수량 출고 시 별도의 박스로 함께 출고됩니다.</span>
                                    </div>
                                    <div class="col-md-12 mt-4 mb-1">
                                        <h6 v-b-tooltip.left="'해당 SKU가 2p 구성일 경우에는 2라고 작성해 주세요'">[ 판매구성수량 ]</h6>
                                        <div class="custom-control-inline">
                                            <input type="number" :min="0" class="form-control" placeholder="판매구성수량" aria-label="" v-model="formData.판매구성수량" style="text-align:center;">
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-4 mb-1">
                                        <h6 v-b-tooltip.left="'통관 시 필요한 정보를 기입해주세요 ex. 신고영문명 : shoes, 신고가격(USD) : 3.5, HS-CODE : 6401.10-0000'">[ 통관정보 ]</h6>
                                        <div class="custom-control-inline">
                                            <input type="text" class="form-control" placeholder="신고영문명" aria-label="" v-model="formData.신고영문명" style="text-align:center;" v-b-tooltip.left="'제품의 신고영문명을 간단히 작성해주세요.'">
                                        </div>
                                        <div class="custom-control-inline">
                                            <input type="number" :min="0" class="form-control" placeholder="신고가격(USD)" aria-label="" v-model="formData.신고가격" style="text-align:center;" v-b-tooltip.left="'제품의 신고가격(USD)를 작성해주세요. 원/위안화는 달러로 환산해서 작성해주세요.'">
                                        </div>
                                        <div class="custom-control-inline">
                                            <input type="text" class="form-control" placeholder="HS-CODE" aria-label="" v-model="formData.HSCODE" style="text-align:center;" v-b-tooltip.left="'통관할 제품의 HS-CODE를 작성해주세요 ex : 6401.10-1000'">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <h6 class="mt-4 mb-1">[ 원산지작업 ]</h6>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="formData.요청내역.barcode" @change="labelling(formData.요청내역)">
                                            <label class="custom-control-label" for="customSwitch2">바코드라벨(원산지,한글표시사항 포함)</label>
                                        </div>
                                        <div class="custom-control custom-switch custom-control-inline" v-if="formData.요청내역.barcode">
                                            <input type="text" class="form-control" placeholder="바코드입력" aria-label="" v-model="formData.바코드" style="text-align:center;" >
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="formData.요청내역.sticker">
                                            <label class="custom-control-label" for="customSwitch3">원산지스티커</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch4" v-model="formData.요청내역.stamp">
                                            <label class="custom-control-label" for="customSwitch4">원산지도장</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch5" v-model="formData.요청내역.tag">
                                            <label class="custom-control-label" for="customSwitch5">원산지택총</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <h6 class="mt-4 mb-1">[ 검수 및 포장작업 ]</h6>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch6" v-model="formData.요청내역.default" @change="qadefault(formData.요청내역)">
                                            <label class="custom-control-label" for="customSwitch6">기본검수</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch7" v-model="formData.요청내역.opp">
                                            <label class="custom-control-label" for="customSwitch7">투명 OPP 봉투 (견적서 참조)</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch8" v-model="formData.요청내역.LDPE">
                                            <label class="custom-control-label" for="customSwitch8">불투명 LDPE 봉투 (견적서 참조)</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch9" v-model="formData.요청내역.airpacking">
                                            <label class="custom-control-label" for="customSwitch9">에어캡 포장 (견적서 참조)</label>
                                        </div>
                                        <p>※큰 부피의 제품(최대박스 사이즈의 1/5이상 사이즈)인 경우 별도 협의</p>
                                    </div>
                                    <div class="col-md-12">
                                        <h6 class="mt-4 mb-1">[ 기타요청사항 ]</h6>
                                    </div>
                                    <div class="col-md-12">
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="formData.요청내역.reqcontent"></textarea>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch222" v-model="formData.구매취소" @change="cancelpo(formData)">
                                            <label class="custom-control-label" for="customSwitch222">구매취소</label>
                                        </div>
                                        <div class="custom-control">
                                            <textarea v-if="formData.구매취소" class="form-control" id="exampleFormControlTextarea122" v-model="formData.구매취소내용"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </b-modal>
                        </div>
                    </template>
                </CDataTable>
            </div>
            <img v-if="showspinner"  style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
            <div class="col-lg-12 mb-4 d-flex justify-content-between" v-if="showreqlist">
                <div class="row">
                    <button type="button" class="btn btn-success mr-2" @click="createOrder">1688주문생성</button>
                    <button type="button" class="btn btn-secondary mr-2" @click="downloadlist">주문내역 다운로드</button>
                    <button type="button" class="btn btn-danger mr-2" v-b-toggle.my-collapse>일괄변경</button>
                    <button type="button" class="btn btn-primary mr-2" @click="download">1688주문서 다운로드</button>
                </div>

                <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input" id="customSwitchre" v-model="changeoption" @change="changeoptiondone">
                    <label class="custom-control-label" for="customSwitchre">구매옵션재매칭</label>
                </div>
            </div>
            <div class="col-lg-12">
                <b-collapse id="my-collapse" class="mt-3">
                    <b-card title="">
                        <div>
                            <div class="row">
                                <div class="col-2 custom-control">
                                    <b-form-select v-model="일괄변경" :options="wholechanges"></b-form-select>
                                </div>
                            </div>
                            <div v-if="일괄변경 == '구매취소'">
                                <textarea class="form-control ml-2 mt-2" placeholder="구매취소내용 또는 사유를 작성해 주세요." v-model="일괄구매취소내용"></textarea>
                            </div>

                        </div>
                        <button type="button" class="btn btn-success ml-2 mt-2" @click="allcancelpo">적용</button>
                    </b-card>
                </b-collapse>
            </div>
            <div class="d-flex justify-content-end" v-if="showreqlist"></div>
        </div>
    </div>
</template>
<script>
import * as XLSX from 'xlsx';
import axios from 'axios';
import { validationMixin } from 'vuelidate'
import { required ,integer	} from 'vuelidate/lib/validators'
import CDataTable from "@/components/common/CDataTable";
// import { arrayMax } from 'highcharts';
// import { VueEditor } from 'vue2-editor'
export default {
    name:'Reqpurchase',
     mixins: [validationMixin],
    validations: {
      purchaseno: { required },
      payment: { required ,integer},
      },
    components:{
    //    VueEditor,
        CDataTable
    },
    data(){
        return{
            headers: [
                {text: '업체명', value: 'companyid', align: 'center', width: 100, isSlot: false},
                {text: '요청일', value: '요청일', align: 'center', width: 100, isSlot: true},
                {text: '통관방법', value: 'customsType', align: 'center', width: 100, isSlot: false},
                {text: '요청타입', value: 'reqtype', align: 'center', width: 100, isSlot: true},
                {text: '묶음배송', value: '묶음구성여부', align: 'center', width: 100, isSlot: true},
                {text: '진행현황', value: '진행현황', align: 'center', width: 100, isSlot: false},
                {text: '제품정보', value: '상품명', align: 'left', width: 400, isSlot: true},
                {text: '주문번호', value: '주문번호', align: 'center', width: 100, isSlot: false},
                {text: '구매요청수량', value: '구매요청수량', align: 'center', width: 120, isSlot: false},
                {text: '판매구성수량', value: '판매구성수량', align: 'center', width: 120, isSlot: false},
                {text: '구매링크', value: '구매링크', align: 'center', width: 100, isSlot: true},
                {text: '옵션1_중국어', value: '옵션1_중국어', align: 'center', width: 120, isSlot: false},
                {text: '옵션2_중국어', value: '옵션2_중국어', align: 'center', width: 120, isSlot: false},
                {text: '구매재고', value: '구매재고', align: 'center', width: 100, isSlot: false},
                {text: 'Action', value: 'action', align: 'center', width: 100, isSlot: true},
            ],
            tableSelectedItem: [],
            changeindexlist : [],
            changeoption : false,
            change : false,
            일괄변경 : '구매취소',
            wholechanges : [{value:'구매전',text:'구매전'},{value:'구매취소',text:'구매취소'},{value:'구매확인',text:'구매확인'}],
            일괄구매취소내용 : '',
            selectdate : 'today',
            startdate: this.getTodaywith(),
            enddate: this.getTodaywith(),
            selectoption:'요청일',
            SKUID: '',
            상품명: '',
            바코드: '',

            tobepurchase:true,

            formData: {
                상품매칭 :  [],
                옵션1_중국어 : '',
                옵션2_중국어 : '',
                구매재고 : '구매불가',
                등록이미지 :'',
                옵션이미지 : '',
                매칭상태 : '매칭필요',
                요청내역: {}
            },


            업체명:'',
            companyids:[],
            importtext:'Connect',

            total: 0,
            구매전: 0,
            발송전: 0,
            발송완료: 0,
            입고완료: 0,
            노마드클럽: 0,
            일반배송 : 0,
            구매취소: 0,
            발송지연 : 0,
            배송지연 : 0,

            showspinner: false,

            allcheckreqlists : false,
            reqlists: [],
            reqlistsinit : [],

            title:"구매대행요청",
            desc1:"쿠팡로켓 / 제트배송 / 일반LCL을 선택한 후에 양식을 다운받아 작성하여 업로드 해주세요",
            desc2:"상품DB에 SKU ID/구매링크/옵션1_중국어/옵션2_중국어를 미리 업로드해 놓을 경우 자동으로 상품이 매핑됩니다. (일반LCL제품은 10000000이하의 숫자로 SKU ID를 작성해 주세요)",
            desc3:"",

            showreqlist: false,
            showlist: false,

            checkbarcode: true,
            checktag: false,
            checkstamp: false,
            checksticker: false,

            // reqtype: "로켓배송",
            showreqrocket: false,
            showreqjet: false,
            showreqgeneral: false,
            purchaseno:'',
            payment:'',
            reqcontent:'',

            allchecked: false,
            packageMethod :'auto',
            lists: [],
            downloadlists: [],
            currentindex: '',

            uploadstatus:'excel file upload',

            CreatePo_수취인 : this.$store.state.settings[0].CreatePo_수취인,
            CreatePo_연락처 : this.$store.state.settings[0].CreatePo_연락처,
            CreatePo_주소_성 : this.$store.state.settings[0].CreatePo_주소_성,
            CreatePo_주소_시 : this.$store.state.settings[0].CreatePo_주소_시,
            CreatePo_주소_구 : this.$store.state.settings[0].CreatePo_주소_구,
            CreatePo_상세주소 : this.$store.state.settings[0].CreatePo_상세주소,
            CreatePo_주문번호 : this.$store.state.settings[0].CreatePo_주문번호,
            CreatePo_발주기준일 : this.$store.state.settings[0].CreatePo_발주기준일,

        }
    },
    created(){
        document.addEventListener.call(window, "message", event => {
            if(event.data.res && event.data.res === 'yes'){
                console.log(event.data.res);
                console.log(event.data.result[0].result);
                if(event.data.result[0].result.option1array.length == 0){
                    this.lists[this.currentindex].상품매칭.옵션1_중국어 = [];
                    this.lists[this.currentindex].옵션1_중국어 = '';
                } else {
                    this.lists[this.currentindex].상품매칭.옵션1_중국어 = event.data.result[0].result.option1array;
                }
                if(event.data.result[0].result.option2array.length == 0){
                    this.lists[this.currentindex].상품매칭.옵션2_중국어 = [];
                    this.lists[this.currentindex].옵션2_중국어 = '';
                } else {
                    this.lists[this.currentindex].상품매칭.옵션2_중국어 = event.data.result[0].result.option2array;
                }
                this.lists[this.currentindex].showspinner = false;
                this.currentindex = '';
            }
        });
    },
    methods: {
        async createOrder() {
            // 1. 선택된 아이템 검증
            let arr = [];
            if (this.tableSelectedItem.length == 0) {
                if (this.reqlists.filter(e => e.진행현황 == '구매전').length < this.reqlists.length) {
                    alert('구매전 상태인 제품들만 선택해주세요.')
                    return
                }
                arr = this.reqlists
            } else {
                if (this.tableSelectedItem.filter(e => e.진행현황 == '구매전').length < this.tableSelectedItem.length) {
                    alert('구매전 상태인 제품들만 선택해주세요.')
                    return
                }
                arr = this.tableSelectedItem
            }

            // 최소 주문수량과 재고 검증
            const orderQuantityByProduct = {};
            arr.forEach(item => {
                if (!item.구매링크) return;
                const supplierId = item.구매링크.slice(30, item.구매링크.search('.html'));
                
                // SKU 정보 찾기 및 재고 확인
                let stock = 0;

                // 옵션이 없는 경우
                if (!item.item.props_list || Object.keys(item.item.props_list).length === 0) {
                    stock = parseInt(item.item.num);
                }
                // 옵션이 1개인 경우
                else if (item.item.skus.sku.some(sku => !sku.properties.includes(';'))) {
                    const properties = item.item.skus.sku.find(sku => 
                        sku.properties_name.includes(item.옵션1_중국어)
                    );
                    if (properties) {
                        stock = parseInt(properties.quantity);
                    }
                }
                // 옵션이 2개인 경우
                else {
                    const skuInfo = item.item.productSkuInfos.find(sku => 
                        sku.skuAttributes.some(attr => attr.value === item.옵션1_중국어) &&
                        sku.skuAttributes.some(attr => attr.value === item.옵션2_중국어)
                    );
                    if (skuInfo) {
                        stock = parseInt(skuInfo.amountOnSale);
                    }
                }

                if (!orderQuantityByProduct[supplierId]) {
                    orderQuantityByProduct[supplierId] = {
                        totalQuantity: 0,
                        minOrderQuantity: item.item?.minOrderQuantity || 2,
                        products: []
                    };
                }
                
                orderQuantityByProduct[supplierId].totalQuantity += parseInt(item.구매요청수량 || 0);
                orderQuantityByProduct[supplierId].products.push({
                    name: item.상품명,
                    quantity: item.구매요청수량,
                    stock: stock,
                    options: item.옵션1_중국어 && item.옵션2_중국어 ? 
                            `${item.옵션1_중국어}, ${item.옵션2_중국어}` :
                            item.옵션1_중국어 || ''
                });
            });

            // 검증 실행
            for (const info of Object.values(orderQuantityByProduct)) {
                // 최소 주문수량 체크
                if (info.totalQuantity < info.minOrderQuantity) {
                    const productDetails = info.products
                        .map(p => `${p.name} (옵션: ${p.options}, 수량: ${p.quantity}개)`)
                        .join('\n');
                    
                    alert(`다음 상품들의 총 주문수량(${info.totalQuantity}개)이 최소 주문수량(${info.minOrderQuantity}개)보다 적습니다:\n\n${productDetails}`);
                    return;
                }

                // 재고 수량 체크
                const outOfStockProducts = info.products.filter(p => parseInt(p.quantity) > parseInt(p.stock));
                if (outOfStockProducts.length > 0) {
                    const stockDetails = outOfStockProducts
                        .map(p => `${p.name}\n- 옵션: ${p.options}\n- 주문수량: ${p.quantity}개\n- 가용재고: ${p.stock}개`)
                        .join('\n\n');
                    
                    alert(`다음 상품들의 주문수량이 재고수량을 초과합니다:\n\n${stockDetails}`);
                    return;
                }
            }
            this.showspinner = true;

            try {
                console.log("처리할 전체 items:", arr);
                const allUpdatedItems = []; // 모든 업데이트될 아이템을 담을 배열

                const companyGroups = {};
                arr.forEach(item => {
                    if (!companyGroups[item.companyid]) {
                        companyGroups[item.companyid] = [];
                    }
                    companyGroups[item.companyid].push(item);
                });
                console.log("Company Groups:", companyGroups);

                for (const [companyId, items] of Object.entries(companyGroups)) {
                    console.log(`Processing Company ${companyId} with items:`, items);
                    
                    const supplierGroups = {};
                    items.forEach(item => {
                        const supplierId = item.구매링크.slice(30, item.구매링크.search('.html'));
                        if (!supplierGroups[supplierId]) {
                            supplierGroups[supplierId] = [];
                        }
                        supplierGroups[supplierId].push(item);
                    });
                    console.log(`Supplier Groups for ${companyId}:`, supplierGroups);

                    for (const [supplierId, supplierItems] of Object.entries(supplierGroups)) {
                        console.log(`Processing Supplier ${supplierId} with items:`, supplierItems);
                        
                        const mergedItems = {};
                        supplierItems.forEach(item => {
                            console.log(`Processing item for merging:`, item);
                            // 옵션이 없는 경우
                            if (!item.옵션1_중국어 && !item.옵션2_중국어) {
                                var specId = "";
                            }
                            // 옵션이 1개인 경우
                            else if (item.옵션1_중국어 && !item.옵션2_중국어) {
                                specId = item.item.productSkuInfos.find(f => f.skuAttributes[0].value == item.옵션1_중국어).specId;
                            }
                            // 옵션이 2개인 경우
                            else {
                                specId = item.item.productSkuInfos.find(f => f.skuAttributes[0].value == item.옵션1_중국어 && f.skuAttributes[1].value == item.옵션2_중국어).specId;
                            }

                            console.log(`Found specId:`, specId);

                            // if (!specId) {
                            //     console.log(`No specId found for item:`, item);
                            //     return;
                            // }

                            const key = `${specId}`;
                            if (!mergedItems[key]) {
                                mergedItems[key] = {
                                    offerId: parseInt(supplierId),
                                    specId: specId,
                                    quantity: 0
                                };
                            }
                            mergedItems[key].quantity += parseInt(item.구매요청수량) * parseInt(item.판매구성수량 || 1);
                        });
                        console.log(`Merged items:`, mergedItems);

                        const mergedItemsList = Object.values(mergedItems);
                        console.log(`Merged items list:`, mergedItemsList);

                        const chunks = [];
                        for (let i = 0; i < mergedItemsList.length; i += 50) {
                            chunks.push(mergedItemsList.slice(i, i + 50));
                        }
                        console.log(`Created chunks:`, chunks);

                        for (const chunk of chunks) {
                            console.log(`Processing chunk:`, chunk);
                            let reqtype = 'G仓库';
                            if (supplierItems[0].reqtype == '로켓배송') {
                                reqtype = 'R仓库';
                            } else if (supplierItems[0].reqtype == '제트배송') {
                                reqtype = 'Z仓库';
                            }

                            const orderRequest = {
                                flow: 'general',
                                message: '',
                                addressParam: {
                                    fullName: '飞运',
                                    mobile: '15650102901',
                                    provinceText: '山东省',
                                    cityText: '威海市',
                                    areaText: '环翠区',
                                    address: '凤林街道海南路9号飞运' + ' ' + companyId.toUpperCase() + 
                                            `${reqtype}(无法自提 不接受到付 仓库电话13296318809)`,
                                    postCode: '000000'
                                },
                                cargoParamList: chunk
                            };
                            console.log(`Sending order request:`, orderRequest);

                            const res = await axios.post('/api/reqpurchase/createCrossOrder', orderRequest);
                            console.log(`Order creation response:`, res.data);
                            if (res.data.요청결과 !== '성공') {
                                const errorMessage = res.data.error?.errorMessage || 
                                                res.data.error?.message || 
                                                res.data.error || 
                                                '주문 생성 실패';
                                throw new Error(errorMessage);
                            }
                            if (res.data.요청결과 === '성공') {
                                // chunk에 사용된 specId들을 이용해 해당하는 supplierItems 찾기
                                for (const chunkItem of chunk) {
                                    const items = supplierItems.filter(item => {
                                        let itemSpecId;
                                        // 옵션이 없는 경우
                                        if (!item.옵션1_중국어 && !item.옵션2_중국어) {
                                            itemSpecId = "";
                                        }
                                        // 옵션이 1개인 경우
                                        else if (item.옵션1_중국어 && !item.옵션2_중국어) {
                                            itemSpecId = item.item.productSkuInfos.find(f => f.skuAttributes[0].value === item.옵션1_중국어)?.specId;
                                        }
                                        // 옵션이 2개인 경우
                                        else {
                                            itemSpecId = item.item.productSkuInfos.find(f => 
                                                f.skuAttributes[0].value === item.옵션1_중국어 && 
                                                f.skuAttributes[1].value === item.옵션2_중국어
                                            )?.specId;
                                        }
                                        return itemSpecId === chunkItem.specId;
                                    });

                                    // 찾은 items에 주문 정보 업데이트
                                    items.forEach(item => {
                                        item.주문정보 = {
                                            주문번호: res.data.result.orderResult.result.orderId,
                                            배송비: res.data.result.orderResult.result.postFee/100,
                                            구매가격: (res.data.result.orderResult.result.totalSuccessAmount - res.data.result.orderResult.result.postFee)/100,
                                            총금액: res.data.result.orderResult.result.totalSuccessAmount/100
                                        };
                                        item.진행현황 = '구매확인';
                                        allUpdatedItems.push(item); // 전체 배열에 추가
                                    });
                                }
                            }
                        }
                    }
                }

                // 모든 처리가 완료된 후 한번에 업데이트
                if (allUpdatedItems.length > 0) {
                    console.log(allUpdatedItems)
                    await this.updatereqlist(allUpdatedItems);
                }

                alert('모든 주문 생성 완료');
                this.showspinner = false;
            } catch (e) {
                alert('주문 생성 실패: ' + e.message);
                console.error('주문 생성 에러:', e);
                this.showspinner = false;
                return;
            }
        },
        changePackage(list){
            if(this.packageMethod == 'opp'){
                list.요청내역.opp = true;
                list.요청내역.LDPE = false;
                list.요청내역.auto = false;
                return
            }
            if(this.packageMethod == 'LDPE'){
                list.요청내역.opp = true;
                list.요청내역.LDPE = false;
                list.요청내역.auto = false;
                return
            }
            if(this.packageMethod == 'noPackage'){
                list.요청내역.opp = false;
                list.요청내역.LDPE = false;
                list.요청내역.auto = false;
                return
            }
            if(this.packageMethod == 'auto'){
                list.요청내역.opp = false;
                list.요청내역.LDPE = false;
                list.요청내역.auto = true;
                return
            }
        },
        confirmBarcode(list){
            if(list.바코드.includes('no') || list.바코드.includes('NO') || list.바코드.includes('No') || list.바코드.includes('노')){
                if(list.묶음구성여부){
                    alert('바코드 정보가 없는 경우 묶음구성 진행이 어렵습니다. 바코드를 먼저 생성해 주세요')
                    return
                }
            }
        },
        async changeoptiondone(){
            if(this.change && !this.changeoption){
                if(confirm('변경사항을 저장하시겠습니까?')){
                    this.reqlists.forEach((e,i) => {
                        if(this.changeindexlist.includes(i)){
                            e.checked = true;
                        } else {
                            e.checked = false;
                        }
                    })
                    var arr = this.reqlists.filter(e => e.checked);
                    if(confirm('구매불가는 구매취소처리하시겠습니까?')){
                        arr.forEach(e => {
                            e.진행현황 = '구매취소';
                            e.구매취소 = true;
                        })
                    }
                    var result = await this.updatereqlist(arr);
                    console.log(result);
                    this.search();
                } else {
                    this.search();
                }
            }
        },
        async allcancelpo(){
            if(this.tableSelectedItem.length == 0){
                alert('변경할 내역을 선택해 주세요')
                return
            }
            if(this.일괄변경 == '구매취소' && this.tableSelectedItem.filter(e => e.진행현황 !== '구매전' && e.진행현황 !== '구매취소').length > 0){
                if(!confirm('이미 구매한 제품이 있습니다. 그래도 구매취소처리하시겠습니까?')){
                    return
                }
            }
            this.tableSelectedItem.forEach(e => {
                e.진행현황 = this.일괄변경;
                if(this.일괄변경 == '구매취소'){
                    e.구매취소 = true;
                    e.구매취소내용 = e.일괄구매취소내용;
                } else {
                    e.구매취소 = false;
                    e.구매취소내용 = '';
                }
            })
            var arr = this.tableSelectedItem
            var result = await this.updatereqlist(arr);
            console.log(result);
            this.search();
        },
        cancelpo(list){
            if(list.구매취소){
                list.진행현황 = "구매취소"
            } else {
                list.진행현황 = "구매전"
            }
            console.log(list)
        },
        productNameWith(list){
            // if(confirm('변경된 한글표시사항 제품명에 따라 상품명도 변경하시겠습니까?')){
                list.상품명 = list.한글표시사항.제품명;
            // }
        },
        importcompanyinfo(){
            if(this.업체명){
                if(this.$store.state.purchase.length > 0 && this.업체명.toUpperCase() == this.$store.state.purchase[0].companyid.toUpperCase()){
                    this.showimportspinner = false;
                    this.importtext = 'Connected'
                    return
                }
                this.showimportspinner = true;
                axios.post('/api/logisaddimport/importcompanyinfo',{
                    companyid : this.업체명
                })
                .then( result => {
                if (result.data.요청결과 == 'success') {
                    this.importreqlists()
                    this.$store.commit('dbupdate', result.data.dbs)
                    this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
                    this.showimportspinner = false;
                    this.importtext = 'Connected'
                } else {
                    alert('업체 Data Import 실패')
                }
                }).catch((e)=>{
                    console.log(e);
                })
            } else {
                alert('업체ID를 기입해 주세요')
            }
		},
        polistchange(b,list){
            list.발주수량 = b.sku수량;
            list.구매요청수량 = b.sku수량;
            list.selectpo = b.발주번호;
        },
        labelling(요청내역){
            if(요청내역.barcode){
                요청내역.한글표시사항 = true
            } else {
                요청내역.한글표시사항 = false
            }
        },
        qadefault(요청내역){
            if(!요청내역.default){
                if(요청내역.barcode || 요청내역.sticker || 요청내역.stamp || 요청내역.tag){
                    alert('개별 원산지 작업을 하는 경우 기본검수는 필수항목 입니다.')
                    요청내역.default = true;
                }
            }
        },

        showModal(item) {
            this.formData = item
            // console.log('formData', this.formData)
        },
        async saverequest(item){
            const selectedIndex = this.reqlists.findIndex(i => i._id === item._id);
            if(item.진행현황 === '입고완료'){
                if(!confirm('입고가 완료된 상품은 수정이 불가능 합니다. 그래도 진행하시겠습니까?')){
                    return
                }
            }
            if(item.진행현황 === '구매전'){
                this.reqlistsinit[selectedIndex] = this.deepClone(item)
                await this.updatereqlist([item])
                this.$forceUpdate();
            } else {
                if(item['구매요청수량'] !== this.reqlistsinit[selectedIndex]['구매요청수량']){
                    if(!confirm('이미 구매 진행된 상품은 구매요청수량을 변경할 수 없습니다. 그래도 진행하시겠습니까?')){
                        return
                    }
                } else {
                    this.reqlistsinit[selectedIndex] = this.deepClone(item)
                    await this.updatereqlist([item])
                    this.$forceUpdate();
                }
            }
        },
        async updatereqlist(arr){
            if(arr.length > 0){
                axios.post('/api/reqpurchase/updatereqpurchase',arr)
                .then((res) => {
                    if(res.data.요청결과 == '변경완료'){
                        alert(res.data.요청결과)
                    } else {
                        alert(res.data.요청결과)
                        console.log(res.data)
                        return res
                    }
                })
                .catch(console.log)
            } else {
                alert('변경할 내역이 없습니다.')
            }
        },
        showtotal(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit;
                this.showreqlist = true;
            }, 10);
        },
        showa(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '구매전');
                this.showreqlist = true;
            }, 10);
        },
        showb(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '배송전');
                this.showreqlist = true;
            }, 10);
        },
        showc(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 != '구매전' && e.진행현황 != '배송전' && e.진행현황 != '발송지연' && e.진행현황 != '입고완료' && e.진행현황 != '배송지연');
                this.showreqlist = true;
            }, 10);
        },
        showd(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '입고완료');
                this.showreqlist = true;
            }, 10);
        },
        showe(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.reqtype == '제트배송');
                this.showreqlist = true;
            }, 10);
        },
        showf(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.구매취소);
                this.showreqlist = true;
            }, 10);
        },
        showg(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '발송지연');
                this.showreqlist = true;
            }, 10);
        },
        showh(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '배송지연');
                this.showreqlist = true;
            }, 10);
        },
        showi(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.reqtype !== '제트배송');
                this.showreqlist = true;
            }, 10);
        },
        showj(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '구매확인');
                this.showreqlist = true;
            }, 10);
        },
        async poconfirm(arr){
            arr.forEach(e => {
                if (e.진행현황 === '구매전') {
                    e.진행현황 = "구매확인"
                }
            })
            var result = await this.updatereqlist(arr);
            console.log(result);
            this.search();
        },
        getTimeDate(timestamp) {
            let date = new Date(timestamp);
            let year = date.getFullYear();
            let month = ("0" + (1 + date.getMonth())).slice(-2);
            let day = ("0" + date.getDate()).slice(-2);
            let hours = ("0" + date.getHours()).slice(-2);
            let minutes = ("0" + date.getMinutes()).slice(-2);
            let seconds = ("0" + date.getSeconds()).slice(-2);

            return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
        },
        download() {
            var arr = [];
            var ar = [];
            if(this.tableSelectedItem.length == 0){
                if(this.reqlists.filter(e => e.진행현황 == '구매전').length < this.reqlists.length){
                    alert('구매전인 제품들만 선택해주세요.')
                    return
                }
                if(this.reqlists.filter(e => e.companyid == this.reqlists[0].companyid).length < this.reqlists.length){
                    alert('같은 업체명만 선택해주세요')
                    return
                }
                arr = this.reqlists
            } else {
                if(this.tableSelectedItem.filter(e => e.진행현황 == '구매전').length < this.tableSelectedItem.length){
                    alert('구매전인 제품들만 선택해주세요.')
                    return
                }
                if(this.tableSelectedItem.filter(e => e.companyid == this.tableSelectedItem[0].companyid).length < this.tableSelectedItem.length){
                    alert('같은 업체명만 선택해주세요')
                    return
                }
                arr = this.tableSelectedItem
            }
            if(confirm('다운로드 내역을 구매확인으로 변경하시겠습니까?')){
                this.poconfirm(arr);
            }

            var obj = {};
            console.log(arr)
            arr.forEach(e => {
                if(e.reqtype == '로켓배송'){
                    var reqtype = 'R仓库'
                } else {
                    if(e.reqtype == '제트배송'){
                        reqtype = 'Z仓库'
                    } else {
                        reqtype = 'G仓库'
                    }
                }

                obj = {
                    '订单号':e.companyid + "-" + this.getToday(),
                    '商品名称':e.상품명,
                    '商品数量(件)':e.구매요청수량 * e.판매구성수량,
                    '商品规格1（如：颜色）': e.옵션1_중국어 === undefined ? '' : e.옵션1_중국어,
                    '商品规格2（如：尺码）': e.옵션2_중국어 === undefined ? '' : e.옵션2_중국어,
                    '收件人-姓名': this.CreatePo_수취인,
                    '收件人-手机': '15650102901',
                    '收件人-省':this.CreatePo_주소_성,
                    '收件人-市':this.CreatePo_주소_시,
                    '收件人-区':this.CreatePo_주소_구,
                    '收件人-详细地址':'凤林街道海南路9号飞运' + ' ' + e.companyid.toUpperCase() + `${reqtype}(无法自提 不接受到付 仓库电话13296318809)`,
                    '收货地址（省/市/区/详细地址合并的收货地址）':'',
                    '买家留言':e.SKUID,
                    '1688商品链接/1688商品id':e.구매링크.slice(30,e.구매링크.search('.html')),
                    '分销or现货（默认或者填1走分销，0走现货）':0,
                }
                ar.push(obj)
            })

            console.log(ar)

            this.downloadpo(ar)
      },
        async onClickLocket(list) {
            if (list.진행현황 !== '구매전') {
                alert('구매전인 상품만 변경 가능합니다.');
            } else {
                if (confirm('로켓배송으로 변경 하시겠습니까 ?')) {
                    try {
                        const response = await axios.post('/api/reqpurchase/changeLocket', {list})
                        console.log(response)
                        alert(response.data.요청결과)
                    } catch (error) {
                        alert('알 수 없는 error')
                        console.error(error);
                    }
                } else {
                    return false
                }
            }
        },
        search(){
            // console.log(this.업체명)
            this.showreqlist = false;
            var start = this.gettimestamp(this.startdate) - (1000*60*60*9)
            var end = this.gettimestamp(this.getdate(this.gettimestamp(this.enddate) + (1000*60*60*24))) - (1000*60*60*9) - 1
            console.log(start,end)
            axios.post('/api/reqpurchase/getliststotal',{start:start,end:end,companyid:this.업체명,tobepurchase:this.tobepurchase})
                .then((res) => {

                    this.$store.commit('reqpurchaseupdate',res.data.reqpurchase);
                    console.log(res.data.reqpurchase)
                    console.log(res.data.요청결과)

                    var arr = [];
                    res.data.reqpurchase.forEach(e => {
                        e.진행현황 = e.진행현황 ? e.진행현황 : "구매전";
                        e.구매취소 = e.구매취소 ? e.구매취소 : false;
                        e.구매취소내용 = e.구매취소내용 ? e.구매취소내용 : '';
                        e.판매구성수량 = e.판매구성수량 == '단품' ? 1 : e.판매구성수량;
                        e.주문번호 = e.주문정보 ? e.주문정보.주문번호 : ""; 
                        if(e.진행현황 == '발송전'){
                            e.진행현황 = '구매완료';
                        }
                        e.checked = false;
                        arr.push(this.deepClone(e))
                    })

                    this.구매전 = arr.filter(e => e.진행현황 == '구매전').length;
                    this.발송전 = arr.filter(e => e.진행현황 == '발송전').length;//발송지연
                    this.발송완료 = arr.filter(e => e.진행현황 == '발송완료').length;
                    this.입고완료 = arr.filter(e => e.진행현황 == '입고완료').length;
                    this.노마드클럽 = arr.filter(e => e.reqtype == '제트배송').length;
                    this.일반배송 = arr.filter(e => e.reqtype !== '제트배송').length;
                    this.구매확인 = arr.filter(e => e.진행현황 == '구매확인').length;
                    this.구매취소 = arr.filter(e => e.구매취소).length;
                    this.total = arr.length;

                    this.reqlistsinit = arr;
                    this.reqlists = arr;

                    this.showreqlist = true;
                    console.log(this.reqlists)
                })
                .catch(e => console.log('err821' + e))

        },
        getdate(timestamp){
            var date = new Date(timestamp);
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },
        changedate(){
            var now
            var target
            if(this.selectdate == 'today'){
                this.startdate = this.getTodaywith();
                this.enddate = this.getTodaywith();
            } else {
                if(this.selectdate == 'week'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*7

                    this.startdate = this.getdate(target);
                    this.enddate = this.getTodaywith();
                } else {
                    if(this.selectdate == 'month'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*30
                    this.startdate = this.getdate(target);
                    this.enddate = this.getTodaywith();
                    }
                }
            }
        },
        allcheck(){
            if(this.allcheckreqlists){
                this.reqlists.forEach(e => {
                    e.checked = true;
                })
            } else {
                this.reqlists.forEach(e => {
                    e.checked = false;
                })
            }
        },
        confirmlists(){
            var arr = [];
            if(this.lists.filter(e => e.checked).length == 0){
                arr = this.lists
            } else {
                arr = this.lists.filter(e => e.checked)
            }
            if(arr.filter(e => e.구매링크 === undefined).length > 0){
                alert('구매링크를 모두 입력해 주세요')
                return
            }
            if(arr.filter(e => e.옵션1_중국어 === undefined || e.옵션2_중국어 === undefined).length > 0){
                if(confirm('매칭이 안된 옵션이 있습니다. 이대로 진행하시겠습니까?')){
                    return arr
                } else {
                    return []
                }
            }
            return arr
        },
        async requestpurchase(){
            var list = await this.confirmlists()
            if(list.length == 0){
                alert('요청할 목록이 없습니다.')
                return
            }
            var cnt = list.length;
            var arr = [];
            var obj = {};
            var now = Date.now();
            list.forEach(e => {
                obj = {
                    companyid : this.$store.state.user.companyid,
                    요청일 : now,
                    SKUID : parseInt(e.SKUID),
                    바코드 : e.바코드,
                    상품명 : e.상품명,
                    구매링크 : e.구매링크,
                    옵션1_중국어 : e.옵션1_중국어,
                    옵션2_중국어 : e.옵션2_중국어,
                    구매요청수량 : e.구매요청수량,
                    등록이미지 : e.등록이미지,
                }
                arr.push(obj);
            })
            if(confirm("총 " + cnt + "건을 구매대행 요청하시겠습니까?")){
                axios.post('/api/reqpurchase/requestpurchase',arr)
                .then((res) => {
                    this.$store.commit('reqpurchaseupdate',res.data.reqpurchase);
                    alert(res.data.요청결과)
                })
                .catch(console.log)
            }
        },
        async saveproductdb(){
            var list = await this.confirmlists()
            if(list.length == 0){
                alert('업데이트할 목록이 없습니다.')
                return
            }
            console.log(list)
            axios.post('/api/reqpurchase/updateproductdb',list)
            .then((res) => {
                this.$store.commit('productdbupdate',res.data.productdb);
                alert(res.data.요청결과)
            })
            .catch(console.log)
        },
        deleteindexfromarray(array,index){
            return array.slice(0,index).concat(array.slice(index+1))
        },
        deleteitem(index){
            if(confirm('삭제된 제품은 복구가 불가능합니다. 삭제하시겠습니까?')){
                this.showlist = false;
                setTimeout(() => {
                    this.lists = this.deleteindexfromarray(this.lists,index);
                    this.showlist = true;
                }, 1);
            }
        },
        confirmexcelDownFunc(){
            if(this.reqtype == '로켓배송'){
                this.excelDownFunc()
                return
            }
            if(this.reqtype == '제트배송'){
                this.excelDownFunc()
                return
            }
            if(this.reqtype == '일반LCL'){
                if(confirm('일반LCL에서 SKUID는 10000000 이하의 숫자로 임의로 기입하시면 됩니다.')){
                    this.excelDownFunc()
                    return
                }
            }
        },
        reset(list){
            list.옵션1_중국어 = undefined;
            list.옵션2_중국어 = undefined;
            list.상품매칭.옵션1_중국어 = [];
            list.상품매칭.옵션2_중국어 = [];
            list.구매링크 = undefined;
        },
        rematching(list){
            this.change = true;
            const selectedIndex = this.reqlists.findIndex(i => i._id === list._id);
            this.changeindexlist.push(selectedIndex);
            if(!list.구매링크){
                alert('구매링크를 입력해 주세요')
                return
            }
            list.옵션1_중국어 = undefined;
            list.옵션2_중국어 = undefined;
            list.상품매칭.옵션1_중국어 = [];
            list.상품매칭.옵션2_중국어 = [];
            this.matchoption1(selectedIndex,list.구매링크)
        },
        fileupload(){
            this.$refs.pdbupload.value = '';
            this.$refs.pdbupload.click();
        },
        option1matching(a,list){
            this.change = true;
            // this.showlist = false;
            if(a.imageUrl){
                if(!list.등록이미지){
                    list.등록이미지 = a.imageUrl;
                }
                list.옵션이미지 = a.imageUrl;
            }
            list.옵션1_중국어 = a.name;


            // if(this.checkstock){
            if(list.옵션2_중국어 == ''){
                var properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1).quantity);
                if(list.구매재고 == 0){
                    alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                } else {
                    alert('구매 가능한 재고수량 : ' + list.구매재고)
                }
                list.매칭상태 = '매칭완료';
            } else {
                if(list.옵션2_중국어 !== undefined){
                    properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                    var properties2 = list.상품매칭.옵션2_중국어.find(e => e.name == list.옵션2_중국어).properties;
                    list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).quantity);
                    if(list.구매재고 == 0){
                        alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                    } else {
                        alert('구매 가능한 재고수량 : ' + list.구매재고)
                    }
                    list.매칭상태 = '매칭완료';
                }
            }
            if(list.등록이미지 == '' && list.옵션이미지 == ''){
                list.등록이미지 = list.item.pic_url;
                list.옵션이미지 = list.item.pic_url;
            }
            // this.showlist = true;
            // }
            this.pricechange(list)
        },
        option2matching(a,list){
            this.change = true;
            // this.showlist = false;
            if(a.imageUrl){
                list.등록이미지 = a.imageUrl;
            }
            list.옵션2_중국어 = a.name;

            // if(this.checkstock){

            if(list.상품매칭.옵션1_중국어 && list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어)){
                var properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                var properties2 = list.상품매칭.옵션2_중국어.find(e => e.name == list.옵션2_중국어).properties;
                list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).quantity);
                if(list.구매재고 == 0){
                    alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                } else {
                    alert('구매 가능한 재고수량 : ' + list.구매재고)
                }
                list.매칭상태 = '매칭완료'
            }

            // }
            if(list.등록이미지 == '' && list.옵션이미지 == ''){
                list.등록이미지 = list.item.pic_url;
                list.옵션이미지 = list.item.pic_url;
            }
            // this.showlist = true;
            this.pricechange(list)
        },
        sendmessage(url){
            window.postMessage({greeting: "importproductinfo",url:url},"*",)
        },
        async matchoption1(index,구매링크){
            this.reqlists[index].showspinner = true;
            this.reqlists[index].구매링크 = 구매링크;
            this.currentindex = index;
            console.log(구매링크)
            // setTimeout(() => {this.sendmessage(구매링크);},1)
            var newObj = await this.confirmreq(this.reqlists[index]);
            // console.log(newObj)
            // this.showlist = false
            this.reqlists[index] = newObj;
            console.log(this.reqlists[this.currentindex])
            // this.showlist = true


            // axios.post('/api/reqpurchase/geturlinfo',{
            //     url : 구매링크
            // })
            // .then((res) => {
            //     if(res.data.요청결과 == 'success'){
            //         console.log(res.data.result)
            //         var obj = {}
            //         res.data.result.option1array.forEach(e => {
            //             obj = {
            //                 image : e.imageUrl,
            //                 optionname : e.name,
            //             }
            //             list.상품매칭.옵션1_중국어.push(obj)
            //         })
            //         res.data.result.option2array.forEach(e => {
            //             obj = {
            //                 image : e.imageUrl,
            //                 optionname : e.name,
            //             }
            //             list.상품매칭.옵션1_중국어.push(obj)
            //         })

            //     } else {
            //         alert('error777 :' + res.data.요청결과)
            //     }
            // })
            // .catch(console.log)
        },
        nopo(구매링크){
            this.reqlists.forEach((obj,i) => {
                if(obj.구매링크 == 구매링크){
                    obj.상품매칭.옵션1_중국어 = [];
                    obj.상품매칭.옵션2_중국어 = [];
                    obj.옵션1_중국어 = '';
                    obj.옵션2_중국어 = '';
                    obj.구매재고 = '구매불가';
                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : '';
                    obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : '';
                    obj.매칭상태 = '매칭필요';
                    obj.showspinner = false;
                    this.changeindexlist.push(i)
                }
            })
        },
        async confirmreq(obj){
            if(!obj.구매링크 || obj.구매링크 == '사입'){
                obj.showspinner = false;
                return obj
            }
            obj.구매링크init = obj.구매링크;
            obj.매칭상태 = '매칭필요'
            var item = await this.getProductInfo(obj.구매링크);
            console.log(item)
            obj.item = item;

            if(item.format_check == 'fail'){
                obj.상품매칭.옵션1_중국어 = [];
                obj.상품매칭.옵션2_중국어 = [];
                obj.옵션1_중국어 = '';
                obj.옵션2_중국어 = '';
                obj.구매재고 = '구매불가';
                obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : item.pic_url;
                obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : item.pic_url;
                obj.매칭상태 = '매칭필요';
                obj.showspinner = false;
                if(confirm('해당제품은 구매불가입니다. 같은 링크를 모두 구매불가 처리하시겠습니까?')){
                    this.nopo(obj.구매링크);
                }
                return obj
            }

            var option1array = [];
            var option2array = [];
            if(item.props_list && Object.keys(item.props_list).length > 0){
                item.skus.sku.forEach(e => {
                    if(e.properties.includes(";")){
                        var properties1 = e.properties.split(';')[0];
                        var properties2 = e.properties.split(';')[1];
                        if(!option1array.find(ele => ele.properties == properties1)){
                            option1array.push({
                                properties : properties1,
                                name : e.properties_name.split(";" + properties2 + ':')[0].replace(properties1 + ":",""),
                                imageUrl : item.props_img[properties1] ? item.props_img[properties1] : '',
                            })
                        }
                        if(!option2array.find(ele => ele.properties == properties2)){
                            option2array.push({
                                properties : properties2,
                                name : e.properties_name.split(";" + properties2 + ':')[1],
                                imageUrl : item.props_img[properties2] ? item.props_img[properties2] : '',
                            })
                        }
                    } else {
                        option1array.push({
                            properties : e.properties,
                            name : e.properties_name.replace(e.properties + ":",""),
                            imageUrl : item.props_img[e.properties] ? item.props_img[e.properties] : '',
                        })
                    }
                })



                // Object.keys(item.props_list).forEach(e => {
                //     if(e[0] == '0'){
                //         option1array.push({
                //             properties : e,
                //             name : item.props_list[e].split(":")[1],
                //             imageUrl : item.props_img[e] ? item.props_img[e] : '',
                //         })
                //     }
                //     if(e[0] == '1'){
                //         option2array.push({
                //             properties : e,
                //             name : item.props_list[e].split(":")[1],
                //             imageUrl : item.props_img[e] ? item.props_img[e] : '',
                //         })
                //     }
                // })
            }

            if(option1array.length == 0){
                obj.상품매칭.옵션1_중국어 = [];
                obj.상품매칭.옵션2_중국어 = [];
                obj.옵션1_중국어 = '';
                obj.옵션2_중국어 = '';
                obj.구매재고 = parseInt(item.num);
                obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : item.pic_url;
                obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : item.pic_url;
                obj.매칭상태 = '매칭완료'
            } else {
                if(option2array.length == 0){
                    var imgUrl = '';
                    if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                        obj.옵션1_중국어 = undefined;
                        obj.옵션이미지 = undefined;
                        obj.구매재고 = 0;
                    } else {
                        var option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                        obj.구매재고 = parseInt(item.skus.sku.find(e => e.properties == option1properties).quantity);
                        if(item.prop_imgs.prop_img.length > 0){
                            imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option1properties).url;
                        }
                        obj.옵션이미지 = imgUrl;
                        obj.매칭상태 = '매칭완료'
                    }
                    obj.상품매칭.옵션1_중국어 = option1array;
                    obj.상품매칭.옵션2_중국어 = [];
                    obj.옵션2_중국어 = '';
                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
                } else {
                    imgUrl = '';
                    if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                        obj.옵션1_중국어 = undefined;
                        obj.옵션이미지 = undefined;
                        obj.구매재고 = 0;
                    } else {
                        option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                        if(item.prop_imgs.prop_img && item.prop_imgs.prop_img.find(e => e.properties == option1properties)){
                            imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option1properties).url;
                        }
                        obj.옵션이미지 = imgUrl;
                        if(!option2array.find(e => e.name == obj.옵션2_중국어)){
                            obj.옵션2_중국어 = undefined;
                            obj.구매재고 = 0;
                        } else {
                            var option2properties = option2array.find(e => e.name == obj.옵션2_중국어).properties;
                            obj.구매재고 = parseInt(item.skus.sku.find(e => e.properties == (option1properties + ';' + option2properties)).quantity);
                            if(item.prop_imgs.prop_img && item.prop_imgs.prop_img.find(e => e.properties == option2properties)){
                                imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option2properties).url;
                            }
                            obj.옵션이미지 = imgUrl;
                            obj.매칭상태 = '매칭완료'
                        }
                    }
                    obj.상품매칭.옵션1_중국어 = option1array;
                    obj.상품매칭.옵션2_중국어 = option2array;
                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
                }
            }
            if(!obj.등록이미지 && !option1array.find(e => e.imageUrl) && !option1array.find(e => e.imageUrl)){
                obj.등록이미지 = item.pic_url;
            }
            this.pricechange(obj);
            obj.showspinner = false;
            return obj
        },
        async getProductInfo(구매링크){
            var productNo = 구매링크.slice(구매링크.search('offer/')+6,구매링크.search('.html'));
            console.log(productNo)
            try{
                var res = await axios.post('/api/reqpurchase/getProductInfo',[productNo])
                return res.data.result[0].item
            }catch(e){
                console.log('err821' + e);
                return false
            }
        },
        pricechange(obj){
            this.change = true;
            if(obj.매칭상태 == '매칭필요'){
                obj.구매가격 = '';
                return
            }
            if(obj.item && obj.구매요청수량){
                var option1array = [];
                var option2array = [];
                var item = obj.item;
                if(item.props_list && Object.keys(item.props_list).length > 0){
                    Object.keys(item.props_list).forEach(e => {
                        if(e[0] == '0'){
                            option1array.push({
                                properties : e,
                                name : item.props_list[e].split(":")[1],
                                imageUrl : item.props_img[e] ? item.props_img[e] : '',
                            })
                        }
                        if(e[0] == '1'){
                            option2array.push({
                                properties : e,
                                name : item.props_list[e].split(":")[1],
                                imageUrl : item.props_img[e] ? item.props_img[e] : '',
                            })
                        }
                    })
                }

                if(option1array.length == 0){
                    if(item.priceRange){
                        for(var i=0;i<item.priceRange.length;i++){
                            obj.구매가격 = parseFloat(item.priceRange[i][1]);
                            if(parseInt(item.priceRange[i][0]) > obj.구매요청수량){
                                break
                            }
                        }
                    } else {
                        obj.구매가격 = parseInt(item.price);
                    }
                } else {
                    if(option2array.length == 0){
                        if(item.priceRange){
                            for(i=0;i<item.priceRange.length;i++){
                                obj.구매가격 = parseFloat(item.priceRange[i][1]);
                                if(parseInt(item.priceRange[i][0]) > obj.구매요청수량){
                                    break
                                }
                            }
                        } else {
                            obj.구매가격 = parseInt(item.price);
                        }
                    } else {
                        if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                            obj.구매가격 = '';
                        } else {
                            if(!option2array.find(e => e.name == obj.옵션2_중국어)){
                                obj.구매가격 = '';
                            } else {
                                var option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                                var option2properties = option2array.find(e => e.name == obj.옵션2_중국어).properties;
                                obj.구매가격 = parseInt(item.skus.sku.find(e => e.properties == (option1properties + ';' + option2properties)).price);
                            }
                        }
                    }
                }
            }
        },
        matchclass(a){
            if(a == '매칭완료'){
                return 'badge badge-success';
            } else {
                return 'badge badge-warning';
            }
        },
        excelDownFunc(){
            var arr = [];
            var myHeader = ["SKUID","구매요청수량"];

            var dataWS = XLSX.utils.json_to_sheet(arr, {header : myHeader});

            const range = XLSX.utils.decode_range(dataWS['!ref']);
            range.e['c'] = myHeader.length - 1;
            dataWS['!ref'] = XLSX.utils.encode_range(range);

            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, dataWS, '구매대행요청양식');
            // 엑셀 파일을 내보낸다.
            XLSX.writeFile(wb, '구매대행요청양식.xlsx');
        },
        // uploadfile(event) {
        //     var obj = {};
        //     var ob = {};
        //     var op1 = '';
        //     var op2 = '';
        //     this.showlist = false;
        //     this.lists = [];
        //     // var 주문번호,판매처,배송단가,할인단가,운송장번호,주문날짜,주문상태;
        //     setTimeout(() => {
        //         const file = event.target.files[0];
        //         let reader = new FileReader();
        //         reader.onload = (e) => {
        //             // let data = reader.result;
        //             let data = e.target.result;
        //             let workbook = XLSX.read(data, {type: 'binary'});
        //             workbook.SheetNames.forEach(sheetName => {
        //                 const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        //                 console.log(roa)
        //                 if(this.reqtype == '일반LCL'){
        //                     roa.forEach(e => {
        //                         if(this.$store.state.productdb.find(ele => parseInt(ele['SKU ID']) == parseInt(e.SKUID))){
        //                             ob = this.$store.state.productdb.find(ele => parseInt(ele['SKU ID']) == parseInt(e.SKUID))
        //                             // console.log(e)
        //                             // console.log(ob)
        //                             if(ob.옵션1_중국어 || ob.옵션1_중국어 == ''){
        //                                 op1 = ob.옵션1_중국어
        //                             } else {
        //                                 op1 = undefined
        //                             }
        //                             if(ob.옵션2_중국어 || ob.옵션2_중국어 == ''){
        //                                 op2 = ob.옵션2_중국어
        //                             } else {
        //                                 op2 = undefined
        //                             }
        //                             obj = {
        //                                 checked : false,
        //                                 showspinner : false,
        //                                 SKUID : e.SKUID,
        //                                 바코드 : ob.바코드 ? ob.바코드 : '',
        //                                 상품명 : ob.상품명,
        //                                 구매링크 : ob.구매링크 ? ob.구매링크 : undefined,
        //                                 옵션1_중국어 : op1,
        //                                 옵션2_중국어 : op2,
        //                                 매칭상태 : ob.구매링크 !== undefined || op1 !== undefined || op2 !== undefined ? '매칭완료' : '매칭필요',
        //                                 상품매칭 : {
        //                                     옵션1_중국어 : [],
        //                                     옵션2_중국어 : [],
        //                                 },
        //                                 구매요청수량 : e.구매요청수량,
        //                                 등록이미지 : ob.등록이미지
        //                             }
        //                             this.lists.push(obj)
        //                         } else {
        //                             if(e.옵션1_중국어 || e.옵션1_중국어 == ''){
        //                                 op1 = e.옵션1_중국어
        //                             } else {
        //                                 op1 = undefined
        //                             }
        //                             if(e.옵션2_중국어 || e.옵션2_중국어 == ''){
        //                                 op2 = e.옵션2_중국어
        //                             } else {
        //                                 op2 = undefined
        //                             }
        //                             obj = {
        //                                 checked : false,
        //                                 showspinner : false,
        //                                 SKUID : e.SKUID,
        //                                 바코드 : e.바코드 ? e.바코드 : '',
        //                                 상품명 : e.상품명,
        //                                 구매링크 : e.구매링크 ? e.구매링크 : undefined,
        //                                 옵션1_중국어 : op1,
        //                                 옵션2_중국어 : op2,
        //                                 매칭상태 : e.구매링크 !== undefined || op1 !== undefined || op2 !== undefined ? '매칭완료' : '매칭필요',
        //                                 상품매칭 : {
        //                                     옵션1_중국어 : [],
        //                                     옵션2_중국어 : [],
        //                                 },
        //                                 구매요청수량 : e.구매요청수량,
        //                                 등록이미지 : e.등록이미지
        //                             }
        //                             this.lists.push(obj)
        //                         }
        //                     })
        //                 } else {
        //                     roa.forEach(e => {
        //                         if(this.$store.state.productdb.find(ele => parseInt(ele['SKU ID']) == parseInt(e.SKUID))){
        //                             ob = this.$store.state.productdb.find(ele => parseInt(ele['SKU ID']) == parseInt(e.SKUID))
        //                             // console.log(e)
        //                             // console.log(ob)
        //                             if(ob.옵션1_중국어 || ob.옵션1_중국어 == ''){
        //                                 op1 = ob.옵션1_중국어
        //                             } else {
        //                                 op1 = undefined
        //                             }
        //                             if(ob.옵션2_중국어 || ob.옵션2_중국어 == ''){
        //                                 op2 = ob.옵션2_중국어
        //                             } else {
        //                                 op2 = undefined
        //                             }
        //                             obj = {
        //                                 checked : false,
        //                                 showspinner : false,
        //                                 SKUID : e.SKUID,
        //                                 바코드 : ob.바코드,
        //                                 상품명 : ob.상품명,
        //                                 구매링크 : ob.구매링크 ? ob.구매링크 : undefined,
        //                                 옵션1_중국어 : op1,
        //                                 옵션2_중국어 : op2,
        //                                 매칭상태 : ob.구매링크 !== undefined || op1 !== undefined || op2 !== undefined ? '매칭완료' : '매칭필요',
        //                                 상품매칭 : {
        //                                     옵션1_중국어 : [],
        //                                     옵션2_중국어 : [],
        //                                 },
        //                                 구매요청수량 : e.구매요청수량,
        //                                 등록이미지 : ob.등록이미지
        //                             }
        //                             // console.log(obj)
        //                             this.lists.push(obj)
        //                         } else {
        //                             alert('로켓/제트 상품은 상품DB를 먼저 등록해주세요')
        //                             return
        //                         }
        //                     })
        //                 }
        //                 this.showlist = true;
        //             });
        //         };
        //         reader.readAsBinaryString(file);
        //     }, 10);
        // },
        modalID(index) {
            // console.log(index);
            return 'modal' + index
        },

		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
        deletelist(index){
            var newarr = [];
            var delarr = [];
            this.reqlists.forEach((e,i) => {
                if(i != index){
                    newarr.push(this.deepClone(e))
                } else {
                    delarr.push(this.deepClone(e))
                }
            })
            this.reqlists = newarr;
            if(confirm('삭제 후에는 복구가 불가능합니다. 그래도 삭제하시겠습니까?')){
                this.deletereqlists(delarr);
            }
        },
        async deletereqlists(delarr){
            axios.post('/api/reqpurchase/deletereqpurchase',delarr)
            .then((res) => {
                if(res.data.요청결과 == '삭제완료'){
                    alert(res.data.요청결과)
                } else {
                    alert(res.data.요청결과)
                    console.log(res.data)
                }
            })
            .catch(console.log)
        },
        deletedetail(발주번호,SKUID){
            var newarr = [];
            var newobj = {};
            this.lists.forEach(e => {
                if(e.발주번호 != 발주번호){
                    newarr.push(e);
                } else {
                    newobj = this.deepClone(e);
                    newobj.발주세부내역 = [];
                    e.발주세부내역.forEach(ele => {
                        if(ele.SKUID != SKUID){
                            newobj.발주세부내역.push(ele);
                        }
                    });
                    newarr.push(newobj);
                }
            })
            this.lists = newarr;
        },
        allchecklist(allchecked){
            if(allchecked == true){
                this.lists.forEach(e => {
                    e.checked = true;
                })
            } else {
                this.lists.forEach(e => {
                    e.checked = false;
                })
            }
        },
        allcheckdetaillist(allcheckdetail){
            if(allcheckdetail == true){
                this.detaillists.forEach(e => {
                    e.checked = true;
                })
            } else {
                this.detaillists.forEach(e => {
                    e.checked = false;
                })
            }
        },
        getTodaywith(){
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },
        getToday(){
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + month + day;
        },
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime() / 1000
        },
        gettimestamp(date){
            return new Date(date).getTime()
        },
        downloadlist(){
            var arr = [];
            this.reqlists.forEach(e => {
                arr.push({
                    업체명 : e.companyid,
                    요청일 : this.getdate(e.요청일),
                    상품명 : e.상품명,
                    SKUID : e.SKUID,
                    바코드 : e.바코드,
                    구매요청수량 : parseInt(e.구매요청수량),
                    판매구성수량: parseInt(e.판매구성수량),
                    구매링크 : e.구매링크,
                    옵션1_중국어 : e.옵션1_중국어,
                    옵션2_중국어 : e.옵션2_중국어,
                    배치발주번호 : e.selectpo,
                    출고방법 : e.요청내역.exportType,
                    목적지 : e.요청내역.destination ? e.요청내역.destination : "coupang",
                    신고영문명 : e.신고영문명,
                    신고가격 : e.신고가격,
                    HSCODE : e.HSCODE,

                    "기본검수/분류/포장" : e.요청내역.default,
                    바코드라벨 : e.요청내역.barcode,

                    포장방법위임 : e.요청내역.auto ? "O" : "",
                    LDPE : e.요청내역.LDPE ? "O" : "",
                    OPP : e.요청내역.OPP ? "O" : "",
                    재포장생략 : (e.요청내역.auto == false && !e.요청내역.LDPE && !e.요청내역.OPP) ? "O" : "",

                    원산지스티커 : e.요청내역.sticker ? "O" : "",
                    라벨봉제 : e.요청내역.sewing ? "O" : "",
                    도장 : e.요청내역.stamp ? "O" : "",
                    택총 : e.요청내역.tag ? "O" : "",
                    에어캡포장 : e.요청내역.airpacking ? "O" : "",
                    중국어마스킹 : e.요청내역.masking ? "O" : "",

                    협의단가: e.협의단가,
                    총금액: e.요청내역.총금액,
                    협의ID: e.협의ID,

                    기타요청사항 : e.요청내역.reqcontent,
                    이미지링크 : e.등록이미지,
                })
            })
            const workBook = XLSX.utils.book_new()
            const workSheet = XLSX.utils.json_to_sheet(arr)
            XLSX.utils.book_append_sheet(workBook, workSheet, '주문내역')
            XLSX.writeFile(workBook, '주문내역' + '_' + this.getToday() + '.xlsx')
        },
        downloadpo(arr){
            if(arr.length > 0){
                var createpo = [];
                var totalpo = arr;
                totalpo.forEach((e,i) => {
                    e.index = i;
                })
                //1688id순으로 정렬 (중국내배송비최소화)
                totalpo.sort(function(a,b){
                    if (a['商品规格1（如：颜色）'] < b['商品规格1（如：颜色）']){
                        return -1;
                    }
                    if (a['商品规格1（如：颜色）'] > b['商品规格1（如：颜色）']){
                        return 1;
                    }
                    return 0;
                })
                totalpo.sort(function(a,b){
                    if (a['商品规格2（如：尺码）'] < b['商品规格2（如：尺码）']){
                        return -1;
                    }
                    if (a['商品规格2（如：尺码）'] > b['商品规格2（如：尺码）']){
                        return 1;
                    }
                    return 0;
                })
                totalpo.sort(function(a,b){
                    if (a['商品名称'] < b['商品名称']){
                        return -1;
                    }
                    if (a['商品名称'] > b['商品名称']){
                        return 1;
                    }
                    return 0;
                })
                totalpo.sort(function(a,b){
                    if (a['1688商品链接/1688商品id'] < b['1688商品链接/1688商品id']){
                        return -1;
                    }
                    if (a['1688商品链接/1688商品id'] > b['1688商品链接/1688商品id']){
                        return 1;
                    }
                    return 0;
                })

                //동일제품 1688주문번호에 구분번호추가 (발주번호 구분)
                var ct = 1;

                for(var i=1;i<totalpo.length;i++){
                    if(
                        ((totalpo[i]['商品规格1（如：颜色）'] && totalpo[i-1]['商品规格1（如：颜色）']) ? (totalpo[i]['商品规格1（如：颜色）'] == totalpo[i-1]['商品规格1（如：颜色）']) : true) &&
                        ((totalpo[i]['商品规格2（如：尺码）'] && totalpo[i-1]['商品规格2（如：尺码）']) ? (totalpo[i]['商品规格2（如：尺码）'] == totalpo[i-1]['商品规格2（如：尺码）']) : true) &&
                        (totalpo[i]['商品名称'] == totalpo[i-1]['商品名称']) &&
                        (totalpo[i]['1688商品链接/1688商品id'] == totalpo[i-1]['1688商品链接/1688商品id'])
                    ){
                        totalpo[i]['订单号'] += ct.toString();
                        ct++
                    } else {
                        ct = 1;
                    }
                }

                totalpo.sort(function(a,b){
                    if (a.index < b.index){
                        return -1;
                    }
                    if (a.index > b.index){
                        return 1;
                    }
                    return 0;
                })

                totalpo.sort(function(a,b){
                    if (a['1688商品链接/1688商品id'] < b['1688商品链接/1688商品id']){
                        return -1;
                    }
                    if (a['1688商品链接/1688商品id'] > b['1688商品链接/1688商品id']){
                        return 1;
                    }
                    return 0;
                })

                totalpo.sort(function(a,b){
                    if (a['订单号'] < b['订单号']){
                        return -1;
                    }
                    if (a['订单号'] > b['订单号']){
                        return 1;
                    }
                    return 0;
                })


                //1688id순으로 정렬 (중국내배송비최소화)
                // totalpo.sort(function(a,b){
                //     if (a.판매처 < b.판매처){
                //         return -1;
                //     }
                //     if (a.판매처 > b.판매처){
                //         return 1;
                //     }
                //     return 0;
                // })


                //1688po를 100개씩 잘라서 createpo array에 저장
                console.log(totalpo)
                var j
                var cnt = totalpo.length;

                for(j=0;j<cnt;j++){
                    if(totalpo.length < 100){
                        createpo.push(totalpo)
                        break
                    }
                    for(i=100;i>0;i--){
                        if(totalpo[i][['1688商品链接/1688商品id']] != totalpo[i-1][['1688商品链接/1688商品id']]){
                            console.log(totalpo.slice(0,i))
                            createpo.push(totalpo.slice(0,i))
                            totalpo = totalpo.slice(i,totalpo.length)
                            console.log(totalpo)
                            break
                        } else {
                            if(i==1){
                                createpo.push(totalpo.slice(0,100))
                                totalpo = totalpo.slice(100,totalpo.length)
                            }
                        }
                    }
                }

                //test
                // const workBook = XLSX.utils.book_new()
                // const workSheet1 = XLSX.utils.json_to_sheet(totalpo)
                // const workSheet2 = XLSX.utils.json_to_sheet(matchpo)
                // XLSX.utils.book_append_sheet(workBook, workSheet1, '1688주문서')
                // XLSX.utils.book_append_sheet(workBook, workSheet2, 'matching')
                // XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '.xlsx')


                //다운로드
                for(i=0;i<createpo.length;i++){
                    const workBook = XLSX.utils.book_new()
                    const workSheet = XLSX.utils.json_to_sheet(createpo[i])
                    XLSX.utils.book_append_sheet(workBook, workSheet, '1688주문서')
                    XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '_' + i.toString() + '.xlsx')
                }
            } else {
                alert('생성할 주문서가 없습니다.')
            }
        },

        savesetting() {
            var payload = {
                companyid : this.$store.state.user.companyid,
                CreatePo_수취인 : this.CreatePo_수취인,
                CreatePo_연락처 : this.CreatePo_연락처,
                CreatePo_주소_성 : this.CreatePo_주소_성,
                CreatePo_주소_시 : this.CreatePo_주소_시,
                CreatePo_주소_구 : this.CreatePo_주소_구,
                CreatePo_상세주소 : this.CreatePo_상세주소,
                CreatePo_주문번호 : this.CreatePo_주문번호,
                CreatePo_발주기준일 : this.CreatePo_발주기준일
            };

            this.$store.commit('settingsupdate', payload)
            console.log(this.$store.state.settings)
            axios.post('/api/addpurchase/addsettings',payload)
            .then((res) => {alert(res.data.요청결과)})
        },
        importcompanyids(){
			axios.post('/api/getcompanyids',{})
            .then( result => {
				if (result.data.요청결과 == 'success'){
					result.data.result.forEach(e => {
                        if(e.auth.showimport){
                            if(!this.companyids.includes(e.companyid)){
                                this.companyids.push(e.companyid);
                            }
                        }
					})
                    console.log(this.companyids)
				} else {
					alert(result.data.사유)
				}
            }).catch((e)=>{
                alert('companyid 가져오기 실패 (1413)')
                console.log(e);
                return true
            })
		},
    },
    importtextchange(event){
        this.importtext = 'Connect';
        if(event.keyCode === 13 && this.업체명 != ''){
            this.importcompanyinfo()
        }
    },
    mounted() {
        this.importcompanyids()
    }
}
</script>
<style>
.my-class .dropdown-menu {
transform: translate3d(0px, -30px, 0px) !important;
max-height: 100px;
width:300px;
z-index:10000;
overflow-y: auto;
}
#quill-container{
    height: 50px;
}
#quill-container .ql-editor{
    min-height: unset;
}

.modal-dialog {
    max-width: 90%;
    position: absolute;

    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}



</style>
